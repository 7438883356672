import { inject, injectable } from 'ioc';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { FieldState, FormState } from 'formstate';
import { formValidatorsWithCustomMessages } from '../../common/utils/form-state-validators';
import { apiClient } from '../../common/api/api-client';
import { AuthenticationCodeModel, IUserLoginModel, UserLoginModel } from '../../common/api/api';
import { AuthStore } from '../../common/stores/auth-store';
import { LoadStatus } from '../../common/enums/load-status';
import { TokenResponse } from '@react-oauth/google';
import { getRedirectUriForGoogle } from '../utils/third-party-utils';

export type Provider = 'google-callback' | 'discord-callback';

@injectable()
export class LoginStore {
    formState = new FormState({
        username: new FieldState('').validators(
            formValidatorsWithCustomMessages.email(
                'Please enter a valid email address (e.g., name@example.com)',
            ),
        ),
        password: new FieldState('').validators(
            formValidatorsWithCustomMessages.password(
                'Password is wrong. Please check one more time.',
            ),
        ),
    });

    @observable loginLoadStatus = LoadStatus.None;
    @observable loginGoogleLoadStatus = LoadStatus.None;
    @observable loginDiscordLoadStatus = LoadStatus.None;

    @inject(AuthStore) private readonly authStore!: AuthStore;

    @computed get isLoginDisabled() {
        return (
            this.loginGoogleLoadStatus === LoadStatus.Loading ||
            this.loginDiscordLoadStatus === LoadStatus.Loading
        );
    }

    constructor() {
        this.formState.disableAutoValidation();
        makeAutoObservable(this);
    }

    @action onLoadingState = () => {
        this.loginLoadStatus = LoadStatus.Loading;
        this.loginGoogleLoadStatus = LoadStatus.Loading;
        this.loginDiscordLoadStatus = LoadStatus.Loading;
    };

    @action offLoadingState = () => {
        this.loginLoadStatus = LoadStatus.None;
        this.loginGoogleLoadStatus = LoadStatus.None;
        this.loginDiscordLoadStatus = LoadStatus.None;
    };

    login = async () => {
        try {
            runInAction(() => {
                this.loginLoadStatus = LoadStatus.Loading;
            });

            await this.formState.enableAutoValidationAndValidate();

            if (this.formState.hasError) {
                throw new Error('Validation error');
            }

            const payload: IUserLoginModel = {
                login: this.formState.$.username.$,
                password: this.formState.$.password.$,
            };

            const jwtAuthResult = await apiClient.accessToken(new UserLoginModel(payload));

            this.authStore.authorize(jwtAuthResult);
        } catch {
            runInAction(() => {
                this.loginLoadStatus = LoadStatus.Error;
            });

            throw new Error('Unable to login');
        }
    };

    @action loginWithGoogle = async (
        tokenResponse: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>,
    ) => {
        try {
            runInAction(() => {
                this.loginGoogleLoadStatus = LoadStatus.Loading;
            });

            const googleAuthResult = new AuthenticationCodeModel({
                code: tokenResponse.access_token,
                redirectUri: getRedirectUriForGoogle(),
            });

            const jwtAuthResult = await apiClient.google(googleAuthResult);

            await this.authStore.authorize(jwtAuthResult);

            runInAction(() => {
                this.loginGoogleLoadStatus = LoadStatus.Ok;
            });

            return true;
        } catch {
            runInAction(() => {
                this.loginGoogleLoadStatus = LoadStatus.Error;
            });
        }
    };

    @action loginWithDiscordCode = async (code: string, redirectUri: string) => {
        try {
            runInAction(() => {
                this.loginDiscordLoadStatus = LoadStatus.Loading;
            });

            const auth = new AuthenticationCodeModel({
                redirectUri,
                code,
            });

            const jwtAuthResult = await apiClient.discord(auth);

            await this.authStore.authorize(jwtAuthResult);

            runInAction(() => {
                this.loginDiscordLoadStatus = LoadStatus.Ok;
            });

            return true;
        } catch {
            runInAction(() => {
                this.loginDiscordLoadStatus = LoadStatus.Error;
            });

            return false;
        }
    };
}
