import { FC, Fragment } from 'react';
import { observer } from 'mobx-react';
import {
    IdNameModel,
    MatchModel,
    MatchWithOddsModelPagingModel,
    OddGroupModel,
} from '../../api/api';
import { LoadStatus } from '../../enums/load-status';
import { MatchOverviewRowProps } from '../match-overviews/match-overview-row';
import Box from '@mui/material/Box';
import { BackButton } from '../back-button/back-button';
import { MatchOdds } from '../match-odds/match-odds';
import { MatchOverviews } from '../match-overviews/match-overviews';
import { MatchSectionProps } from '../match-odds/match-sections';

interface IMatchContentProps {
    // Overviews
    matchOverviews?: MatchWithOddsModelPagingModel;
    matchOverviewsLoadStatus: LoadStatus;
    onMatchClick: MatchOverviewRowProps['onMatchClick'];

    // Odds
    match?: MatchModel;
    matchOddsLoadStatus: LoadStatus;
    matchSectionId?: IdNameModel['id'];
    matchSections: IdNameModel[];
    oddSubsectionGroups: OddGroupModel[];
    onSelectSection: MatchSectionProps['onChange'];

    // Own
    activeMatchId?: number;
    onBackToMatches: () => void;
}

export const MatchContent: FC<IMatchContentProps> = observer(
    ({
        matchOverviews,
        onSelectSection,
        matchOddsLoadStatus,
        oddSubsectionGroups,
        matchSections,
        matchSectionId,
        match,
        matchOverviewsLoadStatus,
        onMatchClick,
        activeMatchId,
        onBackToMatches,
    }) => {
        return activeMatchId ? (
            <Fragment>
                <Box mb={2}>
                    <BackButton label="Matches" onClick={onBackToMatches} />
                </Box>
                {!!match && (
                    <MatchOdds
                        onSelectSection={onSelectSection}
                        oddsLoadStatus={matchOddsLoadStatus}
                        oddSubsectionGroups={oddSubsectionGroups}
                        match={match}
                        matchSections={matchSections}
                        matchSectionId={matchSectionId}
                    />
                )}
            </Fragment>
        ) : matchOverviews ? (
            <MatchOverviews
                loadStatus={matchOverviewsLoadStatus}
                matchOverviews={matchOverviews}
                onMatchClick={onMatchClick}
            />
        ) : null;
    },
);
