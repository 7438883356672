import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { provide, useDependencies } from 'ioc';
import { Stack } from '@mui/material';
import { useScreenSize } from '../../../common/hooks/use-is-mobile';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Box } from '@mui/system';
import { MyLeaguesStore } from '../../stores/my-leagues-store';
import { AsyncList } from '../../../common/components/async-table-list/async-list';
import { useNavigate } from 'react-router-dom';
import { leaguesListColumns } from '../../utils/leagues-list-columns';
import { MyLeaguesMobile } from './mobile/my-leagues-mobile';
import { CustomIconButton } from '../../../common/components/custom-icon-button/custom-icon-button';
import { noop } from '../../../common/utils/noop';
import { GameLeaguePublicModel } from '../../../common/api/api';
import { useConfirm } from 'material-ui-confirm';
import { CustomTable } from '../../../common/components/custom-table/custom-table';
import { CLICKABLE_TABLE_ROW_SX } from '../../../common/utils/ui';
import { InvitePlayers } from '../../../invite-players/components/invite-players';
import { DoorOpen, UserPlus } from '@phosphor-icons/react';
import {
    customNegative,
    customSecondary,
} from '../../../redesigned-modules/root/components/theme-provider/colors';
import { LeaguesStore } from '../../stores/leagues-store';
import { LeaguesRoutes } from '../../utils/leagues-routes';
import { DebouncedSearch } from 'shared';

export const MyLeagues = provide([MyLeaguesStore])(
    observer(() => {
        const [
            { setStatusTab },
            {
                fetchLeagues,
                leaguesLoadStatus,
                leaveLeague,
                searchLeagues,
                leagues,
                getShareLink,
                startInvitation,
                sendInvite,
                endInvitation,
                leagueToShare,
            },
        ] = useDependencies(LeaguesStore, MyLeaguesStore);

        const confirm = useConfirm();

        useEffect(() => {
            setStatusTab(LeaguesRoutes['My Leagues']);
            fetchLeagues();
        }, []);

        const { isDesktop } = useScreenSize();
        const navigate = useNavigate();

        const handleLeagueClick = (league: GameLeaguePublicModel) => {
            if (!league.hasStarted) {
                return;
            }

            navigate(`../content/${league.id}`);
        };

        const handleLeave = (league: GameLeaguePublicModel) => {
            confirm({
                description: 'Are you sure you want to leave this league?',
                title: `Leave League`,
                confirmationButtonProps: {
                    variant: 'contained',
                    color: 'error',
                    fullWidth: true,
                },
                confirmationText: `Yes, Leave`,
                cancellationButtonProps: {
                    variant: 'contained',
                    color: 'inherit',
                    fullWidth: true,
                },
            })
                .then(() => {
                    leaveLeague(league);
                })
                .catch(noop);
        };

        const handleShare = (league: GameLeaguePublicModel) => {
            startInvitation(league);
        };

        return (
            <Box display="flex" flexDirection="column" alignItems={{ sm: 'start' }} gap={3}>
                <DebouncedSearch onSearch={searchLeagues} label="Search League" />
                <AsyncList
                    length={leagues.length}
                    loadStatus={leaguesLoadStatus}
                    onFetch={fetchLeagues}
                >
                    {setLastElement =>
                        !isDesktop ? (
                            <MyLeaguesMobile
                                onLeave={handleLeave}
                                onShare={handleShare}
                                setLastElement={setLastElement}
                                onClick={handleLeagueClick}
                            />
                        ) : (
                            <CustomTable>
                                <TableBody>
                                    {leagues.map((league, leagueIndex) => (
                                        <TableRow
                                            key={league.id}
                                            ref={
                                                leagueIndex === leagues.length - 1
                                                    ? setLastElement
                                                    : undefined
                                            }
                                            sx={
                                                league.hasStarted
                                                    ? CLICKABLE_TABLE_ROW_SX
                                                    : undefined
                                            }
                                            onClick={() => {
                                                handleLeagueClick(league);
                                            }}
                                        >
                                            {leaguesListColumns.map((column, columnIndex) => (
                                                <TableCell key={columnIndex}>
                                                    {column.getComponent(league)}
                                                </TableCell>
                                            ))}
                                            <TableCell align="right">
                                                {league.hasStarted ? null : (
                                                    <Stack
                                                        direction="row"
                                                        spacing={2}
                                                        justifyContent="flex-end"
                                                    >
                                                        <CustomIconButton
                                                            variant="contained"
                                                            background={customSecondary['300']}
                                                            color="black"
                                                            onClick={event => {
                                                                event.stopPropagation();
                                                                handleShare(league);
                                                            }}
                                                        >
                                                            <UserPlus weight="bold" />
                                                        </CustomIconButton>
                                                        <CustomIconButton
                                                            color={customNegative['500']}
                                                            onClick={event => {
                                                                event.stopPropagation();
                                                                handleLeave(league);
                                                            }}
                                                        >
                                                            <DoorOpen weight="fill" />
                                                        </CustomIconButton>
                                                    </Stack>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </CustomTable>
                        )
                    }
                </AsyncList>
                {!!leagueToShare && (
                    <InvitePlayers
                        onInvite={sendInvite}
                        onClose={endInvitation}
                        getShareLink={getShareLink}
                    />
                )}
            </Box>
        );
    }),
);
