import Dialog from '@mui/material/Dialog';
import { useIsMobile } from '../../common/hooks/use-is-mobile';
import { FC, useEffect } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { IconButton, InputAdornment, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import { Participant } from './participant';
import { observer } from 'mobx-react';
import { ParticipantsStore } from '../stores/participants-store';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { useDependencies } from 'ioc';
import { provide } from 'ioc';
import Box from '@mui/material/Box';
import { ParticipantsGetter } from '../utils/types';

interface ParticipantsProps {
    onClose: () => void;
    getParticipants: ParticipantsGetter;
}

export const Participants: FC<ParticipantsProps> = provide([ParticipantsStore])(
    observer(({ onClose, getParticipants }) => {
        const [{ filteredParticipants, searchTerm, init }] = useDependencies(ParticipantsStore);

        const isMobile = useIsMobile();
        const theme = useTheme();

        useEffect(() => {
            init(getParticipants);
        }, []);

        return (
            <Dialog fullScreen={isMobile} open onClose={onClose} fullWidth maxWidth="md">
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    px={2}
                    sx={{ height: 68 }}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }}>
                        <Typography
                            fontWeight={900}
                            fontSize={24}
                            color={theme.palette.primary.main}
                        >
                            Participants
                        </Typography>
                    </DialogTitle>
                    <IconButton onClick={onClose} sx={{ p: 0, position: 'absolute', right: 22 }}>
                        <CloseIcon sx={{ width: 32, height: 32 }} />
                    </IconButton>
                </Box>
                <DialogContent sx={{ background: '#f9f9f9', px: { sm: '100px' } }}>
                    <Box mb={2}>
                        <TextField
                            placeholder="Search users"
                            value={searchTerm.value}
                            onChange={event => searchTerm.onChange(event.target.value)}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    {
                        // to avoid margin overlaps
                        !!filteredParticipants.length && (
                            <Grid container spacing={1}>
                                {filteredParticipants.map(participant => (
                                    <Grid item xs={12} sm={6} key={participant.id}>
                                        <Participant participant={participant} />
                                    </Grid>
                                ))}
                            </Grid>
                        )
                    }
                </DialogContent>
            </Dialog>
        );
    }),
);
