export const InstagramIcon = () => (
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_bi_351_24679)">
            <mask id="path-1-inside-1_351_24679" fill="white">
                <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" />
            </mask>
            <path
                d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
                fill="#58F081"
            />
            <path
                d="M0 0H40H0ZM40 21C40 32.0457 31.0457 41 20 41C8.9543 41 0 32.0457 0 21V20C0 30.4934 8.9543 39 20 39C31.0457 39 40 30.4934 40 20V21ZM0 40V0V40ZM40 0V40V0Z"
                fill="white"
                fillOpacity="0.1"
                mask="url(#path-1-inside-1_351_24679)"
            />
            <g clipPath="url(#clip0_351_24679)">
                <path
                    d="M24.5837 15.4165H24.592M15.8337 11.6665H24.167C26.4682 11.6665 28.3337 13.532 28.3337 15.8332V24.1665C28.3337 26.4677 26.4682 28.3332 24.167 28.3332H15.8337C13.5325 28.3332 11.667 26.4677 11.667 24.1665V15.8332C11.667 13.532 13.5325 11.6665 15.8337 11.6665ZM23.3337 19.4748C23.4365 20.1684 23.318 20.8767 22.9951 21.499C22.6722 22.1213 22.1613 22.626 21.535 22.9412C20.9087 23.2564 20.199 23.3662 19.5068 23.2548C18.8146 23.1434 18.1751 22.8166 17.6794 22.3208C17.1836 21.825 16.8568 21.1856 16.7454 20.4933C16.634 19.8011 16.7437 19.0914 17.0589 18.4651C17.3741 17.8389 17.8788 17.328 18.5011 17.005C19.1235 16.6821 19.8318 16.5637 20.5253 16.6665C21.2328 16.7714 21.8877 17.1011 22.3934 17.6068C22.8991 18.1125 23.2288 18.7674 23.3337 19.4748Z"
                    stroke="#3A3AD2"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </g>
        <defs>
            <filter
                id="filter0_bi_351_24679"
                x="-40"
                y="-40"
                width="120"
                height="120"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
                <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_351_24679"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_351_24679"
                    result="shape"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="32"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect2_innerShadow_351_24679"
                />
                <feOffset dy="8" />
                <feGaussianBlur stdDeviation="32" />
                <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"
                />
                <feBlend
                    mode="plus-lighter"
                    in2="shape"
                    result="effect2_innerShadow_351_24679"
                />
            </filter>
            <clipPath id="clip0_351_24679">
                <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(10 10)"
                />
            </clipPath>
        </defs>
    </svg>
);
