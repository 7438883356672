import { inject, injectable } from 'ioc';
import { LeagueContentStore } from './league-content-store';
import { makeAutoObservable, runInAction, when } from 'mobx';
import { apiClient } from '../../common/api/api-client';
import { GameModel } from '../../common/api/api';
import { LoadStatus } from '../../common/enums/load-status';
import { PlayerWithBets } from '../utils/types';
import { getDailyTimestampsOfRange } from '../utils/get-daily-timestamps-of-range';

export const ALL_OPTION_VALUE = undefined;

@injectable()
export class MatchupStore {
    @inject(LeagueContentStore)
    private readonly leagueContentStore!: LeagueContentStore;

    loadStatus = LoadStatus.None;
    leagueId!: number;
    games: GameModel[] = [];
    gameIndex = -1;
    playersWithBets: PlayerWithBets[] = [];
    selectedTimestampOption?: number = ALL_OPTION_VALUE;

    constructor() {
        makeAutoObservable(this);
    }

    init = async () => {
        runInAction(() => {
            this.loadStatus = LoadStatus.Loading;
        });

        await when(() => !!this.leagueContentStore.league);
        this.leagueId = this.leagueContentStore.league!.id;

        try {
            const games = await apiClient.matchup(this.leagueId);

            runInAction(() => {
                this.games = games;
                this.loadStatus = LoadStatus.Ok;
            });

            if (games.length) {
                this.changeGame(0);
            }
        } catch {
            runInAction(() => {
                this.loadStatus = LoadStatus.Error;
            });
        }
    };

    changeGame = (gameIndex: number) => {
        runInAction(() => {
            this.gameIndex = gameIndex;
        });

        this.fetchPlayersWithBets();
    };

    changeTimestamp = (timestamp: typeof this.selectedTimestampOption) => {
        runInAction(() => {
            this.selectedTimestampOption = timestamp;
        });

        this.fetchPlayersWithBets();
    };

    fetchPlayersWithBets = async () => {
        if (this.gameId === undefined) {
            return;
        }

        const gameId = this.gameId;

        try {
            const gameInfo = await apiClient.gamesGET2(gameId, undefined);

            const { players } = gameInfo;

            if (!players) {
                return;
            }

            const bets = await Promise.all(
                players.map(player =>
                    apiClient.getBets(
                        gameId,
                        player.id,
                        undefined,
                        this.selectedTimestampOption
                            ? new Date(this.selectedTimestampOption)
                            : undefined,
                    ),
                ),
            );

            const playersWithBets = players.map((player, index) => ({
                player,
                bets: bets[index],
            }));

            runInAction(() => {
                this.playersWithBets = playersWithBets;
            });
        } catch {
            //
        }
    };

    selectNext = () => {
        if (this.isNextDisabled) {
            return;
        }

        this.changeGame(this.gameIndex + 1);
    };

    selectPrev = () => {
        if (this.isPrevDisabled) {
            return;
        }

        this.changeGame(this.gameIndex - 1);
    };

    get isPrevDisabled() {
        return this.gameIndex < 1;
    }

    get isNextDisabled() {
        return this.gameIndex === this.games.length - 1;
    }

    get gameId() {
        return this.games.at(this.gameIndex)?.id;
    }

    get timestampOptions() {
        if (this.games.length) {
            const game = this.games[0];
            return getDailyTimestampsOfRange(game.startDate, game.endDate);
        }

        return [];
    }
}
