import { inject, injectable } from 'ioc';
import { makeAutoObservable, runInAction } from 'mobx';
import { LoadStatus } from '../../common/enums/load-status';
import { FieldState, FormState } from 'formstate';
import { formStateValidators } from '../../common/utils/form-state-validators';
import { AuthStore } from '../../common/stores/auth-store';
import { apiClient } from '../../common/api/api-client';
import {
    ApplicationFileModel,
    ApplicationFileTypeEnum,
    IUserAddModel,
    PlayerPrivacySetting,
    UserAddModel,
} from '../../common/api/api';

@injectable()
export class SignupStore {
    formState = new FormState({
        avatarFile: new FieldState<ApplicationFileModel>(
            new ApplicationFileModel({
                id: '',
                url: '',
                uploadDate: new Date(),
            }),
        ),
        fullName: new FieldState('').validators(formStateValidators.required),
        email: new FieldState('').validators(
            formStateValidators.required,
            formStateValidators.email,
        ),
        phoneNumber: new FieldState('').validators(
            formStateValidators.required,
            formStateValidators.phoneNumber,
        ),
        username: new FieldState('').validators(
            formStateValidators.required,
            formStateValidators.username,
        ),
        password: new FieldState('').validators(
            formStateValidators.required,
            formStateValidators.password,
            () => {
                if (this.formState.$.confirmPassword.hasBeenValidated) {
                    this.formState.$.confirmPassword.validate();
                }

                return false;
            },
        ),
        confirmPassword: new FieldState('').validators(formStateValidators.required, value => {
            if (value !== this.formState.$.password.value) {
                return 'Passwords do not match';
            }
        }),
        agreeTerms: new FieldState(false).validators(value => (value ? false : 'Required')),
    });

    availableAvatarFiles: ApplicationFileModel[] = [];
    signupLoadStatus = LoadStatus.None;

    @inject(AuthStore) private readonly authStore!: AuthStore;

    constructor() {
        this.formState.disableAutoValidation();
        makeAutoObservable(this);
    }

    init = async () => {
        try {
            const availableAvatarFiles = await apiClient.files(
                ApplicationFileTypeEnum.DefaultAvatar,
            );

            runInAction(() => {
                this.availableAvatarFiles = availableAvatarFiles;
            });

            if (availableAvatarFiles.length) {
                this.formState.$.avatarFile.onChange(availableAvatarFiles[0]);
            }
        } catch {
            // skip
        }
    };

    signup = async () => {
        try {
            runInAction(() => {
                this.signupLoadStatus = LoadStatus.Loading;
            });

            await this.formState.enableAutoValidationAndValidate();

            if (this.formState.hasError) {
                throw new Error('Validation error');
            }

            const payload: IUserAddModel = {
                fullName: this.formState.$.fullName.$,
                phoneNumber: this.formState.$.phoneNumber.$,
                email: this.formState.$.email.$,
                password: this.formState.$.password.$,
                username: this.formState.$.username.$,
                // @ts-ignore
                avatarImageId: this.formState.$.avatarFile.$?.id,
                privacySetting: PlayerPrivacySetting.Public,
            };

            const jwtAuthResult = await apiClient.usersPOST(new UserAddModel(payload));

            this.authStore.authorize(jwtAuthResult);
        } catch {
            runInAction(() => {
                this.signupLoadStatus = LoadStatus.Error;
            });

            throw new Error('Unable to _signup');
        }
    };
}
