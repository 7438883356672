import { FC, PropsWithChildren } from 'react';
import { observer } from 'mobx-react';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import { customNeutral, customPrimary, customSecondary, customTertiary } from './colors';
import { useTheme } from '@mui/material';
import { fontFaceDeclarations } from './fonts';

declare module '@mui/material/styles' {
    interface Palette {
        tertiary: Palette['primary'];
    }

    interface PaletteColor {
        // Re-add `main` so IDE shows it
        main: string;
        25?: string;
        50?: string;
        100?: string;
        150?: string;
        200?: string;
        250?: string;
        300?: string;
        350?: string;
        400?: string;
        450?: string;
        500?: string;
        550?: string;
        600?: string;
        650?: string;
        700?: string;
        750?: string;
        800?: string;
        850?: string;
        900?: string;
        950?: string;
    }

    interface PaletteOptions {
        tertiary: PaletteOptions['primary'];
    }
}

const themeOptions: ThemeOptions = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 1024,
            lg: 1920,
            xl: 2000,
        },
    },
    palette: {
        mode: 'dark',
        primary: customPrimary,
        secondary: customSecondary,
        tertiary: customTertiary,
        error: {
            main: '#ff0000',
            light: '#fae0df',
        },
        success: {
            main: '#5BE734',
        },
        text: {
            primary: '#ffffff',
            secondary: customNeutral[200],
        },
        background: {
            default: '#0E1826',
            paper: '#151D2C',
        },
    },
    spacing: 8,
    shape: {
        borderRadius: 8,
    },
    typography: {
        fontFamily: 'Gilroy, Clash Display, Arial',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                 ${fontFaceDeclarations}   
                .notistack-SnackbarContainer {
                    width: unset;
                }
            `,
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderRadius: 16,
                    fontWeight: 700,
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& label.Mui-focused:not(.Mui-error)': { color: 'unset' },
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused:not(.Mui-error) fieldset': { borderColor: 'unset' },
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-focused:not(.Mui-error)': { color: 'unset' },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    '&.MuiOutlinedInput-root': {
                        '&.Mui-focused:not(.Mui-error) fieldset': { borderColor: 'unset' },
                    },
                },
            },
        },
        // MuiIconButton: {
        //     styleOverrides: {
        //         root: {
        //             borderRadius: '8px',
        //         },
        //     },
        // },
        // MuiAvatar: {
        //     styleOverrides: {
        //         colorDefault: {
        //             background: '#ed3ae5',
        //         },
        //     },
        // },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                },
            },
        },
        MuiTabs: {
            defaultProps: {
                textColor: 'secondary',
                indicatorColor: 'secondary',
            },
        },
    },
};

const theme = createTheme(themeOptions);

export const RedesignedThemeProvider: FC<PropsWithChildren> = observer(({ children }) => {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
});

export const TempThemeProvider: FC<PropsWithChildren> = observer(({ children }) => {
    const outerTheme = useTheme();

    return (
        <ThemeProvider
            theme={createTheme(outerTheme, {
                palette: {
                    mode: 'dark',
                    tertiary: undefined,
                    text: { primary: customNeutral[500] },
                },
            })}
        >
            {children}
        </ThemeProvider>
    );
});
