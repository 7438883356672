// TODO: check width and possible other imports

import { FC, SVGProps } from 'react';

interface IBalanceBetLogoProps extends SVGProps<SVGSVGElement> {}

export const BalanceBetLogo: FC<IBalanceBetLogoProps> = (props) => (
    <svg
        // width="179"
        height="48"
        viewBox="0 0 179 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g filter="url(#filter0_d_351_24604)">
            <path
                d="M36.3033 6.60714C35.4406 5.53571 34.47 4.57143 33.3916 3.60714C32.2054 2.53571 30.8034 2 29.1858 2C27.5682 2 26.1663 2.64286 25.0879 3.71428C24.0095 4.67857 23.0389 5.64286 22.1762 6.71429H6L11.6077 37.8929L29.2937 50L46.9796 37.8929L52.5873 6.71429H36.3033V6.60714Z"
                fill="url(#paint0_linear_351_24604)"
            />
        </g>
        <path
            opacity="0.2"
            d="M29.2937 38.6429C28.3231 38.6429 27.4604 38.3214 26.7055 37.6786C25.4114 36.5 24.2252 35.3214 23.1468 34.0357L22.0684 32.75H36.4112L35.3328 34.0357C34.2544 35.3214 33.0681 36.5 31.7741 37.6786C31.127 38.3214 30.1564 38.6429 29.2937 38.6429Z"
            fill="#3A3AD2"
        />
        <path
            opacity="0.2"
            d="M22.0684 11.5359L23.1468 10.2502C24.2252 8.96449 25.4114 7.78592 26.7055 6.60735C27.4604 5.96449 28.3231 5.64307 29.2937 5.64307C30.2643 5.64307 31.127 5.96449 31.8819 6.60735C33.176 7.78592 34.3622 8.96449 35.4406 10.2502L36.5191 11.5359H22.0684Z"
            fill="#3A3AD2"
        />
        <path
            opacity="0.5"
            d="M13.765 37.5716L8.91211 10.1431H20.4511L17.3237 14.9645H14.6277L18.1864 34.7859L29.2941 42.2859L40.2939 34.7859L43.8526 14.9645H40.186L36.9508 10.1431H49.676L44.7153 37.5716L29.2941 48.0716L13.765 37.5716Z"
            fill="#3A3AD2"
        />
        <path
            opacity="0.2"
            d="M22.0684 11.5359L23.1468 10.2502C24.2252 8.96449 25.4114 7.78592 26.7055 6.60735C27.4604 5.96449 28.3231 5.64307 29.2937 5.64307C30.2643 5.64307 31.127 5.96449 31.8819 6.60735C33.176 7.78592 34.3622 8.96449 35.4406 10.2502L36.5191 11.5359H22.0684Z"
            fill="#3A3AD2"
        />
        <path
            opacity="0.2"
            d="M20.9901 30.4999L20.8823 30.2856C19.2646 27.4999 18.5098 24.8214 18.5098 22.1428C18.5098 19.4642 19.3725 16.7856 20.8823 13.9999L20.9901 13.7856H37.4898L37.5976 13.9999C39.2152 16.7856 39.9701 19.4642 39.9701 22.1428C39.9701 24.8214 39.1074 27.4999 37.5976 30.2856L37.4898 30.4999H20.9901Z"
            fill="#3A3AD2"
        />
        <path
            d="M14.0021 36.3177L9.20312 9.31771L19.8794 9.30699L17.1941 13.5927L14.2824 13.6034L17.9814 33.8427L29.3263 41.5356L40.628 33.7999L44.1544 13.5391L40.2721 13.5498L37.4575 9.27485L49.32 9.25342L44.5211 36.2534L29.3371 46.6784L14.0021 36.3177Z"
            fill="url(#paint1_linear_351_24604)"
        />
        <path
            d="M32.0873 21.5001H34.4598V22.7859H32.0873C31.5481 22.7859 31.3324 22.5823 31.3324 22.1537C31.3324 21.7251 31.5481 21.5109 32.0873 21.5109V21.5001ZM26.9109 19.7859C26.9109 19.3573 26.6952 19.143 26.156 19.143H23.9992V20.3216H26.156C26.6952 20.4287 26.9109 20.2037 26.9109 19.7751V19.7859ZM26.3717 21.5109H23.9992V22.7966H26.3717C26.9109 22.7966 27.1266 22.5716 27.1266 22.143C27.1266 21.7144 26.9109 21.5001 26.3717 21.5001V21.5109ZM39.5283 21.0501C39.5283 23.6216 38.7842 26.193 37.1666 28.8716L21.314 28.9037C19.8042 26.2251 18.9307 23.5466 18.9307 21.0823C18.9307 18.618 19.6748 15.9394 21.2924 13.2609L37.145 13.2287C38.7626 15.8001 39.5283 18.4787 39.5283 21.0501ZM28.5285 22.3573C28.5285 21.5001 27.9893 20.9644 26.8031 20.8573V20.7501C27.8815 20.643 28.205 20.1073 28.205 19.3573C28.205 18.393 27.5579 17.8573 26.2639 17.8573H22.4894L22.5002 24.0823H26.3825C27.8923 24.0823 28.5393 23.4287 28.5393 22.3573H28.5285ZM35.8617 17.9537H32.0873C30.7932 17.9537 30.1461 18.5001 30.1461 19.4644C30.1461 20.2144 30.5775 20.7501 31.5481 20.8573V20.9644C30.3618 21.0716 29.8226 21.6073 29.8226 22.4644C29.8226 23.5359 30.4697 24.1787 31.8716 24.1787H35.7539L35.7431 17.9537H35.8509H35.8617ZM23.3737 32.7609C24.4521 34.0466 25.6384 35.2251 26.8354 36.393C27.4825 37.0359 28.3452 37.3573 29.2079 37.3573C30.0706 37.3573 30.9334 37.0359 31.5804 36.393C32.8745 35.2144 34.0608 34.0359 35.0205 32.7394L35.7754 31.8823L22.6188 31.9037L23.3737 32.7609ZM35.0853 9.27514C34.0068 7.98942 32.8206 6.81085 31.6236 5.643C30.9765 5.00014 30.1138 4.67871 29.2511 4.67871C28.3883 4.67871 27.5256 5.00014 26.8786 5.643C25.5845 6.82157 24.3982 8.00014 23.4384 9.29657L22.6835 10.1537L35.8401 10.1323L35.0853 9.27514ZM31.5481 19.7859C31.5481 20.2144 31.7637 20.4287 32.303 20.4287H34.4598V19.2501H32.303C31.7637 19.143 31.5481 19.368 31.5481 19.7966V19.7859Z"
            fill="url(#paint2_linear_351_24604)"
        />
        <path
            d="M62.873 32.6861V18.9902H70.9527C73.7168 18.9902 75.2051 20.1765 75.2051 22.3333C75.2051 24.0588 74.3546 25.245 72.0158 25.4607V25.6764C74.461 25.8921 75.7367 27.0783 75.7367 28.9116C75.7367 31.2841 74.3546 32.6861 71.2716 32.6861H62.873ZM65.9561 24.3823H70.6338C71.6969 24.3823 72.2284 23.9509 72.2284 23.0882C72.2284 22.2255 71.6969 21.6863 70.6338 21.6863H65.9561V24.3823ZM65.9561 29.8822H71.059C72.3347 29.8822 72.76 29.4508 72.76 28.3724C72.76 27.294 72.2284 26.8626 71.059 26.8626H65.9561V29.8822Z"
            fill="white"
        />
        <path
            d="M76.5869 30.0979C76.5869 28.5881 77.65 27.6176 79.8826 27.4019L84.2413 26.9705V26.5392C84.2413 25.2451 83.7098 24.8137 82.0088 24.8137C80.5204 24.8137 79.8826 25.2451 79.8826 26.4313V26.5392H76.7995V26.4313C76.7995 23.951 78.9258 22.1177 82.2214 22.1177C85.5171 22.1177 87.218 23.8431 87.218 26.5392V32.5783H84.3476V30.2058H84.135C83.7098 31.8234 82.2214 32.7939 79.9889 32.7939C77.8627 32.7939 76.5869 31.8234 76.5869 30.0979ZM80.8394 30.6371C82.8593 30.6371 84.0287 30.0979 84.0287 28.696L80.5204 29.1273C79.7763 29.2352 79.4573 29.343 79.4573 29.8822C79.5636 30.4214 79.9889 30.6371 80.8394 30.6371Z"
            fill="white"
        />
        <path
            d="M88.6006 32.6861V18.9902H91.5773V32.6861H88.6006Z"
            fill="white"
        />
        <path
            d="M92.8525 30.0979C92.8525 28.5881 93.9156 27.6176 96.1482 27.4019L100.507 26.9705V26.5392C100.507 25.2451 99.9754 24.8137 98.2744 24.8137C96.786 24.8137 96.1482 25.2451 96.1482 26.4313V26.5392H93.0652V26.4313C93.0652 23.951 95.1914 22.1177 98.487 22.1177C101.783 22.1177 103.484 23.8431 103.484 26.5392V32.5783H100.72V30.2058H100.507C100.082 31.8234 98.5933 32.7939 96.3608 32.7939C94.2346 32.7939 92.8525 31.8234 92.8525 30.0979ZM97.2113 30.6371C99.2312 30.6371 100.401 30.0979 100.401 28.696L96.8924 29.1273C96.1482 29.2352 95.8293 29.343 95.8293 29.8822C95.9356 30.4214 96.3608 30.6371 97.2113 30.6371Z"
            fill="white"
        />
        <path
            d="M104.973 32.6862V22.4413H107.737V25.5687H107.949C108.162 23.8432 109.438 22.2256 112.096 22.2256C114.753 22.2256 116.135 24.0589 116.135 26.3235V32.6862H113.159V27.2941C113.159 25.6765 112.521 24.9216 110.607 24.9216C108.694 24.9216 108.056 25.6765 108.056 27.402V32.5783H104.973V32.6862Z"
            fill="white"
        />
        <path
            d="M117.41 27.5098C117.41 24.2746 119.643 22.2256 123.045 22.2256C126.234 22.2256 128.467 23.951 128.467 26.6471V26.9706H125.49V26.7549C125.49 25.4608 124.533 25.0295 122.938 25.0295C121.131 25.0295 120.281 25.6765 120.281 27.6176C120.281 29.4509 121.131 30.2058 122.938 30.2058C124.533 30.2058 125.49 29.7745 125.49 28.4804V28.2647H128.467V28.5882C128.467 31.1764 126.234 33.0097 123.045 33.0097C119.536 32.794 117.41 30.745 117.41 27.5098Z"
            fill="white"
        />
        <path
            d="M129.53 27.5098C129.53 24.2746 131.763 22.2256 135.165 22.2256C138.567 22.2256 140.693 23.951 140.693 27.1863C140.693 27.5098 140.693 27.8333 140.587 28.1568H132.401C132.507 29.7745 133.251 30.4215 135.165 30.4215C136.972 30.4215 137.61 29.9901 137.61 29.0196V28.8039H140.587V29.0196C140.587 31.2842 138.46 32.794 135.271 32.794C131.763 32.794 129.53 31.1764 129.53 27.5098ZM132.294 26.5392H137.61C137.504 25.1373 136.759 24.5981 134.952 24.5981C133.251 24.5981 132.507 25.2451 132.294 26.5392Z"
            fill="white"
        />
        <path
            d="M144.945 32.6861V18.9902H153.025C155.789 18.9902 157.277 20.1765 157.277 22.3333C157.277 24.0588 156.427 25.245 154.088 25.4607V25.6764C156.533 25.8921 157.809 27.0783 157.809 28.9116C157.809 31.2841 156.427 32.6861 153.344 32.6861H144.945ZM147.922 24.3823H152.6C153.663 24.3823 154.194 23.9509 154.194 23.0882C154.194 22.2255 153.663 21.6863 152.6 21.6863H147.922V24.3823ZM147.922 29.8822H153.025C154.301 29.8822 154.726 29.4508 154.726 28.3724C154.726 27.294 154.194 26.8626 153.025 26.8626H147.922V29.8822Z"
            fill="white"
        />
        <path
            d="M158.659 27.5098C158.659 24.2746 160.892 22.2256 164.294 22.2256C167.696 22.2256 169.822 23.951 169.822 27.1863C169.822 27.5098 169.822 27.8333 169.716 28.1568H161.53C161.636 29.7745 162.38 30.4215 164.294 30.4215C166.101 30.4215 166.739 29.9901 166.739 29.0196V28.8039H169.716V29.0196C169.716 31.2842 167.589 32.794 164.4 32.794C160.892 32.794 158.659 31.1764 158.659 27.5098ZM161.53 26.5392H166.845C166.739 25.1373 165.995 24.5981 164.187 24.5981C162.38 24.5981 161.636 25.2451 161.53 26.5392Z"
            fill="white"
        />
        <path
            d="M175.669 32.6861C173.33 32.6861 171.842 31.6077 171.842 29.0195V25.0294H170.247V22.5491H171.842V20.6079H174.818V22.5491H178.114V25.0294H174.818V28.696C174.818 29.6666 175.244 29.9901 176.307 29.9901H178.114V32.794H175.669V32.6861Z"
            fill="white"
        />
        <defs>
            <filter
                id="filter0_d_351_24604"
                x="0.607947"
                y="0.651987"
                width="57.371"
                height="58.7841"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="4.04404" />
                <feGaussianBlur stdDeviation="2.69603" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.345098 0 0 0 0 0.941176 0 0 0 0 0.505882 0 0 0 0.5 0"
                />
                <feBlend
                    mode="lighten"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_351_24604"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_351_24604"
                    result="shape"
                />
            </filter>
            <linearGradient
                id="paint0_linear_351_24604"
                x1="29.2937"
                y1="49.9143"
                x2="29.2937"
                y2="2.08571"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#58F081" />
                <stop offset="1" stopColor="#3A3AD2" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_351_24604"
                x1="29.3344"
                y1="46.6805"
                x2="29.2632"
                y2="9.35202"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#58F081" />
                <stop offset="0.03" stopColor="#63F189" />
                <stop offset="0.13" stopColor="#8CF5A8" />
                <stop offset="0.25" stopColor="#B0F8C3" />
                <stop offset="0.37" stopColor="#CDFAD9" />
                <stop offset="0.49" stopColor="#E3FCEA" />
                <stop offset="0.63" stopColor="#F3FEF6" />
                <stop offset="0.79" stopColor="#FCFFFD" />
                <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_351_24604"
                x1="29.1985"
                y1="4.75577"
                x2="29.2606"
                y2="37.2736"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#58F081" />
                <stop offset="0.04" stopColor="#6CF290" />
                <stop offset="0.14" stopColor="#93F5AE" />
                <stop offset="0.24" stopColor="#B5F8C7" />
                <stop offset="0.35" stopColor="#D0FBDB" />
                <stop offset="0.47" stopColor="#E5FDEB" />
                <stop offset="0.6" stopColor="#F4FEF6" />
                <stop offset="0.76" stopColor="#FCFFFD" />
                <stop offset="1" stopColor="white" />
            </linearGradient>
        </defs>
    </svg>
);

export const HeaderLogoMobile = () => (
    <svg
        width="153"
        height="18"
        viewBox="0 0 153 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_315_5559)">
            <path
                d="M0.709961 17.7483V0H11.3533C14.9757 0 16.9477 1.53846 16.9477 4.32168C16.9477 6.51748 15.7869 8.11189 12.8218 8.32168V8.55944C16.1086 8.7972 17.689 10.4336 17.689 12.8252C17.689 15.8462 15.8428 17.7483 11.8708 17.7483H0.709961ZM4.68199 7.04895H10.9058C12.3603 7.04895 12.9757 6.54545 12.9757 5.32867C12.9757 4.11189 12.3463 3.58042 10.8638 3.58042H4.68199V7.04895ZM4.68199 14.1678H11.3813C13.0037 14.1678 13.661 13.6643 13.661 12.2378C13.661 10.8112 13.0037 10.3357 11.3813 10.3357H4.68199V14.1818V14.1678Z"
                fill="white"
            />
            <path
                d="M18.6396 14.4612C18.6396 12.5032 20.0942 11.2864 22.9334 10.9927L28.6257 10.4053V9.90178C28.6257 8.1815 27.8564 7.70598 25.7445 7.70598C23.6327 7.70598 22.9054 8.23744 22.9054 9.74793V9.85982H18.9054V9.77591C18.9054 6.48919 21.6606 4.20947 26.0243 4.20947C30.3879 4.20947 32.5417 6.48919 32.5417 9.95772V17.7479H28.8355V14.699H28.6257C28.0243 16.7689 26.1641 18.0137 23.2271 18.0137C20.29 18.0137 18.6396 16.685 18.6396 14.4612ZM24.3599 15.2025C27.0592 15.2025 28.5138 14.5731 28.6257 12.685L24.0243 13.2165C23.0732 13.3004 22.6536 13.5661 22.6536 14.1955C22.6536 14.9088 23.2131 15.2025 24.3739 15.2025H24.3599Z"
                fill="white"
            />
            <path
                d="M34.5703 17.7483V0H38.5423V17.7483H34.5703Z"
                fill="white"
            />
            <path
                d="M40.1367 14.4615C40.1367 12.5034 41.5913 11.2866 44.4304 10.9929L50.1227 10.4055V9.90202C50.1227 8.18174 49.3535 7.70622 47.2416 7.70622C45.1297 7.70622 44.4025 8.23769 44.4025 9.74818V9.86007H40.4025V9.77615C40.4025 6.48944 43.1577 4.20972 47.5213 4.20972C51.885 4.20972 54.0388 6.48944 54.0388 9.95797V17.7482H50.3325V14.6992H50.1227C49.5073 16.7692 47.6612 18.0139 44.7241 18.0139C41.7871 18.0139 40.1507 16.6852 40.1507 14.4615H40.1367ZM45.843 15.2027C48.5423 15.2027 49.9969 14.5734 50.1087 12.6852L45.5073 13.2167C44.5563 13.3006 44.1227 13.5664 44.1227 14.1957C44.1227 14.909 44.6822 15.2027 45.843 15.2027Z"
                fill="white"
            />
            <path
                d="M56.053 17.7479V4.47521H59.7314V8.54514H59.9691C60.3188 6.32136 61.8992 4.20947 65.3677 4.20947C68.8363 4.20947 70.7104 6.54514 70.7104 9.4822V17.7479H66.7384V10.8109C66.7384 8.69898 65.8712 7.78989 63.4237 7.78989C60.9761 7.78989 60.0111 8.79689 60.0111 11.0486V17.7479H56.0391H56.053Z"
                fill="white"
            />
            <path
                d="M72.3467 11.1186C72.3467 6.8808 75.2558 4.20947 79.8152 4.20947C84.0809 4.20947 86.99 6.48919 86.99 9.92975V10.3074H83.046V10.0976C83.046 8.40528 81.8012 7.78989 79.7313 7.78989C77.3816 7.78989 76.2907 8.65702 76.2907 11.1186C76.2907 13.5801 77.3816 14.4332 79.7313 14.4332C81.8012 14.4332 83.046 13.8179 83.046 12.1256V11.9158H86.99V12.2934C86.99 15.706 84.0809 18.0137 79.8152 18.0137C75.2558 18.0137 72.3467 15.3144 72.3467 11.1325V11.1186Z"
                fill="white"
            />
            <path
                d="M88.3187 11.1186C88.3187 6.8808 91.3117 4.20947 95.6753 4.20947C100.039 4.20947 102.906 6.48919 102.906 10.643C102.906 11.1186 102.85 11.4682 102.794 11.9158H91.997C92.1089 13.9577 93.0599 14.8249 95.6194 14.8249C97.969 14.8249 98.7942 14.2095 98.7942 13.0766V12.8109H102.766V13.1046C102.766 15.9857 99.955 17.9997 95.7452 17.9997C91.3257 17.9997 88.3047 15.8319 88.3047 11.1186H88.3187ZM92.0529 9.81786H99.0879C98.976 8.06961 97.9271 7.30038 95.5914 7.30038C93.2557 7.30038 92.2348 8.09758 92.039 9.81786H92.0529Z"
                fill="white"
            />
            <path
                d="M108.612 17.7483V0H119.256C122.878 0 124.85 1.53846 124.85 4.32168C124.85 6.51748 123.689 8.11189 120.724 8.32168V8.55944C124.011 8.7972 125.591 10.4336 125.591 12.8252C125.591 15.8462 123.731 17.7483 119.759 17.7483H108.612ZM112.584 7.04895H118.808C120.263 7.04895 120.878 6.54545 120.878 5.32867C120.878 4.11189 120.249 3.58042 118.752 3.58042H112.584V7.04895ZM112.584 14.1678H119.284C120.892 14.1678 121.563 13.6643 121.563 12.2378C121.563 10.8112 120.906 10.3357 119.284 10.3357H112.584V14.1818V14.1678Z"
                fill="white"
            />
            <path
                d="M126.654 11.1186C126.654 6.8808 129.647 4.20947 134.01 4.20947C138.374 4.20947 141.241 6.48919 141.241 10.643C141.241 11.1186 141.185 11.4682 141.129 11.9158H130.332C130.444 13.9577 131.395 14.8249 133.954 14.8249C136.304 14.8249 137.129 14.2095 137.129 13.0766V12.8109H141.101V13.1046C141.101 15.9857 138.29 17.9997 134.08 17.9997C129.661 17.9997 126.64 15.8319 126.64 11.1186H126.654ZM130.388 9.81786H137.437C137.325 8.06961 136.276 7.30038 133.94 7.30038C131.605 7.30038 130.584 8.09758 130.388 9.81786Z"
                fill="white"
            />
            <path
                d="M149.017 17.748C145.912 17.748 144.038 16.2935 144.038 12.9508V7.762H141.94V4.47528H144.038V1.90186H148.01V4.47528H152.304V7.762H148.01V12.5033C148.01 13.8319 148.514 14.1676 149.912 14.1676H152.29V17.748H149.003H149.017Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_315_5559">
                <rect
                    width="151.58"
                    height="18"
                    fill="white"
                    transform="translate(0.709961)"
                />
            </clipPath>
        </defs>
    </svg>
);
