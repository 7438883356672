import { FC, Fragment } from 'react';
import { observer } from 'mobx-react';
import { GameLeaguePublicModel } from '../../../common/api/api';
import { useDependencies } from 'ioc';
import { NotificationsStore } from '../../../common/stores/notifications-store';
import { LeaguesStore } from '../../stores/leagues-store';
import { IconButton, useTheme } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { noop } from '../../../common/utils/noop';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import { ColoredBorder } from '../../../common/components/colored-border/colored-border';
import Button from '@mui/material/Button';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import { GameLeagueListItemMobile } from '../../../common/components/game-leagues/mobile/game-league-list-item-mobile';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import { CustomIconButton } from '../../../common/components/custom-icon-button/custom-icon-button';

interface InvitedLeagueProps {
    league: GameLeaguePublicModel;
}

export const InvitedLeague: FC<InvitedLeagueProps> = observer(({ league }) => {
    const [{ joinInvitedLeague, cancelGameInvitation, showParticipants }, { notify }] =
        useDependencies(LeaguesStore, NotificationsStore);

    const navigate = useNavigate();

    const leaguePathname = `/leagues/content/${league.id}`;
    const theme = useTheme();

    // TODO: improve typing
    if (league.isParticipating) {
        return <Navigate to={leaguePathname} replace />;
    }

    const handleJoinLeague = () => {
        joinInvitedLeague(league.id)
            .then(() => {
                notify({
                    severity: 'info',
                    children: (
                        <Fragment>
                            <Typography mr={2} variant="body1">
                                Joined league <strong>{league.name}</strong>!
                            </Typography>
                            <Typography variant="body2">
                                You can find the league in&nbsp;
                                <Link
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        // closeNotification();
                                        navigate('/leagues/my');
                                    }}
                                >
                                    My Leagues
                                </Link>
                                &nbsp;page.
                            </Typography>
                        </Fragment>
                    ),
                });

                navigate(leaguePathname, { replace: true });
            })
            .catch(noop);
    };

    return (
        <Dialog open onClose={cancelGameInvitation} fullWidth maxWidth="xs">
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h5" fontWeight="700">
                        You’re invited to join
                    </Typography>
                    <IconButton onClick={cancelGameInvitation}>
                        <CloseIcon sx={{ width: 32, height: 32 }} />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <ColoredBorder sx={{ borderRadius: 1 }}>
                    <GameLeagueListItemMobile
                        league={league}
                        actions={
                            <Box display="flex" gap={2}>
                                <CustomIconButton
                                    onClick={() => showParticipants(league)}
                                    color={theme.palette.grey[800]}
                                >
                                    <Groups2OutlinedIcon />
                                </CustomIconButton>
                                <Button
                                    onClick={handleJoinLeague}
                                    endIcon={<DirectionsWalkIcon />}
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                        boxShadow: 1,
                                    }}
                                >
                                    <Typography>Join</Typography>
                                </Button>
                            </Box>
                        }
                    />
                </ColoredBorder>
            </DialogContent>
        </Dialog>
    );
});
