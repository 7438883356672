import { ConferencePublicModel } from '../../../../common/api/api';
import { FC, Fragment } from 'react';
import { TableHead, TypographyProps } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CustomTable } from '../../../../common/components/custom-table/custom-table';
import { useIsMobile } from '../../../../common/hooks/use-is-mobile';
import { PlayerInfo } from './player-info';

const StandingsTypographyCell: FC<TypographyProps> = props => {
    return (
        <Typography fontSize={20} {...props}>
            {props.children}
        </Typography>
    );
};

interface StandingsTableProps {
    conference: ConferencePublicModel;
}

export const StandingsTable: FC<StandingsTableProps> = ({ conference }) => {
    const isMobile = useIsMobile();
    const pointCellSx = { textAlign: 'center' };

    return (
        <CustomTable>
            <TableHead>
                <TableCell>
                    <Typography fontSize={28}>{conference.name}</Typography>
                </TableCell>
                {!isMobile && (
                    <Fragment>
                        <TableCell sx={pointCellSx}>W</TableCell>
                        <TableCell sx={pointCellSx}>T</TableCell>
                        <TableCell sx={pointCellSx}>L</TableCell>
                        <TableCell sx={pointCellSx}>PT</TableCell>
                    </Fragment>
                )}
            </TableHead>
            <TableBody>
                {conference.players?.map((player, index) => {
                    const {
                        id,
                        stats: { winCount, loseCount, tieCount, points },
                    } = player;

                    return (
                        <TableRow key={id}>
                            <TableCell>
                                <Box display="flex" alignItems="center" gap={2}>
                                    <Typography
                                        fontWeight={700}
                                        fontSize={{ xs: 12, sm: 16 }}
                                        align="center"
                                        sx={{ minWidth: 26 }}
                                    >
                                        #{index + 1}
                                    </Typography>
                                    <PlayerInfo
                                        player={player}
                                        mainInfo={
                                            isMobile ? (
                                                <Typography color="textSecondary" fontSize={12}>
                                                    ({winCount}-{loseCount}-{tieCount}, {points}pt)
                                                </Typography>
                                            ) : undefined
                                        }
                                    />
                                </Box>
                            </TableCell>
                            {!isMobile && (
                                <Fragment>
                                    <TableCell>
                                        <StandingsTypographyCell sx={pointCellSx}>
                                            {winCount}
                                        </StandingsTypographyCell>
                                    </TableCell>
                                    <TableCell>
                                        <StandingsTypographyCell sx={pointCellSx}>
                                            {tieCount}
                                        </StandingsTypographyCell>
                                    </TableCell>
                                    <TableCell>
                                        <StandingsTypographyCell sx={pointCellSx}>
                                            {loseCount}
                                        </StandingsTypographyCell>
                                    </TableCell>
                                    <TableCell>
                                        <StandingsTypographyCell sx={pointCellSx}>
                                            {points}
                                        </StandingsTypographyCell>
                                    </TableCell>
                                </Fragment>
                            )}
                        </TableRow>
                    );
                })}
            </TableBody>
        </CustomTable>
    );
};
