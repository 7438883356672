import { observer } from 'mobx-react';
import Grid from '@mui/material/Grid';
import { Player } from './player';
import { useDependencies } from 'ioc';
import { provide } from 'ioc';
import { AsyncList } from '../../common/components/async-table-list/async-list';
import { FriendRequestsStore } from '../stores/friend-requests-store';
import { PlayersContainer } from './players-container';

export const FriendRequests = provide([FriendRequestsStore])(
    observer(() => {
        const [{ fetchPlayers, players, loadStatus, acceptFriendRequest, denyFriendRequest }] =
            useDependencies(FriendRequestsStore);

        return (
            <PlayersContainer>
                <AsyncList
                    length={players.length}
                    loadStatus={loadStatus}
                    onFetch={fetchPlayers}
                    prefetch
                >
                    {setLastElement => (
                        <Grid container spacing={1}>
                            {players.map((player, index) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    key={player.id}
                                    ref={index === players.length - 1 ? setLastElement : undefined}
                                >
                                    <Player
                                        player={player}
                                        onDeny={denyFriendRequest}
                                        onAccept={acceptFriendRequest}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </AsyncList>
            </PlayersContainer>
        );
    }),
);
