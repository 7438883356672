import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import { FriendsContent } from './friends-content';

export const FriendsPage = observer(() => {
    return (
        <Box
            height="100%"
            px={{ xs: 1, sm: 20 }}
            py={{ xs: 3, sm: 4 }}
            display="flex"
            justifyContent="center"
        >
            <Box width={1200}>
                <FriendsContent />
            </Box>
        </Box>
    );
});
