import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import { PlayoffStore } from '../../../stores/playoff-store';
import { PlayoffSection } from './playoff-section';
import { PlayoffGame } from './playoff-game';

export const ConsolationLadder = observer(() => {
    const [{ gameLeaguePlayoffData }] = useDependencies(PlayoffStore);

    return (
        <PlayoffSection title="Consolation Ladder">
            {roundNumber =>
                gameLeaguePlayoffData?.consolationLadder
                    .find(item => item.roundNumber === roundNumber)
                    ?.games.map(game => {
                        return <PlayoffGame game={game} />;
                    })
            }
        </PlayoffSection>
    );
});
