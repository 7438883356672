import { injectable } from 'ioc';
import { makeAutoObservable, runInAction } from 'mobx';
import { FieldState, FormState } from 'formstate';
import { formStateValidators } from '../../common/utils/form-state-validators';
import { LoadStatus } from '../../common/enums/load-status';
import { apiClient } from '../../common/api/api-client';
import { IUserPasswordChangeModel, UserPasswordChangeModel } from '../../common/api/api';

@injectable()
export class ChangePasswordStore {
    changePasswordLoadStatus = LoadStatus.None;

    formState = new FormState({
        currentPassword: new FieldState('').validators(formStateValidators.required),
        newPassword: new FieldState('').validators(
            formStateValidators.required,
            formStateValidators.password,
            () => {
                if (this.formState.$.confirmPassword.hasBeenValidated) {
                    this.formState.$.confirmPassword.validate();
                }

                return false;
            },
        ),
        confirmPassword: new FieldState('').validators(formStateValidators.required, value => {
            if (value !== this.formState.$.newPassword.value) {
                return 'Passwords do not match';
            }
        }),
    });

    constructor() {
        this.formState.disableAutoValidation();
        makeAutoObservable(this);
    }

    save = async () => {
        try {
            runInAction(() => {
                this.changePasswordLoadStatus = LoadStatus.Loading;
            });

            await this.formState.enableAutoValidationAndValidate();

            if (this.formState.hasError) {
                throw new Error('Validation error');
            }

            const payload: IUserPasswordChangeModel = {
                oldPassword: this.formState.$.currentPassword.$,
                newPassword: this.formState.$.newPassword.$,
            };

            await apiClient.password(new UserPasswordChangeModel(payload));

            runInAction(() => {
                this.changePasswordLoadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.changePasswordLoadStatus = LoadStatus.Error;
            });

            throw new Error('Unable to change password');
        }
    };
}
