import { FC, Fragment, useState } from 'react';
import { observer } from 'mobx-react';
import { NotificationModel, NotificationTypeEnum } from '../../common/api/api';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useDependencies } from 'ioc';
import { UserNotificationsStore } from '../stores/user-notifications-store';

interface UserNotificationProps {
    notification: NotificationModel;
}

const mapNotificationNameToType: Record<NotificationTypeEnum, string> = {
    [NotificationTypeEnum.FriendRequest]: 'New Friend Request',
    [NotificationTypeEnum.GameInvitation]: 'Game Invitation',
    [NotificationTypeEnum.GameLeagueInvitation]: 'League Invitation',
    [NotificationTypeEnum.Informative]: 'Information',
};

export const UserNotification: FC<UserNotificationProps> = observer(({ notification }) => {
    const [{ takeAction }] = useDependencies(UserNotificationsStore);

    const { hasAccept, message, id, type, iconUrl } = notification;

    const [isLoading, setIsLoading] = useState(false);

    const getActionTaker = (decision: boolean) => () => {
        setIsLoading(true);
        takeAction(notification, decision).finally(() => setIsLoading(false));
    };

    return (
        <Box display="flex" alignItems="start" sx={{ p: 2 }} gap={1.5}>
            <Avatar src={iconUrl} />
            <Box display="flex" flexDirection="column" width="100%">
                <Typography variant="caption" color="textSecondary" mb={0.5}>
                    {mapNotificationNameToType[type]}
                </Typography>
                <Typography variant="body2" key={message}>
                    {message?.split(' ').map((word, index) => {
                        if (word.includes('#') || word.includes('@')) {
                            return (
                                <Typography
                                    component="span"
                                    variant="inherit"
                                    fontWeight={900}
                                    key={index}
                                >
                                    {word}{' '}
                                </Typography>
                            );
                        }
                        return <Fragment key={index}>{word} </Fragment>;
                    })}
                </Typography>
                {hasAccept && (
                    <Box display="flex" gap={1.5} mt={1.5}>
                        <Button
                            variant="outlined"
                            color="error"
                            fullWidth
                            size="large"
                            disabled={isLoading}
                            onClick={getActionTaker(false)}
                        >
                            Reject
                        </Button>
                        <Button
                            variant="contained"
                            fullWidth
                            size="large"
                            disabled={isLoading}
                            onClick={getActionTaker(true)}
                        >
                            Accept
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
});
