import { FC } from 'react';
import { observer } from 'mobx-react';
import { GeneralAnnouncementMessageModel } from '../../../common/api/api';
import { Paper } from '@mui/material';
import { useDependencies } from 'ioc';
import { AnnouncementsStore } from '../../stores/announcements-store';
import Dialog from '@mui/material/Dialog';

interface GeneralAnnouncementProps {
    content: GeneralAnnouncementMessageModel;
}

export const GeneralAnnouncement: FC<GeneralAnnouncementProps> = observer(({ content }) => {
    const [{ viewCurrentAnnouncement }] = useDependencies(AnnouncementsStore);

    return (
        <Dialog open onClose={viewCurrentAnnouncement}>
            <Paper sx={{ width: 400, height: 400, overflowY: 'auto' }}>
                General Announcement
                <pre>{JSON.stringify(content, null, 2)}</pre>
            </Paper>
        </Dialog>
    );
});
