import { GameLeagueWeekModel } from '../../common/api/api';

export const getRangeIndexForCurrentDate = (
    dateRanges: GameLeagueWeekModel[]
) => {
    if (!dateRanges.length) {
        return undefined;
    }

    const firstRange = dateRanges[0];
    const lastRange = dateRanges[dateRanges.length - 1];
    const currentDateTime = new Date().getTime();

    if (currentDateTime < firstRange.startDate.getTime()) {
        return 0;
    }

    if (currentDateTime > lastRange.endDate.getTime()) {
        return dateRanges.length - 1;
    }

    let index = 0;

    for (const range of dateRanges) {
        const startDateTime = range.startDate.getTime();
        const endDateTime = range.endDate.getTime();

        if (
            currentDateTime >= startDateTime &&
            currentDateTime <= endDateTime
        ) {
            break;
        }

        ++index;
    }

    return index;
};
