import { FC, PropsWithChildren } from 'react';
import { useScreenSize } from '../../../../common/hooks/use-is-mobile';
import { AuthMobileLayout } from './auth-mobile-layout';
import { AuthDesktopLayout } from './auth-desktop-layout';

export const AuthLayout: FC<PropsWithChildren> = ({ children, ...rest }) => {
    const { isDesktop } = useScreenSize();

    return isDesktop ? (
        <AuthDesktopLayout {...rest}>{children}</AuthDesktopLayout>
    ) : (
        <AuthMobileLayout {...rest}>{children}</AuthMobileLayout>
    );
};
