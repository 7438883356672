import { Fragment } from 'react';
import { observer } from 'mobx-react';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import { useDependencies } from 'ioc';
import { LeaderboardStore } from '../stores/leaderboard-store';
import { GamePlayer } from '../../common/components/game-player/game-player';

export const LeaderboardContent = observer(() => {
    const [{ leaders, me, showBetHistory }] = useDependencies(LeaderboardStore);

    const cardSx = { width: { xs: '100%', sm: 360 } };

    return (
        <Fragment>
            <DialogContent sx={{ background: '#f9f9f9', px: { sm: '100px' } }}>
                <Box display="flex" alignItems="center" flexDirection="column" gap={1}>
                    {leaders.map((player, index) => (
                        <Box sx={cardSx} key={index}>
                            <GamePlayer
                                player={player}
                                rank={index + 1}
                                onClick={() => {
                                    showBetHistory(player.id);
                                }}
                            />
                        </Box>
                    ))}
                </Box>
            </DialogContent>
            {me && (
                <Box
                    display="flex"
                    justifyContent="center"
                    px={3}
                    py={2}
                    boxShadow="0px -4px 16px 4px #62626233"
                >
                    <Box sx={cardSx}>
                        <GamePlayer
                            rank={me.rank}
                            player={me.info}
                            onClick={() => {
                                showBetHistory(me.info.id);
                            }}
                        />
                    </Box>
                </Box>
            )}
        </Fragment>
    );
});
