export const formatDateRange = (startDate: Date, endDate: Date): string => {
    const startDay = startDate.getDate();
    const endDay = endDate.getDate();

    const startMonth = startDate.toLocaleDateString(undefined, {
        month: 'long',
    });
    const endMonth = endDate.toLocaleDateString(undefined, { month: 'long' });

    // Check if the months are the same
    if (startMonth === endMonth) {
        return `${startDay}-${endDay} ${startMonth}`;
    }

    // If the months are different
    return `${startDay} ${startMonth} - ${endDay} ${endMonth}`;
};
