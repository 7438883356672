import { observer } from 'mobx-react';
import { provide } from 'ioc';
import { MyGamesStore } from '../stores/my-games-store';
import MyGamesSectionImage from '../../../assets/images/my-games-section-image.png';
import { SectionWithBackground } from '../../common/components/section-with-background/section-with-background';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Tab, Tabs } from '@mui/material';
import {
    Navigate,
    Link as RouterLink,
    Route,
    Routes,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { MyGamesRoutes } from '../utils/my-games-routes';
import { CurrentGames } from './current-games/current-games';
import { UpcomingGames } from './upcoming-games/upcoming-games';
import { useDependencies } from 'ioc';
import { CreateGame } from '../../create-game/components/create-game';
import { Fragment, useState } from 'react';
import Link from '@mui/material/Link';
import { NotificationsStore } from '../../common/stores/notifications-store';
import { GameFullModel } from '../../common/api/api';
import { GameContentPage } from './content/game-content-page';
import { asParentPath } from '../../common/utils/as-parent-path';
import { TempThemeProvider } from '../../redesigned-modules/root/components/theme-provider/theme-provider';
import { WithBettingHub } from '../../root/components/with-betting-hub';

const MyGamesPageMain = provide([MyGamesStore])(
    observer(() => {
        const [{ isCreatingGame, showCreateGame, hideCreateGame }, { notify }] = useDependencies(
            MyGamesStore,
            NotificationsStore,
        );

        const [upcomingGamesKey, setUpcomingGamesKey] = useState(() => Math.random());

        const navigate = useNavigate();

        const { statusTab } = useParams();

        const createGameCallback = (game?: GameFullModel) => {
            if (statusTab === MyGamesRoutes.Upcoming) {
                setUpcomingGamesKey(Math.random());
            }

            if (game) {
                notify({
                    severity: 'success',
                    children: (
                        <Typography>
                            Successfully created! ID: #{game.id}.
                            {statusTab === MyGamesRoutes.Current && (
                                <Fragment>
                                    See&nbsp;
                                    <Link
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            // closeNotification();
                                            navigate('../upcoming');
                                        }}
                                    >
                                        Upcoming Games
                                    </Link>
                                    .
                                </Fragment>
                            )}
                        </Typography>
                    ),
                });
            }
        };

        const handleGameClick = (gameId: number) => {
            navigate(`../content/${gameId}`);
        };

        if (!Object.values(MyGamesRoutes).includes(statusTab as MyGamesRoutes)) {
            return <Navigate to={MyGamesRoutes.Current} replace />;
        }

        return (
            <SectionWithBackground
                imgSrc={MyGamesSectionImage}
                description="PLACEHOLDER TEXT"
                headline="PLACEHOLDER TEXT"
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: 'start',
                        gap: 3,
                        mb: 3,
                    }}
                >
                    <Box display="flex" flexDirection="column" gap={1}>
                        <Typography variant="h4" fontWeight={900}>
                            My Games
                        </Typography>
                        <Tabs value={statusTab}>
                            {Object.entries(MyGamesRoutes).map(([label, route]) => (
                                <Tab
                                    key={label}
                                    label={label}
                                    component={RouterLink}
                                    to={`../${route}`}
                                    value={route}
                                />
                            ))}
                        </Tabs>
                    </Box>
                    <Button
                        onClick={showCreateGame}
                        variant="contained"
                        sx={{ width: { xs: '100%', sm: '344px' } }}
                    >
                        Create Game
                    </Button>
                </Box>
                {statusTab === MyGamesRoutes.Current && (
                    <CurrentGames onGameClick={handleGameClick} />
                )}
                {statusTab === MyGamesRoutes.Upcoming && (
                    <UpcomingGames key={upcomingGamesKey} onGameClick={handleGameClick} />
                )}
                {isCreatingGame && (
                    <CreateGame onClose={hideCreateGame} successCallback={createGameCallback} />
                )}
            </SectionWithBackground>
        );
    }),
);

export const MyGamesPage = () => {
    return (
        <Routes>
            <Route path=":statusTab?" element={<MyGamesPageMain />} />
            <Route
                path={asParentPath('content/:gameId')}
                element={
                    <Routes>
                        <Route
                            path=":leagueId?/:matchId?"
                            element={
                                <WithBettingHub>
                                    <GameContentPage />
                                </WithBettingHub>
                            }
                        />
                        <Route path="*" element={<Navigate to="" replace />} />
                    </Routes>
                }
            />
            <Route path="*" element={<Navigate to="" replace />} />
        </Routes>
    );
};
