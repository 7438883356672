import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from './baseQuery';
import {
    BetHistoryTypes,
    TAddNewBet,
    TBetHistory,
    TGetStandings,
    TGetTournamentParticipationsModel,
    TLeagueTournamenActiveData,
    TOddsSectionsModel,
    TTournamentParticipatingById,
} from '../types';

export const gamesApi = createApi({
    reducerPath: 'gamesApi',
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({
        getBetHistory: builder.query<
            TBetHistory[],
            {
                tournamentId: string;
                status: BetHistoryTypes;
                playerId?: number;
            }
        >({
            query: ({ tournamentId, status, playerId }) => ({
                url: `Bets`,
                params: {
                    tournamentId,
                    state: status,
                    playerId,
                },
            }),
        }),
        addNewBet: builder.mutation<void, TAddNewBet>({
            query: ({ amount, matchOddIds, tournamentId }) => ({
                url: 'bets',
                method: 'POST',
                body: {
                    amount,
                    matchOddIds,
                    tournamentId,
                },
            }),
        }),
        getTournamentsParticipating: builder.query<
            TTournamentParticipatingById,
            {
                tournamentId?: string;
            }
        >({
            query: ({ tournamentId }) => ({
                url: `/tournaments/${tournamentId}/participating`,
            }),
        }),
        getBetsConflicts: builder.query<number[], number[]>({
            query: oddIds => ({
                url: `/Bets/conflicts`,
                method: 'Post',
                body: oddIds,
            }),
        }),
        getBalance: builder.query<
            {
                balance: number;
            },
            {
                tournamentId?: number;
            }
        >({
            query: ({ tournamentId }) => ({
                url: `/Tournaments/${tournamentId}/balance`,
            }),
        }),
        getLeagueTournamentData: builder.query<
            TLeagueTournamenActiveData,
            {
                leagueId: string;
            }
        >({
            query: ({ leagueId }) => ({
                url: `/GameLeagues/${leagueId}/tournament/active`,
            }),
        }),
        getTournamentParticipations: builder.query<
            TGetTournamentParticipationsModel,
            {
                tournamentId: string;
            }
        >({
            query: ({ tournamentId }) => ({
                url: `/tournamentParticipations/${tournamentId}`,
            }),
        }),
        getStandings: builder.query<
            TGetStandings,
            {
                leagueId: string;
            }
        >({
            query: ({ leagueId }) => ({
                url: `gameleagues/${leagueId}/standings`,
            }),
        }),
        getMatchOddSections: builder.query<
            TOddsSectionsModel[],
            {
                matchId: number;
            }
        >({
            query: ({ matchId }) => ({
                url: `Matches/${matchId}/odds/sections`,
            }),
        }),
    }),
});
// setTournamentsParticipating;
