import { FieldState } from 'formstate';
import { TextFieldProps } from '@mui/material';

// TODO: change location and make reusable
export const getInputProps = (
    fieldState: FieldState<string>
): TextFieldProps => {
    return {
        required: true,
        sx: { mb: 3 },
        fullWidth: true,
        value: fieldState.value,
        error: fieldState.hasError,
        onChange: (event) => fieldState.onChange(event.target.value),
        onBlur: fieldState.enableAutoValidationAndValidate,
    };
};
