import { FC } from 'react';
import { MatchOddModel, MatchWithOddsModel } from '../../api/api';
import { CardActionArea, Grid2 as Grid, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import { ConnectedOdd } from '../connected-odd/connected-odd';
import { TeamInfo } from './team-info';
import { useDependencies } from 'ioc';
import { LiveOddsStore } from '../../stores/live-odds-store';
import { observer } from 'mobx-react';

export interface MatchOverviewRowProps {
    teamIndex?: number;
    grouped?: boolean;
    currentOdds: MatchOddModel[];
    matchOverview: MatchWithOddsModel;
    onMatchClick: (matchOverview: MatchWithOddsModel) => void;
}

export const MatchOverviewRow: FC<MatchOverviewRowProps> = observer(
    ({ matchOverview, grouped, teamIndex, currentOdds, onMatchClick }) => {
        const [{ matchInfo }] = useDependencies(LiveOddsStore);

        const liveMatchInfo = matchInfo.get(matchOverview.match.id);
        const { teams } = matchOverview.match;

        return (
            <Grid container spacing={0.5}>
                <Grid size={5}>
                    <Card
                        sx={{
                            height: '100%',
                            cursor: 'pointer',
                            boxShadow: 'none',
                            minHeight: 64,
                            background: 'transparent',
                            borderRadius: 1,
                        }}
                        onClick={() => onMatchClick(matchOverview)}
                    >
                        <CardActionArea sx={{ height: '100%', p: 1 }}>
                            <Stack height="100%" direction="row" alignItems="center">
                                {grouped ? (
                                    <Stack spacing={1} width="100%">
                                        <TeamInfo
                                            team={teams[0]}
                                            score={liveMatchInfo?.scores[0]}
                                        />
                                        <TeamInfo
                                            team={teams[1]}
                                            score={liveMatchInfo?.scores[1]}
                                        />
                                    </Stack>
                                ) : teamIndex !== undefined ? (
                                    <TeamInfo
                                        team={teams[teamIndex]}
                                        score={liveMatchInfo?.scores[teamIndex]}
                                    />
                                ) : null}
                            </Stack>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid size={7} container spacing={0.5}>
                    {currentOdds.map((odd, index) => {
                        return (
                            <Grid key={index} size={Math.round(12 / currentOdds.length)}>
                                {/* odds may be null */}
                                {odd.americanStyleOdd !== undefined && (
                                    <Card
                                        sx={{
                                            height: '100%',
                                            boxShadow: 'none',
                                            background: 'transparent',
                                        }}
                                    >
                                        <ConnectedOdd odd={odd} />
                                    </Card>
                                )}
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        );
    },
);
