import moment from 'moment/moment';

const phoneNumberRegex = /^\d{10,20}$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const passwordRegex = /^[a-zA-Z0-9!@#$%^&*_+=-]{6,50}$/;
const usernameRegex = /^[A-Za-z0-9_]{1,20}$/;

const defaultMessages = {
    required: 'Required',
    phoneNumber: 'Invalid Phone Number',
    email: 'Invalid Email Address',
    password: 'Invalid Password',
    username: 'Invalid Username',
    dateValidator: 'Invalid Date',
};

const required = (value: string) => !value.trim() && 'Required';
const phoneNumber = (value: string) => !phoneNumberRegex.test(value) && 'Invalid Phone Number';
const email = (value: string) => !emailRegex.test(value) && 'Invalid Email Address';
const password = (value: string) => !passwordRegex.test(value) && 'Invalid Password';
const username = (value: string) => !usernameRegex.test(value) && 'Invalid Username';
const dateValidator = (value: Date | null) =>
    (!value || !moment(value).isValid()) && 'Invalid Date';

export const formValidatorsWithCustomMessages = {
    required:
        (message: string = defaultMessages.required) =>
        (value: string) =>
            !value.trim() && message,
    phoneNumber:
        (message: string = defaultMessages.phoneNumber) =>
        (value: string) =>
            !phoneNumberRegex.test(value) && message,
    email:
        (message: string = defaultMessages.email) =>
        (value: string) =>
            !emailRegex.test(value) && message,
    password:
        (message: string = defaultMessages.password) =>
        (value: string) =>
            !passwordRegex.test(value) && message,
    username:
        (message: string = defaultMessages.username) =>
        (value: string) =>
            !usernameRegex.test(value) && message,
    dateValidator:
        (message: string = defaultMessages.dateValidator) =>
        (value: Date | null) =>
            (!value || !moment(value).isValid()) && message,
};

export const formStateValidators = {
    required,
    phoneNumber,
    email,
    username,
    password,
    dateValidator,
};
