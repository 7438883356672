import { FC, Fragment } from 'react';
import { useIsMobile } from '../../common/hooks/use-is-mobile';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDependencies } from 'ioc';
import { UserNotificationsStore } from '../stores/user-notifications-store';
import { UserNotification } from './user-notification';
import { Divider } from '@mui/material';
import { observer } from 'mobx-react';
import { LoadStatus } from '../../common/enums/load-status';
import CircularProgress from '@mui/material/CircularProgress';

interface UserNotificationsContentProps {
    onClose?: () => void;
}

export const UserNotificationsContent: FC<UserNotificationsContentProps> = observer(
    ({ onClose }) => {
        const [{ userNotifications, loadStatus }] = useDependencies(UserNotificationsStore);
        const isMobile = useIsMobile();

        return (
            <Fragment>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    px={2}
                    sx={{ height: 68 }}
                >
                    <Typography fontWeight={900} fontSize={24} color="primary">
                        Notifications
                    </Typography>
                    {!!onClose && (
                        <IconButton
                            onClick={onClose}
                            sx={{ p: 0, position: 'absolute', right: 22 }}
                        >
                            <CloseIcon sx={{ width: 32, height: 32 }} />
                        </IconButton>
                    )}
                </Box>
                <Box
                    sx={{
                        // background: '#f9f9f9',
                        maxHeight: isMobile ? undefined : '80vh',
                        height: 'calc(100% - 68px)',
                        overflow: 'auto',
                        width: isMobile ? undefined : 360,
                    }}
                >
                    {loadStatus === LoadStatus.Loading || !userNotifications.length ? (
                        <Box
                            height="100%"
                            minHeight={200}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {loadStatus === LoadStatus.Loading ? (
                                <CircularProgress />
                            ) : (
                                <Typography>You have no notifications</Typography>
                            )}
                        </Box>
                    ) : (
                        userNotifications.map(notification => {
                            return (
                                <Fragment key={notification.id}>
                                    <UserNotification notification={notification} />
                                    <Divider />
                                </Fragment>
                            );
                        })
                    )}
                </Box>
            </Fragment>
        );
    },
);
