import { FC, PropsWithChildren } from 'react';
import { Stack } from '@mui/material';
import { Users } from '@phosphor-icons/react';
import Box from '@mui/material/Box';

export const Participants: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <Box
                sx={{
                    border: '1px solid white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 100,
                    height: 20,
                    width: 20,
                }}
            >
                <Users size={14} weight="bold" />
            </Box>
            {children}
        </Stack>
    );
};
