export const BalanceIcon = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
                fill="url(#paint0_linear_1007_4559)"
            />
            <path
                d="M9.99909 19.6007C15.3014 19.6007 19.5997 15.3024 19.5997 10.0001C19.5997 4.69777 15.3014 0.399414 9.99909 0.399414C4.6968 0.399414 0.398438 4.69777 0.398438 10.0001C0.398438 15.3024 4.6968 19.6007 9.99909 19.6007Z"
                fill="url(#paint1_linear_1007_4559)"
            />
            <mask
                id="mask0_1007_4559"
                // style="mask-type:luminance"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
            >
                <path
                    d="M9.99713 19.6007C15.2994 19.6007 19.5978 15.3024 19.5978 10.0001C19.5978 4.69777 15.2994 0.399414 9.99713 0.399414C4.69484 0.399414 0.396484 4.69777 0.396484 10.0001C0.396484 15.3024 4.69484 19.6007 9.99713 19.6007Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_1007_4559)">
                <path
                    d="M5.87709 3.34949C6.52655 3.13031 7.14642 2.85466 7.73672 2.5212C8.23288 2.24151 8.47895 1.66198 8.34583 1.10799L7.73133 -1.42798L2.94043 0.714015L4.41817 2.86004C4.74223 3.32932 5.3379 3.53101 5.87844 3.34814L5.87709 3.34949Z"
                    fill="url(#paint2_linear_1007_4559)"
                />
                <path
                    d="M2.38153 8.21301C2.68542 7.59851 2.9288 6.96519 3.11032 6.31305C3.26361 5.76444 3.0283 5.17953 2.54155 4.88371L0.313496 3.52563L-1.55957 8.42815L1.00329 8.90011C1.564 9.00365 2.12874 8.72397 2.38153 8.21301Z"
                    fill="url(#paint3_linear_1007_4559)"
                />
                <path
                    d="M3.34777 14.1227C3.1286 13.4732 2.85295 12.8534 2.51948 12.2631C2.2398 11.7669 1.66027 11.5208 1.10628 11.6539L-1.42969 12.2684L0.712304 17.0594L2.85833 15.5816C3.3276 15.2576 3.5293 14.6619 3.34643 14.1213L3.34777 14.1227Z"
                    fill="url(#paint4_linear_1007_4559)"
                />
                <path
                    d="M8.21179 17.6173C7.59729 17.3134 6.96397 17.07 6.31183 16.8885C5.76322 16.7352 5.17831 16.9705 4.88249 17.4573L3.52441 19.6853L8.42693 21.5584L8.8989 18.9955C9.00243 18.4348 8.72275 17.8701 8.21179 17.6173Z"
                    fill="url(#paint5_linear_1007_4559)"
                />
                <path
                    d="M14.1217 16.6505C13.4722 16.8697 12.8524 17.1453 12.2621 17.4788C11.7659 17.7585 11.5199 18.338 11.653 18.892L12.2675 21.428L17.0584 19.286L15.5806 17.1399C15.2566 16.6707 14.6609 16.469 14.1204 16.6518L14.1217 16.6505Z"
                    fill="url(#paint6_linear_1007_4559)"
                />
                <path
                    d="M17.6161 11.7886C17.3122 12.403 17.0688 13.0364 16.8873 13.6885C16.734 14.2371 16.9693 14.822 17.4561 15.1179L19.6841 16.4759L21.5572 11.5734L18.9943 11.1014C18.4336 10.9979 17.8689 11.2776 17.6161 11.7886Z"
                    fill="url(#paint7_linear_1007_4559)"
                />
                <path
                    d="M16.6495 5.87734C16.8687 6.5268 17.1443 7.14667 17.4778 7.73696C17.7575 8.23313 18.337 8.4792 18.891 8.34608L21.427 7.73159L19.285 2.94067L17.139 4.41842C16.6697 4.74248 16.468 5.33815 16.6509 5.87869L16.6495 5.87734Z"
                    fill="url(#paint8_linear_1007_4559)"
                />
                <path
                    d="M11.7876 2.38276C12.4021 2.68664 13.0354 2.93002 13.6875 3.11155C14.2361 3.26483 14.8211 3.02952 15.1169 2.54277L16.4749 0.314719L11.5724 -1.55835L11.1005 1.00451C10.9969 1.56522 11.2766 2.12997 11.7876 2.38276Z"
                    fill="url(#paint9_linear_1007_4559)"
                />
            </g>
            <path
                opacity="0.2"
                d="M10.0004 16.7513C13.7291 16.7513 16.7518 13.7286 16.7518 9.99992C16.7518 6.27123 13.7291 3.24854 10.0004 3.24854C6.27172 3.24854 3.24902 6.27123 3.24902 9.99992C3.24902 13.7286 6.27172 16.7513 10.0004 16.7513Z"
                fill="#3A3AD2"
            />
            <path
                d="M10.0971 16.7311C13.6958 16.7311 16.6132 13.8138 16.6132 10.215C16.6132 6.61632 13.6958 3.69897 10.0971 3.69897C6.49839 3.69897 3.58105 6.61632 3.58105 10.215C3.58105 13.8138 6.49839 16.7311 10.0971 16.7311Z"
                fill="url(#paint10_linear_1007_4559)"
            />
            <path
                d="M13.4416 11.3846C13.4428 10.5245 12.9062 9.98608 11.7231 9.87686L11.7228 9.76914C12.7975 9.66324 13.121 9.12603 13.1226 8.37363C13.1235 7.40579 12.4791 6.86769 11.1887 6.8665L7.4256 6.86138L7.42908 13.1086L11.2999 13.1134C12.8052 13.1152 13.4512 12.4603 13.4518 11.3848L13.4416 11.3846ZM8.92975 8.15363L11.0803 8.15603C11.6148 8.15706 11.8302 8.37011 11.8319 8.79676C11.8324 8.79553 11.833 8.79431 11.8316 8.79076L11.8314 8.80099C11.8314 8.80099 11.8313 8.79799 11.8312 8.79499C11.8286 9.22121 11.6806 9.33757 11.0781 9.33699L8.92757 9.33459L8.92907 8.15186L8.92975 8.15363ZM11.2908 11.8228L8.92538 11.8198L8.92657 10.5293L11.2922 10.5221C11.8297 10.523 12.044 10.7385 12.044 11.1688C12.044 11.5992 11.8278 11.8249 11.2903 11.824L11.2908 11.8228Z"
                fill="url(#paint11_linear_1007_4559)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1007_4559"
                    x1="6.02172"
                    y1="29.0141"
                    x2="13.2369"
                    y2="-5.46848"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="0.03" stopColor="#63F189" />
                    <stop offset="0.13" stopColor="#8CF5A8" />
                    <stop offset="0.25" stopColor="#B0F8C3" />
                    <stop offset="0.37" stopColor="#CDFAD9" />
                    <stop offset="0.49" stopColor="#E3FCEA" />
                    <stop offset="0.63" stopColor="#F3FEF6" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1007_4559"
                    x1="6.08238"
                    y1="1.28564"
                    x2="13.9145"
                    y2="18.7139"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="1" stopColor="#3A3AD2" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_1007_4559"
                    x1="12.5848"
                    y1="23.7135"
                    x2="4.95294"
                    y2="-1.62099"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="0.03" stopColor="#63F189" />
                    <stop offset="0.13" stopColor="#8CF5A8" />
                    <stop offset="0.25" stopColor="#B0F8C3" />
                    <stop offset="0.37" stopColor="#CDFAD9" />
                    <stop offset="0.49" stopColor="#E3FCEA" />
                    <stop offset="0.63" stopColor="#F3FEF6" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_1007_4559"
                    x1="6.36491"
                    y1="25.5874"
                    x2="-1.26693"
                    y2="0.252886"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="0.03" stopColor="#63F189" />
                    <stop offset="0.13" stopColor="#8CF5A8" />
                    <stop offset="0.25" stopColor="#B0F8C3" />
                    <stop offset="0.37" stopColor="#CDFAD9" />
                    <stop offset="0.49" stopColor="#E3FCEA" />
                    <stop offset="0.63" stopColor="#F3FEF6" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_1007_4559"
                    x1="4.83178"
                    y1="26.0503"
                    x2="-2.80061"
                    y2="0.714514"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="0.03" stopColor="#63F189" />
                    <stop offset="0.13" stopColor="#8CF5A8" />
                    <stop offset="0.25" stopColor="#B0F8C3" />
                    <stop offset="0.37" stopColor="#CDFAD9" />
                    <stop offset="0.49" stopColor="#E3FCEA" />
                    <stop offset="0.63" stopColor="#F3FEF6" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_1007_4559"
                    x1="8.01627"
                    y1="25.0905"
                    x2="0.384449"
                    y2="-0.243971"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="0.03" stopColor="#63F189" />
                    <stop offset="0.13" stopColor="#8CF5A8" />
                    <stop offset="0.25" stopColor="#B0F8C3" />
                    <stop offset="0.37" stopColor="#CDFAD9" />
                    <stop offset="0.49" stopColor="#E3FCEA" />
                    <stop offset="0.63" stopColor="#F3FEF6" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_1007_4559"
                    x1="15.4182"
                    y1="22.8608"
                    x2="7.78633"
                    y2="-2.47368"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="0.03" stopColor="#63F189" />
                    <stop offset="0.13" stopColor="#8CF5A8" />
                    <stop offset="0.25" stopColor="#B0F8C3" />
                    <stop offset="0.37" stopColor="#CDFAD9" />
                    <stop offset="0.49" stopColor="#E3FCEA" />
                    <stop offset="0.63" stopColor="#F3FEF6" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_1007_4559"
                    x1="21.637"
                    y1="20.9877"
                    x2="14.0046"
                    y2="-4.34801"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="0.03" stopColor="#63F189" />
                    <stop offset="0.13" stopColor="#8CF5A8" />
                    <stop offset="0.25" stopColor="#B0F8C3" />
                    <stop offset="0.37" stopColor="#CDFAD9" />
                    <stop offset="0.49" stopColor="#E3FCEA" />
                    <stop offset="0.63" stopColor="#F3FEF6" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear_1007_4559"
                    x1="23.1716"
                    y1="20.5254"
                    x2="15.5392"
                    y2="-4.81039"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="0.03" stopColor="#63F189" />
                    <stop offset="0.13" stopColor="#8CF5A8" />
                    <stop offset="0.25" stopColor="#B0F8C3" />
                    <stop offset="0.37" stopColor="#CDFAD9" />
                    <stop offset="0.49" stopColor="#E3FCEA" />
                    <stop offset="0.63" stopColor="#F3FEF6" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear_1007_4559"
                    x1="19.986"
                    y1="21.4844"
                    x2="12.3542"
                    y2="-3.8501"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="0.03" stopColor="#63F189" />
                    <stop offset="0.13" stopColor="#8CF5A8" />
                    <stop offset="0.25" stopColor="#B0F8C3" />
                    <stop offset="0.37" stopColor="#CDFAD9" />
                    <stop offset="0.49" stopColor="#E3FCEA" />
                    <stop offset="0.63" stopColor="#F3FEF6" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint10_linear_1007_4559"
                    x1="14.5831"
                    y1="20.2491"
                    x2="5.76654"
                    y2="0.53024"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="0.03" stopColor="#63F189" />
                    <stop offset="0.13" stopColor="#8CF5A8" />
                    <stop offset="0.25" stopColor="#B0F8C3" />
                    <stop offset="0.37" stopColor="#CDFAD9" />
                    <stop offset="0.49" stopColor="#E3FCEA" />
                    <stop offset="0.63" stopColor="#F3FEF6" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint11_linear_1007_4559"
                    x1="12.8316"
                    y1="0.290765"
                    x2="8.32324"
                    y2="17.2197"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="1" stopColor="#3A3AD2" />
                </linearGradient>
            </defs>
        </svg>
    );
};
