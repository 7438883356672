import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { keyframes } from '@mui/material';
import {
    customNegative,
    customPositive,
} from '../../../redesigned-modules/root/components/theme-provider/colors';

interface INumberChangeIndicatorProps {
    upIcon?: ReactElement;
    downIcon?: ReactElement;
    value: number;
}

const blink = keyframes`
  0%, 40%, 80% { opacity: 0; }
  20%, 60%, 100% { opacity: 1; }
`;

const defaultUpIcon = <CaretUp weight={'fill'} color={customPositive.main} />;
const defaultDownIcon = (
    <CaretDown weight={'fill'} color={customNegative.main} />
);

export const NumberChangeIndicator: FC<INumberChangeIndicatorProps> = ({
    value,
    upIcon = defaultUpIcon,
    downIcon = defaultDownIcon,
}) => {
    const firstRenderRef = useRef(true);
    const prevValueRef = useRef(value);
    const [shownArrowDirection, setShownArrowDirection] = useState<
        'up' | 'down' | null
    >(null);

    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
            return;
        }

        const prevValue = prevValueRef.current;

        if (value !== prevValue) {
            setTimeout(() => {
                setShownArrowDirection(value > prevValue ? 'up' : 'down');
            }, 0);

            prevValueRef.current = value;
        }

        return () => {
            setShownArrowDirection(null);
        };
    }, [value]);

    return (
        <Box>
            {shownArrowDirection !== null && (
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{
                        animation: `${blink} 1s`,
                    }}
                >
                    {shownArrowDirection === 'up' ? upIcon : downIcon}
                </Box>
            )}
        </Box>
    );
};
