import { FC, PropsWithChildren } from 'react';
import { ButtonProps, darken, IconButton } from '@mui/material';

interface CustomIconButton extends PropsWithChildren {
    color: string;
    background?: string;
    onClick: ButtonProps['onClick'];
    variant?: 'contained' | 'outlined';
}

export const CustomIconButton: FC<CustomIconButton> = ({
    children,
    color,
    onClick,
    background,
    variant,
}) => {
    return (
        <IconButton
            size="medium"
            onClick={onClick}
            sx={{
                'borderRadius': '8px',
                'border': variant === 'outlined' ? `1px solid ${color}` : undefined,
                // 'boxShadow': 1,
                color,
                'background': variant === 'contained' ? background : undefined,
                '&:hover': {
                    borderRadius: '8px',
                    background:
                        variant === 'contained' && background ? darken(background, 0.1) : undefined,
                },
            }}
        >
            {children}
        </IconButton>
    );
};
