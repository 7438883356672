import { FC } from 'react';
import { observer } from 'mobx-react';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useDependencies } from 'ioc';
import { BetSlipStore } from '../stores/bet-slip-store';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { Balance } from '../../common/components/balance/balance';
import { IBetSlipInternalProps } from './bet-slip';

export const BetSlipHeader: FC<IBetSlipInternalProps> = observer(({ swipe, isOpen, isReview }) => {
    const [{ activeOdds, balance }] = useDependencies(BetSlipStore);

    const theme = useTheme();

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
                p: 2,
                height: 80,
            }}
            onClick={swipe}
        >
            <Stack direction="row" gap={2} alignItems="center">
                <Box
                    sx={{
                        background: theme.palette.primary.main,
                        width: 36,
                        height: 36,
                        borderRadius: 100,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography color="white" fontWeight={500} fontSize={30}>
                        {activeOdds.size}
                    </Typography>
                </Box>
                <Typography fontWeight={700} fontSize={30} color="white">
                    Bet Slip
                </Typography>
            </Stack>
            <Stack spacing={2} alignItems="center" direction="row">
                {!isReview && <Balance hasOdometer amount={balance} fontWeight={600} />}
                {!!swipe && (
                    <IconButton
                        sx={{
                            width: 40,
                            height: 40,
                        }}
                    >
                        {isOpen ? <CaretDown /> : <CaretUp />}
                    </IconButton>
                )}
            </Stack>
        </Stack>
    );
});
