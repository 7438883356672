import { injectable } from 'ioc';
import { LeaguesListBaseStore } from './leagues-list-base-store';
import {
    GameLeagueInvitationCreateModel,
    GameLeaguePublicModel,
    GameStatusParam,
    IGameLeagueInvitationCreateModel,
    PlayerUsernameModel,
} from '../../common/api/api';
import { apiClient } from '../../common/api/api-client';
import { action, makeObservable, observable, runInAction } from 'mobx';

@injectable()
export class MyLeaguesStore extends LeaguesListBaseStore {
    gameStatusParam = GameStatusParam.Registered;
    leagueToShare?: GameLeaguePublicModel;

    constructor() {
        super();

        makeObservable(this, {
            leagueToShare: observable,
            startInvitation: action,
            endInvitation: action,
        });
    }

    leaveLeague = async (league: GameLeaguePublicModel) => {
        try {
            await apiClient.participationDELETE(league.id);

            runInAction(() => {
                this.leagues = this.leagues.filter(({ id }) => id !== league.id);
            });
        } catch {
            // skip
        }
    };

    startInvitation = (league: typeof this.leagueToShare) => {
        this.leagueToShare = league;
    };

    endInvitation = () => {
        this.leagueToShare = undefined;
    };

    sendInvite = async (usernames: PlayerUsernameModel[]) => {
        if (!this.leagueToShare) {
            return;
        }

        try {
            const payload: IGameLeagueInvitationCreateModel = {
                playerIds: usernames.map(({ id }) => id),
                gameLeagueId: this.leagueToShare.id,
                message: undefined,
            };

            await apiClient.gameLeagueInvitationsPOST(new GameLeagueInvitationCreateModel(payload));
        } catch {
            //
        }
    };

    getShareLink = async () => {
        const league = this.leagueToShare;

        if (!league || league.hasStarted) {
            throw new Error('Could not share!');
        }

        const link = new URL(`${location.origin}/leagues`);
        link.searchParams.set('invitationId', `${league.id}`);

        if (league.isPrivate) {
            const { tokenString } = await apiClient.tokenGET(league.id);

            link.searchParams.set('token', encodeURIComponent(tokenString ?? ''));
        }

        return link.toString();
    };
}
