import { FC } from 'react';
import { LeagueTitleModel } from '../../api/api';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';

export const SportLeagueIconList: FC<{ leagues?: LeagueTitleModel[] }> = ({ leagues = [] }) => {
    return (
        <Stack spacing={0.5} direction="row">
            {leagues.map(league => {
                return (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height={{ xs: 24, md: 40 }}
                        width={{ xs: 24, md: 40 }}
                        p={{ xs: '2px', md: '4px' }}
                        key={league.id}
                    >
                        <img
                            style={{ maxHeight: '100%', maxWidth: '100%' }}
                            alt={league.name}
                            src={league.logoUrl!}
                        />
                    </Box>
                );
            })}
        </Stack>
    );
};
