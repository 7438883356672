import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { provide, useDependencies } from 'ioc';
import { useScreenSize } from '../../../common/hooks/use-is-mobile';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Box } from '@mui/system';
import { AsyncList } from '../../../common/components/async-table-list/async-list';
import { leaguesListColumns } from '../../utils/leagues-list-columns';
import { LiveLeaguesStore } from '../../stores/live-leagues-store';
import { LeaguesHistoryMobile } from './mobile/leagues-history-mobile';
import { useNavigate } from 'react-router-dom';
import { LeaguesRoutes } from '../../utils/leagues-routes';
import { CustomTable } from '../../../common/components/custom-table/custom-table';
import { LeaguesStore } from '../../stores/leagues-store';
import { DebouncedSearch } from 'shared';
import { LeaguesHistoryStore } from '../../stores/leagues-history-store';
import { GameLeaguePublicModel } from '../../../common/api/api';
import { CLICKABLE_TABLE_ROW_SX } from '../../../common/utils/ui';

export const LeaguesHistory = provide([LeaguesHistoryStore])(
    observer(() => {
        const [{ setStatusTab }, { fetchLeagues, leaguesLoadStatus, searchLeagues, leagues }] =
            useDependencies(LeaguesStore, LeaguesHistoryStore);

        useEffect(() => {
            setStatusTab(LeaguesRoutes.History);
            fetchLeagues();
        }, []);

        const { isDesktop } = useScreenSize();
        const navigate = useNavigate();

        const handleLeagueClick = (league: GameLeaguePublicModel) => {
            if (!league.hasStarted) {
                return;
            }

            navigate(`../content/${league.id}`);
        };

        return (
            <Box display="flex" flexDirection="column" alignItems={{ sm: 'start' }} gap={3}>
                <DebouncedSearch onSearch={searchLeagues} label="Search League" />
                <AsyncList
                    length={leagues.length}
                    loadStatus={leaguesLoadStatus}
                    onFetch={fetchLeagues}
                >
                    {setLastElement =>
                        !isDesktop ? (
                            <LeaguesHistoryMobile
                                setLastElement={setLastElement}
                                onClick={handleLeagueClick}
                            />
                        ) : (
                            <CustomTable>
                                <TableBody>
                                    {leagues.map((league, leagueIndex) => (
                                        <TableRow
                                            key={league.id}
                                            ref={
                                                leagueIndex === leagues.length - 1
                                                    ? setLastElement
                                                    : undefined
                                            }
                                            sx={CLICKABLE_TABLE_ROW_SX}
                                            onClick={() => {
                                                handleLeagueClick(league);
                                            }}
                                        >
                                            {leaguesListColumns.map((column, columnIndex) => (
                                                <TableCell key={columnIndex}>
                                                    {column.getComponent(league)}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </CustomTable>
                        )
                    }
                </AsyncList>
            </Box>
        );
    }),
);
