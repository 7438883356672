import { FC } from 'react';
import { observer } from 'mobx-react';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { customNeutral } from '../../../redesigned-modules/root/components/theme-provider/colors';

interface BackButtonProps {
    label: string;
    onClick: () => void;
    isBack?: boolean;
}

export const BackButton: FC<BackButtonProps> = observer(({ label, onClick, isBack = true }) => {
    return (
        <Button
            sx={{ color: customNeutral[200] }}
            variant="text"
            color="inherit"
            startIcon={<ArrowBackIcon />}
            onClick={onClick}
        >
            {isBack ? 'Back' : 'Go'} to {label}
        </Button>
    );
});
