import { OddSubsectionModel } from '../../api/api';
import { FC } from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { SubsectionRow } from './subsection-row';

export interface SubsectionProps {
    subsection: OddSubsectionModel;
}

export const Subsection: FC<SubsectionProps> = (props) => {
    const { subsection } = props;

    return (
        <Box mb={4}>
            <Typography variant={'h5'} fontWeight={700}>
                {subsection.name}
            </Typography>
            <Grid container spacing={1} mb={0.5}>
                <Grid item xs={3} />
                <Grid item xs={9} container spacing={0.5}>
                    {subsection.columnHeaders?.map((header, index, headers) => {
                        return (
                            <Grid
                                key={index}
                                item
                                xs={12 / headers.length}
                                alignContent={'end'}
                            >
                                <Typography
                                    textAlign={'center'}
                                    variant={'subtitle1'}
                                    color={'textSecondary'}
                                >
                                    {header}
                                </Typography>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
            {subsection.rows?.map((row, index) => {
                return (
                    <Box mb={0.5} key={index}>
                        <SubsectionRow row={row} />
                    </Box>
                );
            })}
        </Box>
    );
};
