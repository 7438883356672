import { FC } from 'react';
import { observer } from 'mobx-react';
import { Chip, Stack } from '@mui/material';
import { customPositive } from '../../../redesigned-modules/root/components/theme-provider/colors';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface ILiveClockProps {
    clock?: string;
}

export const LiveClock: FC<ILiveClockProps> = observer(({ clock }) => {
    const value = clock ?? 'Live';

    const children = (
        <Stack direction="row" spacing={1} alignItems="center">
            <Typography fontSize={14}>{value}</Typography>
            <Box sx={{ width: 8, height: 8, background: customPositive.main, borderRadius: 100 }} />
        </Stack>
    );

    return (
        <Chip
            sx={{ borderRadius: 1, borderColor: customPositive[400] }}
            size="small"
            variant="outlined"
            label={children}
        />
    );
});
