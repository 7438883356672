import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import { useDependencies } from 'ioc';
import { ChangePasswordStore } from '../stores/change-password-store';
import { provide } from 'ioc';
import { getInputProps } from '../../auth/utils/get-input-props';
import TextField from '@mui/material/TextField';
import { LoadStatus } from '../../common/enums/load-status';
import Button from '@mui/material/Button';
import { BackButton } from '../../common/components/back-button/back-button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { noop } from '../../common/utils/noop';
import { NotificationsStore } from '../../common/stores/notifications-store';

export const ChangePassword = provide([ChangePasswordStore])(
    observer(() => {
        const [{ save, formState, changePasswordLoadStatus }, { notify }] = useDependencies(
            ChangePasswordStore,
            NotificationsStore,
        );

        const { currentPassword, newPassword, confirmPassword } = formState.$;

        const navigate = useNavigate();
        const handleBackNavigation = () => {
            navigate('..');
        };

        return (
            <Box
                pt={{ xs: 2, sm: 3 }}
                pb={{ xs: 2, sm: 20 }}
                px={{ xs: 2, sm: 20 }}
                height="100%"
                display="flex"
                flexDirection="column"
                gap={2}
                alignItems="start"
            >
                <BackButton label="Profile" onClick={handleBackNavigation} />
                <Box
                    width="100%"
                    flexGrow={1}
                    display="flex"
                    alignItems={{ xs: 'start', sm: 'center' }}
                    justifyContent="center"
                >
                    <Box
                        sx={{ maxWidth: 480 }}
                        component="form"
                        display="flex"
                        flexDirection="column"
                        width="100%"
                        onSubmit={event => {
                            event.preventDefault();
                            save()
                                .then(() => {
                                    notify({
                                        severity: 'success',
                                        children: 'Successfully saved!',
                                    });
                                    handleBackNavigation();
                                })
                                .catch(noop);
                        }}
                    >
                        <Typography variant="h4" mb={3} align="center" fontWeight={900}>
                            Change Password
                        </Typography>
                        <TextField
                            label="Current Password"
                            type="password"
                            {...getInputProps(currentPassword)}
                        />
                        <TextField
                            label="New Password"
                            type="password"
                            {...getInputProps(newPassword)}
                        />
                        <TextField
                            label="Confirm Password"
                            type="password"
                            {...getInputProps(confirmPassword)}
                        />
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={
                                formState.hasError ||
                                changePasswordLoadStatus === LoadStatus.Loading
                            }
                        >
                            Save Changes
                        </Button>
                    </Box>
                </Box>
            </Box>
        );
    }),
);
