import { GameFullModel, GameModel, GameStatusParam } from '../../common/api/api';
import { LoadStatus } from '../../common/enums/load-status';
import { makeAutoObservable, runInAction } from 'mobx';
import { apiClient } from '../../common/api/api-client';
import { injectable } from 'ioc';
import { ASYNC_LOAD_LIMIT } from '../../common/utils/ux';
import { ParticipantsGetter } from '../../participants/utils/types';

@injectable()
export class LobbyStore {
    games: GameModel[] = [];
    gameForParticipants?: (typeof this.games)[number];
    areAllGamesFetched = false;
    gamesLoadStatus = LoadStatus.None;
    isCreatingGame = false;
    invitedGame?: GameFullModel;

    constructor() {
        makeAutoObservable(this);
    }

    initInvitedGame = async (gameId: number, token?: string) => {
        try {
            const game = await apiClient.gamesGET2(gameId, token);

            runInAction(() => {
                this.invitedGame = game;
            });
        } catch {
            //
        }
    };

    cancelGameInvitation = () => {
        this.invitedGame = undefined;
    };

    showCreateGame = () => {
        this.isCreatingGame = true;
    };

    hideCreateGame = () => {
        this.isCreatingGame = false;
    };

    fetchGames = async () => {
        if (this.gamesLoadStatus === LoadStatus.Loading || this.areAllGamesFetched) {
            return;
        }

        try {
            runInAction(() => {
                this.gamesLoadStatus = LoadStatus.Loading;
            });

            const { items: games, totalAmount } = await apiClient.gamesGET(
                undefined,
                undefined,
                undefined,
                GameStatusParam.Unregistered,
                undefined,
                undefined,
                this.games.length,
                ASYNC_LOAD_LIMIT,
            );

            if (games) {
                runInAction(() => {
                    this.games.push(...games);
                });
            }

            if (totalAmount <= this.games.length) {
                this.areAllGamesFetched = true;
            }

            runInAction(() => {
                this.gamesLoadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.gamesLoadStatus = LoadStatus.Error;
            });
        }
    };

    showParticipants = (game: (typeof this.games)[number]) => {
        this.gameForParticipants = game;
    };

    hideParticipants = () => {
        this.gameForParticipants = undefined;
    };

    getParticipants: ParticipantsGetter = async () => {
        if (!this.gameForParticipants) {
            return [];
        }

        try {
            const { items: participants } = await apiClient.gameParticipationsGET(
                undefined,
                undefined,
                this.gameForParticipants.id,
            );

            return participants ?? [];
        } catch {
            return [];
        }
    };

    joinGame = async (gameId: (typeof this.games)[number]['id']) => {
        const game = this.games.find(({ id }) => id === gameId);

        if (!game) {
            throw new Error('game not found');
        }

        await apiClient.gameParticipationsPOST(gameId, undefined);

        runInAction(() => {
            this.games = this.games.filter(({ id }) => id !== gameId);
        });

        return game;
    };

    joinInvitedGame = async (gameId: number, token?: string) => {
        await apiClient.gameParticipationsPOST(gameId, token);
    };
}
