import { jwtDecode } from 'jwt-decode';

export const isTokenExpired = (token: string) => {
    if (!token) return true;

    try {
        const decodedToken = jwtDecode(token);

        if (!decodedToken.exp) {
            throw new Error("Token does not have an 'exp' field");
        }

        const currentTime = Math.floor(Date.now() / 1000);
        return decodedToken.exp < currentTime;
    } catch (error) {
        console.error('Failed to decode token:', error);
        return true;
    }
};
