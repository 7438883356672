import { useState, FC, MouseEvent } from 'react';
import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';

import { Eye } from '@phosphor-icons/react/dist/icons/Eye';
import { EyeClosed } from '@phosphor-icons/react/dist/icons/EyeClosed';
import { BrandTextField } from './brand-text-field';

export const PasswordField: FC<TextFieldProps> = props => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <BrandTextField
            {...props}
            type={showPassword ? 'text' : 'password'}
            slotProps={{
                input: {
                    ...props.slotProps?.input,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                color={props.error ? 'error' : 'secondary'}
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <EyeClosed size={24} /> : <Eye size={24} />}
                            </IconButton>
                        </InputAdornment>
                    ),
                },
            }}
        />
    );
};
