import Box from '@mui/material/Box';
import { FC, PropsWithChildren } from 'react';
import { Header } from '../header/header';
import { Footer } from '../footer/footer';
import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import { AppStore } from '../../../../common/stores/app-store';
import AppBackgroundImage from '../../../../../assets/redesign/images/app-background.png';

export const LayoutAuthenticated: FC<PropsWithChildren> = observer(({ children }) => {
    const [{ headerHeight }] = useDependencies(AppStore);

    return (
        <Box
            sx={{
                // Previously, this was in a separate file, RootLayout clean up the code
                'position': 'relative',
                'width': '100%',
                'height': '100vh',
                ':before': {
                    content: `""`,
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: `url(${AppBackgroundImage})`,
                    backgroundSize: 'contain',
                    filter: 'blur(70px) opacity(0.2)',
                    zIndex: -1,
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    pt: `${headerHeight}px`,
                }}
            >
                <Header />
                <Box
                    component="main"
                    sx={{
                        flex: '1 0 auto',
                        minHeight: `calc(100vh - ${headerHeight}px)`,
                        mb: 2,
                    }}
                >
                    {children}
                </Box>
                <Footer />
            </Box>
        </Box>
    );
});
