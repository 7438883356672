import { FC } from 'react';
import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import { UpcomingGamesStore } from '../../../stores/upcoming-games-store';
import Box from '@mui/material/Box';
import { GameListItemMobile } from '../../../../common/components/games/mobile/game-list-item-mobile';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ShareIcon from '@mui/icons-material/Share';
import { useTheme } from '@mui/material';
import { UpcomingGamesProps } from '../upcoming-games';
import { LastElementSetter } from '../../../../common/utils/types';
import { GameModel } from '../../../../common/api/api';

interface UpcomingGamesMobileProps extends UpcomingGamesProps {
    setLastElement: LastElementSetter;
    onLeave: (gameId: number) => void;
    onShare: (game: GameModel) => void;
}

export const UpcomingGamesMobile: FC<UpcomingGamesMobileProps> = observer(
    ({ setLastElement, onLeave, onGameClick, onShare }) => {
        const [{ games }] = useDependencies(UpcomingGamesStore);
        const theme = useTheme();

        return (
            <Box display="flex" flexDirection="column" gap={2}>
                {games.map((game, index) => (
                    <GameListItemMobile
                        key={game.id}
                        ref={index === games.length - 1 ? setLastElement : undefined}
                        onClick={() => {
                            onGameClick(game.id);
                        }}
                        game={game}
                        hideInitialBalance
                        actions={
                            <Box display="flex" gap={2}>
                                <Button
                                    onClick={event => {
                                        event.stopPropagation();
                                        onShare(game);
                                    }}
                                    startIcon={<ShareIcon />}
                                    fullWidth
                                    sx={{
                                        border: '1px solid',
                                        boxShadow: 1,
                                    }}
                                >
                                    <Typography>Share</Typography>
                                </Button>
                                <Button
                                    onClick={event => {
                                        event.stopPropagation();
                                        onLeave(game.id);
                                    }}
                                    startIcon={<LogoutIcon />}
                                    fullWidth
                                    sx={{
                                        border: `1px solid ${theme.palette.error.main}`,
                                        color: theme.palette.error.main,
                                        boxShadow: 1,
                                    }}
                                >
                                    <Typography>Leave</Typography>
                                </Button>
                            </Box>
                        }
                    />
                ))}
            </Box>
        );
    },
);
