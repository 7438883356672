import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormControl, Grid2, MenuItem, Select, Stack } from '@mui/material';
import { formatDateWithName } from '../../../../../utils';
import Card from '@mui/material/Card';
import { useDependencies } from 'ioc';
import { StandingsStore } from '../../../stores/standings-store';
import { PlayerInfo } from './player-info';
import { ColorfulDivider } from '../../../../redesigned-modules/common/components/colorful-divider/colorful-divider';
import Button from '@mui/material/Button';
import { CaretRight } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import { customNeutral } from '../../../../redesigned-modules/root/components/theme-provider/colors';
import { Balance } from 'shared';

export const Scoreboard = observer(() => {
    const [{ standings, scoreboard, weeks, roundIndex: selectedRoundIndex, changeRound }] =
        useDependencies(StandingsStore);

    const navigate = useNavigate();

    if (!standings) {
        return null;
    }

    return (
        <Box>
            <Box mb={2} display="flex" alignItems="center" justifyContent="end">
                <FormControl size="small">
                    <Select
                        value={selectedRoundIndex ?? ''}
                        onChange={event => {
                            changeRound(+event.target.value);
                        }}
                    >
                        {weeks.map(({ startDate, endDate, name }, index) => (
                            <MenuItem key={index} value={index}>
                                {name} ({formatDateWithName(startDate, true)} -{' '}
                                {formatDateWithName(endDate, true)})
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Typography fontSize={28}>Scoreboard</Typography>
            <ColorfulDivider mb={3} mt={2} height={4} />
            <Grid2 container spacing={2}>
                {scoreboard.map(game => {
                    return (
                        <Grid2 key={game.gameId} size={{ xs: 12, sm: 6, md: 4 }}>
                            <Card sx={{ p: '20px 24px', height: '100%' }}>
                                <Stack
                                    sx={{ height: '100%' }}
                                    justifyContent="space-between"
                                    spacing={1}
                                >
                                    <Stack spacing={3} flexGrow={1}>
                                        <PlayerInfo
                                            player={game.players[0]}
                                            mainInfo={
                                                <Balance
                                                    amount={game.players[0].score}
                                                    typographyProps={{ fontWeight: 700 }}
                                                />
                                            }
                                        />
                                        {!!game.players[1] && (
                                            <PlayerInfo
                                                player={game.players[1]}
                                                mainInfo={
                                                    <Balance
                                                        amount={game.players[1].score}
                                                        typographyProps={{ fontWeight: 700 }}
                                                    />
                                                }
                                            />
                                        )}
                                    </Stack>
                                    <Button
                                        size="small"
                                        sx={{ alignSelf: 'end', color: customNeutral[200] }}
                                        variant="text"
                                        color="inherit"
                                        endIcon={<CaretRight weight="bold" />}
                                        onClick={() => navigate(`${game.gameId}`)}
                                    >
                                        View Bets
                                    </Button>
                                </Stack>
                            </Card>
                        </Grid2>
                    );
                })}
            </Grid2>
        </Box>
    );
});
