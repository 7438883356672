import {
    Box,
    Chip,
    Container,
    IconButton,
    Paper,
    SvgIcon,
    Typography,
    TypographyProps,
    useTheme,
} from '@mui/material';
import React from 'react';
import { ArrowForward } from '@mui/icons-material';
import moment from 'moment';
import AtmIcon from '../icons/Atm';
import Tooltip from '@mui/material/Tooltip';
import { formatBigNumber, isBigNumber } from 'shared';
import { useOdometerNumber } from 'shared';

export const ArrowButton = () => {
    const theme = useTheme();
    return (
        <IconButton
            color="primary"
            sx={{
                width: 24,
                height: 24,
                borderRadius: '4px',
                p: 0,
                background: theme.palette.primary.main,
            }}
        >
            <ArrowForward
                sx={{
                    width: 12,
                    height: 12,
                    color: theme.palette.getContrastText(theme.palette.primary.main),
                }}
            />
        </IconButton>
    );
};

export const Balance = ({
    amount = 0,
    textProps = {},
    hasOdometer,
}: {
    amount?: number;
    textProps?: TypographyProps;
    hasOdometer?: boolean;
}) => {
    const odometerCurrentValue = useOdometerNumber(amount);
    const currentValue = hasOdometer ? odometerCurrentValue : amount;

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SvgIcon>
                <AtmIcon />
            </SvgIcon>
            <Tooltip
                arrow
                title={isBigNumber(currentValue) ? currentValue.toLocaleString() : ''}
                placement="top"
            >
                <Typography {...textProps}>{formatBigNumber(currentValue)}</Typography>
            </Tooltip>
        </Box>
    );
};

export const DurationChip = ({
    startDate,
    endDate,
    color,
}: {
    startDate: string;
    endDate: string;
    color?: string;
}) => {
    const text = `${moment(startDate).format('MM/DD')} - ${moment(endDate).format('MM/DD')}`;
    return <Chip label={text} sx={{ mx: 1, backgroundColor: color }} />;
};
