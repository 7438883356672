import { FC, PropsWithChildren, useEffect } from 'react';
import { observer } from 'mobx-react';
import { provide, useDependencies } from 'ioc';
import { BettingHubStore } from '../stores/betting-hub-store';

export const WithBettingHub: FC<PropsWithChildren> = provide([BettingHubStore])(
    observer(({ children }) => {
        const [{ init, dispose, isReady }] = useDependencies(BettingHubStore);

        useEffect(() => {
            init();

            return () => {
                dispose();
            };
        }, []);

        if (!isReady) {
            return null;
        }

        return children;
    }),
);
