import { FC, PropsWithChildren } from 'react';
import Typography from '@mui/material/Typography';

export const TypographyTableCell: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Typography color="textPrimary" fontWeight={700}>
            {children}
        </Typography>
    );
};
