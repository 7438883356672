import { injectable } from 'ioc';
import { makeAutoObservable } from 'mobx';

@injectable()
export class FriendsContentStore {
    isAddingFriends = false;

    constructor() {
        makeAutoObservable(this);
    }

    startAddingFriends = () => {
        this.isAddingFriends = true;
    };

    stopAddingFriends = () => {
        this.isAddingFriends = false;
    };
}
