import { FC } from 'react';
import { TeamModel } from '../../api/api';
import { Avatar, Stack, Typography } from '@mui/material';
import { Score } from './score';

interface ITeamInfoProps {
    score?: number;
    team: TeamModel;
}

export const TeamInfo: FC<ITeamInfoProps> = ({ team, score }) => {
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            spacing={1}
        >
            <Stack direction="row" alignItems="center" spacing={1}>
                <Avatar
                    sx={{
                        '& img': {
                            objectFit: 'unset',
                        },
                    }}
                    src={team.logoUrl}
                />
                <Typography fontWeight={700} sx={{ wordBreak: 'break-word' }}>
                    {team.name}
                </Typography>
            </Stack>
            {score !== undefined && <Score>{score}</Score>}
        </Stack>
    );
};
