import { inject, injectable } from 'ioc';
import {
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
    LogLevel,
} from '@microsoft/signalr';
import { makeAutoObservable, runInAction } from 'mobx';
import {
    BalanceUpdateMessage,
    MainOddsUpdateHubModel,
    MatchScoreUpdateHubModel,
    OddCoeffUpdateHubModel,
    OddDisableModel,
    SubsectionRemovedHubModel,
    SubsectionUpdateHubModel,
} from '../../common/api/api';
import { AuthStore } from '../../common/stores/auth-store';
import { isTokenExpired } from '../../common/utils/is-token-expired';

const sequentialMessages = ['UpdateBalanceAsync'];

const mapEventsToConstructors: Record<string, { fromJS: (data: any) => any }> = {
    UpdateBalanceAsync: BalanceUpdateMessage,
    UpdateOddCoefficientAsync: OddCoeffUpdateHubModel,
    DisableOddAsync: OddDisableModel,
    UpdateSubsectionAsync: SubsectionUpdateHubModel,
    RemoveSubsectionAsync: SubsectionRemovedHubModel,
    UpdateMatchScoreAsync: MatchScoreUpdateHubModel,
    UpdateMatchMainOddsAsync: MainOddsUpdateHubModel,
};

@injectable()
export class BettingHubStore {
    isReady = false;
    connection: HubConnection;

    @inject(AuthStore) private readonly authStore!: AuthStore;

    constructor() {
        makeAutoObservable(this);

        const connection = new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_BASE_URL_V2}/hubs/BettingHub`, {
                accessTokenFactory: async () => {
                    let token = this.authStore.getLocalAccessToken();

                    if (isTokenExpired(token)) {
                        await this.authStore.handleUnauthorized();

                        token = this.authStore.getLocalAccessToken();
                    }

                    return token;
                },
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();

        const originalConnectionOn = connection.on.bind(connection);

        connection.on = (event, handler, ...connectionRestArgs) => {
            let lastTimestamp = 0;
            const overridable = sequentialMessages.includes(event);

            const newHandler: typeof handler = (data, rawDate: Date, ...handlerRestArgs) => {
                let finalData = data;
                const date = new Date(rawDate);
                const receivedTimestamp = date.getTime();

                if (mapEventsToConstructors[event]) {
                    finalData = mapEventsToConstructors[event].fromJS(finalData);
                }

                if (overridable && receivedTimestamp < lastTimestamp) {
                    return;
                }

                handler(finalData, date, ...handlerRestArgs);
                lastTimestamp = receivedTimestamp;
            };

            return originalConnectionOn(event, newHandler, ...connectionRestArgs);
        };

        this.connection = connection;
    }

    init = async () => {
        try {
            await this.connection.start();

            while (this.connection.state !== HubConnectionState.Connected) {
                // eslint-disable-next-line no-await-in-loop
                await new Promise(resolve => setTimeout(resolve, 100));
            }

            runInAction(() => {
                this.isReady = true;
            });
        } catch {
            //
        }
    };

    dispose = async () => {
        try {
            await this.connection?.stop();
        } catch {
            //
        }
    };
}
