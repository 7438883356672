import { styled } from '@mui/system';
import { TextField } from '@mui/material';

export const BrandTextField = styled(TextField)(({ theme }) => {
    return {
        '& .MuiInputBase-input': {
            color: '#F5F8FF', // Text color
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: theme.palette.secondary.main, // Bottom border color
        },
        '& .MuiInput-underline:hover:': {
            borderBottomColor: theme.palette.secondary.main, // Bottom border color when focused
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: theme.palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.secondary.main, // Bottom border color when focused
        },
        '& .MuiInputLabel-root': {
            color: theme.palette.secondary.main, // Placeholder color
        },
        '& .MuiFormHelperText-root.Mui-error': {
            color: '#D91616', // Error text color
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: '#D91616', // Bottom border color in error state
        },
        '& .MuiInput-underline.Mui-error:before': {
            borderBottomColor: '#D91616', // Bottom border color in error state
        },
    };
});
