import { Chip, ChipProps } from '@mui/material';
import { GameStatusEnum } from '../../../common/api/api';
import { FC } from 'react';

interface iStatusChipProps {
    status: GameStatusEnum;
}

const mapStatusToProps: Record<GameStatusEnum, ChipProps['color']> = {
    [GameStatusEnum.Completed]: 'success',
    [GameStatusEnum.Cancelled]: 'error',
    [GameStatusEnum.Active]: 'primary',
};

export const StatusChip: FC<iStatusChipProps> = ({ status }) => {
    return (
        <Chip size={'small'} color={mapStatusToProps[status]} label={status} />
    );
};
