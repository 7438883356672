import { BaseSyntheticEvent, useState } from 'react';
import axios from '../../utils/axios';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { Box, Button } from '@mui/material';

import { useNavigate } from 'react-router-dom';

import { PaperBackground } from '../../components/PaperBackground';
import { ContactContent } from './ContactContent';
import { useScreenSize } from '../../modules/common/hooks/use-is-mobile';

export default function Contact() {
    const [userData, setUserData] = useState<{ [key: string]: any }>({});
    const [isSent, setIsSent] = useState(false);
    const navigate = useNavigate();
    const { isMobile } = useScreenSize();

    const handleChange = (e: BaseSyntheticEvent) => {
        const { name, value } = e?.target;
        if (!name) {
            return;
        }
        setUserData(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async (e?: BaseSyntheticEvent) => {
        axios
            .post('/contacts', userData)
            .then(() => {
                setIsSent(true);
            })
            .catch(console.log);
    };

    if (isSent) {
        return (
            <Container maxWidth="sm">
                <Paper sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <Typography>Thank you!</Typography>
                    <br />
                    <Typography>
                        We will contact as soon as possible. Every customer's opinion is important
                        for us.
                    </Typography>
                    <Box display="flex" justifyContent="flex-end" sx={{ width: '100%' }}>
                        <Button
                            variant="outlined"
                            onClick={() => navigate('/')}
                            style={{ marginTop: 12 }}
                        >
                            Return Home
                        </Button>
                    </Box>
                </Paper>
            </Container>
        );
    }

    return (
        <PaperBackground images={['soccer_red', 'soccer_white']}>
            <Container component="form" maxWidth="sm" sx={{ mb: 4 }}>
                {isMobile ? (
                    <ContactContent
                        userData={userData}
                        handleChange={handleChange}
                        onSubmit={handleSubmit}
                    />
                ) : (
                    <Paper
                        sx={{
                            my: { xs: 3, md: 6 },
                            p: 8,
                        }}
                    >
                        <ContactContent
                            userData={userData}
                            handleChange={handleChange}
                            onSubmit={handleSubmit}
                        />
                    </Paper>
                )}
            </Container>
        </PaperBackground>
    );
}
