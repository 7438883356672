import { Box, Container, Link, Stack, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { AuthFooter } from './auth-footer';

import styles from './auth-layout.module.css';
import Grid from '@mui/material/Grid2';
import { BalanceBetLogo } from '../../../../redesigned-modules/root/components/logo/balance-bet-logo';
import { ExternalDocsLinks } from '../../../utils/external-links';

export const AuthDesktopLayout: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Container className={styles['auth-layout']} disableGutters>
            <Box className={styles['auth-layout-content']}>
                <Grid
                    container
                    rowSpacing={1}
                    sx={{ flex: 1 }}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    px={8}
                    py={4}
                >
                    <Grid size={6} zIndex={10}>
                        <Box maxWidth={440} position="relative">
                            <Box pb={2}>
                                <BalanceBetLogo />
                            </Box>
                            <Typography
                                variant="h5"
                                fontFamily="ClashDisplay-Medium"
                                color="#58F081"
                                pb={2}
                                sx={{
                                    color: '#58F081',
                                    fontSize: '51px',
                                    fontWeight: '600',
                                    lineHeight: '135%',
                                }}
                            >
                                Bet smarts,
                                <br /> Not cents
                            </Typography>
                            <Typography sx={{ color: '#FFF', opacity: 0.7 }} pb={2}>
                                Get the thrill of sports betting without spending a dime. BalanceBet
                                offers the excitement you crave with no financial risk. Jump in and
                                start playing for fun now!
                            </Typography>
                        </Box>
                        <Stack spacing={2} direction="row" useFlexGap sx={{ flexWrap: 'wrap' }}>
                            <Link
                                href={ExternalDocsLinks.privacyPolicy}
                                sx={{ color: theme => theme.palette.primary[250] }}
                            >
                                Privacy Policy
                            </Link>
                            <Link
                                href={ExternalDocsLinks.termsAndConditions}
                                sx={{ color: theme => theme.palette.primary[250] }}
                            >
                                Terms & Conditions
                            </Link>
                            <Link
                                href={ExternalDocsLinks.contactUs}
                                sx={{ color: theme => theme.palette.primary[250] }}
                            >
                                Contact Us
                            </Link>
                            <Link
                                href={ExternalDocsLinks.feedback}
                                sx={{ color: theme => theme.palette.primary[250] }}
                            >
                                Feedback
                            </Link>
                        </Stack>
                    </Grid>
                    <Grid size={6}>
                        <Box
                            pt={4}
                            p={4}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '100%',
                                width: '100%',
                                zIndex: 100,
                                position: 'relative',
                            }}
                        >
                            {children}
                        </Box>
                    </Grid>
                </Grid>
                <Box className={styles['auth-layout-players']} />
                <Box className={styles['auth-layout-grid']} />
            </Box>
            <AuthFooter />
        </Container>
    );
};
