import { inject, injectable } from 'ioc';
import {
    Api,
    FocusNotificationInfoModel,
    GeneralAnnouncementMessageModelFocusNotificationMessageModel,
    RankedGameCreatedMessageModelFocusNotificationMessageModel,
    RankingSeasonChangedMessageModelFocusNotificationMessageModel,
    RankUpdatedMessageModelFocusNotificationMessageModel,
} from '../../common/api/api';
import { makeAutoObservable, runInAction } from 'mobx';
import { CommsHubStore } from '../../root/stores/comms-hub-store';
import { noop } from '../../common/utils/noop';
import { filteredUniqueById } from '../../common/utils/filtered-unique-by-field';

type Announcement =
    | GeneralAnnouncementMessageModelFocusNotificationMessageModel
    | RankedGameCreatedMessageModelFocusNotificationMessageModel
    | RankUpdatedMessageModelFocusNotificationMessageModel
    | RankingSeasonChangedMessageModelFocusNotificationMessageModel;

@injectable()
export class AnnouncementsStore {
    @inject(CommsHubStore) private readonly commsHubStore!: CommsHubStore;

    private announcements: Announcement[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    init = () => {
        [
            'ReceiveGeneralAnnouncementAsync',
            'ReceiveRankUpdateAsync',
            'RankedGameCreatedAsync',
            'RankingSeasonChangedAsync',
        ].forEach(announcementName => {
            this.commsHubStore.connection.on(announcementName, (announcement: Announcement) => {
                const updatedAnnouncements = filteredUniqueById(
                    [...this.announcements, announcement],
                    item => item.notification.id,
                );

                updatedAnnouncements.sort((a, b) => {
                    const [first, second] = [a, b].map(item =>
                        item.notification.createDate.getTime(),
                    );
                    return first - second;
                });

                runInAction(() => {
                    this.announcements = updatedAnnouncements;
                });
            });
        });

        this.commsHubStore.connection.on(
            'FocusNotificationRemovedAsync',
            (id: Parameters<InstanceType<typeof Api>['focusNotificationRemoved']>[0]) => {
                if (id !== undefined) {
                    this.removeAnnouncement(id);
                }
            },
        );
    };

    get currentAnnouncement() {
        return this.announcements.at(-1);
    }

    private sortByCreatedDate = () => {
        //
    };

    removeAnnouncement = (id: FocusNotificationInfoModel['id']) => {
        const announcementToRemove = this.announcements.find(
            ({ notification }) => notification.id === id,
        );

        if (announcementToRemove) {
            this.announcements = this.announcements.filter(
                announcement =>
                    announcement.notification.id !== announcementToRemove.notification.id,
            );
        }
    };

    viewCurrentAnnouncement = () => {
        const viewedAnnouncement = this.currentAnnouncement;

        if (viewedAnnouncement) {
            this.removeAnnouncement(viewedAnnouncement.notification.id);

            this.commsHubStore.connection
                .invoke('FocusNotificationViewedAsync', viewedAnnouncement.notification.id)
                .catch(noop);
        }
    };
}
