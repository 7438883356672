import {
    GameFullModel,
    IdNameModel,
    ILeagueTitleModel,
    MainOddsUpdateHubModel,
    MatchModel,
    MatchSearchParam,
    MatchStatus,
    MatchWithOddsModelPagingModel,
    OddGroupModel,
    OddSubsectionModel,
    SportFilterModel,
    SubsectionRemovedHubModel,
    SubsectionUpdateHubModel,
} from '../api/api';
import { LoadStatus } from '../enums/load-status';
import { action, makeObservable, observable, runInAction, when } from 'mobx';
import { apiClient } from '../api/api-client';
import { inject, injectable } from 'ioc';
import { LiveOddsStore } from './live-odds-store';
import { BettingHubStore } from '../../root/stores/betting-hub-store';

@injectable()
export class GameContentBaseStore {
    gameId?: number;
    game?: GameFullModel;

    sports: SportFilterModel[] = [];
    sportLeagueId?: ILeagueTitleModel['id'];
    matchId?: MatchModel['id'];

    matchOverviews?: MatchWithOddsModelPagingModel;
    matchOverviewsLoadStatus = LoadStatus.None;

    sportLeaguesLoadStatus = LoadStatus.None;
    matchOddsLoadStatus = LoadStatus.None;
    matchInfo?: MatchModel;
    matchSectionId?: IdNameModel['id'];
    matchSections: IdNameModel[] = [];
    oddSubsectionGroups: OddGroupModel[] = [];

    @inject(LiveOddsStore) private readonly liveOddsStore!: LiveOddsStore;
    @inject(BettingHubStore) private readonly bettingHubStore!: BettingHubStore;

    constructor() {
        makeObservable(this, {
            game: observable,
            sports: observable,
            sportLeagueId: observable,
            matchId: observable,
            matchOverviews: observable,
            matchOverviewsLoadStatus: observable,
            sportLeaguesLoadStatus: observable,
            matchOddsLoadStatus: observable,
            matchInfo: observable,
            matchSectionId: observable,
            matchSections: observable,
            oddSubsectionGroups: observable,

            replaceSubsections: action,
            replaceMatchOverviewMainOdds: action,
            registerMatchOverviewOdds: action,
            resetMatchOdds: action,
            selectSportLeague: action,
            selectMatch: action,
        });
    }

    init = async (gameId?: GameFullModel['id']) => {
        runInAction(() => {
            this.gameId = gameId;
            this.sportLeaguesLoadStatus = LoadStatus.Loading;
        });

        try {
            const [game, sports] = await Promise.all([
                gameId ? apiClient.gamesGET2(gameId, undefined) : undefined,
                apiClient.withSports(false, gameId),
            ]);

            this.liveOddsStore.listenToLiveUpdates();

            this.bettingHubStore.connection.on(
                'UpdateSubsectionAsync',
                (data: SubsectionUpdateHubModel) => {
                    if (
                        this.matchId === data.matchId &&
                        this.matchSectionId !== undefined &&
                        (data.sectionIds.includes(this.matchSectionId) || this.matchSectionId === 0)
                    ) {
                        if (data.subsections.length) {
                            this.replaceSubsections(data.subsections[0].id, data.subsections);
                        }
                    }
                },
            );

            this.bettingHubStore.connection.on(
                'RemoveSubsectionAsync',
                (data: SubsectionRemovedHubModel) => {
                    if (
                        this.matchId === data.matchId &&
                        this.matchSectionId !== undefined &&
                        (data.sectionIds.includes(this.matchSectionId) || this.matchSectionId === 0)
                    ) {
                        this.replaceSubsections(data.subsectionId, []);
                    }
                },
            );

            this.bettingHubStore.connection.on(
                'UpdateMatchMainOddsAsync',
                (data: MainOddsUpdateHubModel) => {
                    if (!this.matchId) {
                        this.replaceMatchOverviewMainOdds(data);
                    }
                },
            );

            runInAction(() => {
                this.game = game;
                this.sports = sports;
                this.sportLeaguesLoadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.sportLeaguesLoadStatus = LoadStatus.Error;
            });
        }
    };

    replaceSubsections = (id: number, subsections: OddSubsectionModel[]) => {
        let subsectionIndex = -1;

        const groupIndex = this.oddSubsectionGroups.findIndex(group => {
            subsectionIndex = group.subsections.findIndex(subsection => subsection.id === id);

            return subsectionIndex !== -1;
        });

        if (groupIndex !== -1) {
            const updatedSubsections = this.oddSubsectionGroups[groupIndex].subsections.filter(
                subsection => subsection.id !== id,
            );

            updatedSubsections.splice(subsectionIndex, 0, ...subsections);

            this.oddSubsectionGroups[groupIndex].subsections = updatedSubsections;

            subsections.forEach(subsection => {
                subsection.rows.forEach(row => {
                    row.odds.forEach(odd => {
                        this.liveOddsStore.registerLiveOdd(odd.id, odd);
                    });
                });
            });

            this.oddSubsectionGroups = [...this.oddSubsectionGroups];
        }
    };

    replaceMatchOverviewMainOdds = (mainOddsUpdateData: MainOddsUpdateHubModel) => {
        if (!this.matchOverviews) {
            return;
        }

        const matchOverviewIndex = this.matchOverviews.items.findIndex(
            matchOverview => matchOverview.match.id === mainOddsUpdateData.matchId,
        );

        if (matchOverviewIndex !== -1) {
            this.registerMatchOverviewOdds(mainOddsUpdateData.odds);

            this.matchOverviews.items[matchOverviewIndex].odds = mainOddsUpdateData.odds;
        }

        this.matchOverviews = new MatchWithOddsModelPagingModel({ ...this.matchOverviews });
    };

    selectSportLeague = (sportLeagueId: number) => {
        this.sportLeagueId = sportLeagueId;

        this.fetchMatchOverviews();
    };

    selectMatch = (matchId?: number) => {
        this.matchId = matchId;

        if (matchId !== undefined) {
            this.fetchMatch();
        } else {
            this.resetMatchOdds();
        }
    };

    resetMatchOdds = () => {
        this.matchInfo = undefined;
        this.matchSectionId = undefined;
        this.matchSections = [];
        this.oddSubsectionGroups = [];
    };

    fetchMatchOverviews = async () => {
        runInAction(() => {
            this.matchOverviewsLoadStatus = LoadStatus.Loading;
        });

        if (this.gameId !== undefined) {
            await when(() => !!this.game);
        }

        try {
            const matchOverviews = MatchWithOddsModelPagingModel.fromJS(
                await this.bettingHubStore.connection.invoke(
                    'GetMatchesWithOddsAsync',
                    new MatchSearchParam({
                        name: undefined,
                        start: 0,
                        count: 0,
                        status: undefined,
                        leagueId: this.sportLeagueId!,
                        gameId: this.game?.id,
                    }),
                ),
            );

            matchOverviews.items.forEach(matchOverview => {
                this.registerMatchOverviewOdds(matchOverview.odds);
            });

            runInAction(() => {
                this.matchOverviews = matchOverviews;
                this.matchOverviewsLoadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.matchOverviewsLoadStatus = LoadStatus.Error;
            });
        }
    };

    registerMatchOverviewOdds = (matchOverviewOdds: OddSubsectionModel) => {
        matchOverviewOdds.rows.forEach(row => {
            row.odds.forEach(odd => {
                this.liveOddsStore.registerLiveOdd(odd.id, odd);
            });
        });
    };

    fetchMatch = async () => {
        if (!this.matchId) {
            return;
        }

        try {
            const [matchInfo, matchSections] = await Promise.all([
                apiClient.matches(this.matchId),
                apiClient.sections(this.matchId),
            ]);

            runInAction(() => {
                this.matchInfo = matchInfo;
                this.matchSections = matchSections;
            });

            if (matchSections.length) {
                this.selectOddSection(matchSections[0].id);
            }
        } catch {
            // skip
        }
    };

    selectOddSection = async (matchSectionId: typeof this.matchSectionId) => {
        runInAction(() => {
            this.matchSectionId = matchSectionId;
            this.matchOddsLoadStatus = LoadStatus.Loading;
        });

        try {
            const oddSubsectionGroups = await apiClient.odds(
                this.matchInfo!.id,
                this.matchSectionId,
            );

            oddSubsectionGroups.forEach(group => {
                group.subsections.forEach(subsection => {
                    subsection.rows.forEach(row => {
                        row.odds.forEach(odd => {
                            this.liveOddsStore.registerLiveOdd(odd.id, odd);
                        });
                    });
                });
            });

            runInAction(() => {
                this.oddSubsectionGroups = oddSubsectionGroups;
                this.matchOddsLoadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.matchOddsLoadStatus = LoadStatus.Error;
            });
        }
    };
}
