export const SocialGoogle = () => {
    return (
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="50" height="50" rx="25" fill="#081217" />
            <g clipPath="url(#clip0_2047_16933)">
                <path
                    d="M21.361 13.7894C18.9631 14.6213 16.8951 16.2002 15.4608 18.2943C14.0265 20.3884 13.3015 22.8872 13.3924 25.4237C13.4832 27.9603 14.3851 30.4008 15.9655 32.3869C17.5459 34.373 19.7216 35.8 22.1729 36.4582C24.1603 36.971 26.2424 36.9935 28.2404 36.5238C30.0504 36.1173 31.7238 35.2476 33.0967 34.0001C34.5256 32.662 35.5627 30.9597 36.0967 29.0763C36.677 27.0282 36.7803 24.8743 36.3985 22.7801H25.2385V27.4094H31.7017C31.5725 28.1478 31.2957 28.8525 30.8878 29.4814C30.48 30.1102 29.9494 30.6504 29.3279 31.0694C28.5387 31.5915 27.649 31.9428 26.716 32.1007C25.7803 32.2747 24.8205 32.2747 23.8848 32.1007C22.9364 31.9046 22.0392 31.5132 21.2504 30.9513C19.9832 30.0543 19.0317 28.7799 18.5317 27.3101C18.0232 25.8127 18.0232 24.1893 18.5317 22.6919C18.8876 21.6423 19.476 20.6867 20.2529 19.8963C21.142 18.9752 22.2677 18.3168 23.5063 17.9933C24.745 17.6698 26.0488 17.6938 27.2748 18.0626C28.2325 18.3565 29.1083 18.8702 29.8323 19.5626C30.561 18.8376 31.2885 18.1107 32.0148 17.3819C32.3898 16.9901 32.7985 16.6169 33.1679 16.2157C32.0627 15.1872 30.7654 14.3869 29.3504 13.8607C26.7736 12.925 23.9541 12.8999 21.361 13.7894Z"
                    fill="#081217"
                />
                <path
                    d="M21.3607 13.7894C23.9536 12.8992 26.7731 12.9237 29.3501 13.8587C30.7654 14.3885 32.062 15.1927 33.1657 16.225C32.7907 16.6262 32.3951 17.0012 32.0126 17.3912C31.2851 18.1175 30.5582 18.8412 29.832 19.5625C29.1079 18.8701 28.2321 18.3565 27.2745 18.0625C26.0489 17.6924 24.7451 17.6671 23.5061 17.9893C22.2671 18.3114 21.1408 18.9686 20.2507 19.8887C19.4738 20.6791 18.8854 21.6348 18.5295 22.6844L14.6426 19.675C16.0338 16.916 18.4427 14.8057 21.3607 13.7894Z"
                    fill="#E33629"
                />
                <path
                    d="M13.6114 22.6563C13.8203 21.6209 14.1672 20.6182 14.6427 19.675L18.5295 22.6919C18.0211 24.1893 18.0211 25.8127 18.5295 27.31C17.2345 28.31 15.9389 29.315 14.6427 30.325C13.4523 27.9556 13.0893 25.256 13.6114 22.6563Z"
                    fill="#F8BD00"
                />
                <path
                    d="M25.2381 22.7781H36.3981C36.7799 24.8723 36.6766 27.0262 36.0963 29.0743C35.5623 30.9577 34.5252 32.66 33.0963 33.9981C31.8419 33.0193 30.5819 32.0481 29.3275 31.0693C29.9494 30.6498 30.4802 30.1091 30.8881 29.4796C31.296 28.85 31.5726 28.1446 31.7013 27.4056H25.2381C25.2363 25.8643 25.2381 24.3212 25.2381 22.7781Z"
                    fill="#587DBD"
                />
                <path
                    d="M14.6406 30.3251C15.9369 29.3251 17.2325 28.3201 18.5275 27.3101C19.0285 28.7804 19.9814 30.0549 21.25 30.9513C22.0413 31.5106 22.9404 31.8988 23.89 32.0913C24.8257 32.2653 25.7855 32.2653 26.7213 32.0913C27.6542 31.9334 28.5439 31.5821 29.3331 31.0601C30.5875 32.0388 31.8475 33.0101 33.1019 33.9888C31.7292 35.237 30.0558 36.1073 28.2456 36.5144C26.2476 36.9841 24.1655 36.9616 22.1781 36.4488C20.6063 36.0291 19.1381 35.2893 17.8656 34.2757C16.5187 33.2063 15.4187 31.8588 14.6406 30.3251Z"
                    fill="#319F43"
                />
            </g>
            <defs>
                <clipPath id="clip0_2047_16933">
                    <rect width="24" height="24" fill="white" transform="translate(13 13)" />
                </clipPath>
            </defs>
        </svg>
    );
};
