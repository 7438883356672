import { FC } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { MatchWithOddsModel } from '../../common/api/api';
import { useDependencies } from 'ioc';
import { SlateStore } from '../stores/slate-store';
import { MatchContent } from '../../common/components/match-content/match-content';

interface ISlateMatchContentProps {
    activeMatchId?: number;
}

export const SlateMatchContent: FC<ISlateMatchContentProps> = observer(({ activeMatchId }) => {
    const [
        {
            matchOverviewsLoadStatus,
            matchOverviews,
            matchInfo,
            matchSectionId,
            matchSections,
            matchOddsLoadStatus,
            selectOddSection,
            oddSubsectionGroups,
            sportLeagueId,
        },
    ] = useDependencies(SlateStore);

    const navigate = useNavigate();

    const handleMatchClick = (matchOverview: MatchWithOddsModel) => {
        navigate(`${matchOverview.match.id}`);
    };

    const handleBackToMatches = () => navigate(`../${sportLeagueId}`);

    return (
        <MatchContent
            match={matchInfo}
            matchSections={matchSections}
            activeMatchId={activeMatchId}
            matchOverviews={matchOverviews}
            onMatchClick={handleMatchClick}
            matchSectionId={matchSectionId}
            onSelectSection={selectOddSection}
            onBackToMatches={handleBackToMatches}
            matchOddsLoadStatus={matchOddsLoadStatus}
            oddSubsectionGroups={oddSubsectionGroups}
            matchOverviewsLoadStatus={matchOverviewsLoadStatus}
        />
    );
});
