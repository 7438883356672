import { useDependencies } from 'ioc';
import { observer } from 'mobx-react';
import { Box, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ArrowCircleRight, ArrowLeft } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';

import { BrandButton } from '../brand-components/brand-button';
import { useScreenSize } from '../../../common/hooks/use-is-mobile';
import { UnauthenticatedRoutes } from '../../../redesigned-modules/root/components/app/app-unauthenticated';
import { BrandTypography } from '../brand-components/brand-typography';
import { ForgotPasswordStore } from '../../stores/forgot-password.store';
import { BrandTextField } from '../brand-components/brand-text-field';

export const ForgotPasswordForm = observer(() => {
    const [{ formState, isLoading, sendRecoverEmail }] = useDependencies(ForgotPasswordStore);
    const { isMobile } = useScreenSize();
    const navigate = useNavigate();

    const { email } = formState.$;

    const handleBack = () => {
        navigate(UnauthenticatedRoutes.login);
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        await sendRecoverEmail();
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                flex: 1,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <BrandTypography variant="h4" textAlign="center" mb={2}>
                    Reset password
                </BrandTypography>
                <Typography textAlign="center" sx={{ color: theme => theme.palette.primary[250] }}>
                    Enter you email address and we’ll send you a link to reset your password
                </Typography>
            </Box>
            <Box width="100%" gap={2}>
                <IconButton
                    size={isMobile ? 'small' : 'medium'}
                    onClick={handleBack}
                    color="secondary"
                    sx={{ border: 2, mb: 4, display: { xs: 'none', md: 'inline-flex' } }}
                >
                    <ArrowLeft weight="bold" />
                </IconButton>
                <BrandTextField
                    fullWidth
                    variant="standard"
                    placeholder="Email address"
                    sx={{ mb: { xs: 3 } }}
                    value={email.value}
                    error={!!email.error}
                    helperText={email.error}
                    onChange={event => {
                        email.onChange(event.target.value);
                    }}
                    onBlur={email.enableAutoValidationAndValidate}
                />
            </Box>

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <BrandButton
                    type="submit"
                    loading={isLoading}
                    disabled={formState.hasError || isLoading}
                    endIcon={
                        <ArrowCircleRight
                            size={32}
                            weight="fill"
                            color="#000"
                            display={isLoading ? 'none' : 'block'}
                        />
                    }
                >
                    Send link
                </BrandButton>
            </Box>
        </Box>
    );
});
