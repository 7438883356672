import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import { useDependencies } from 'ioc';
import { AppStore } from '../../../../common/stores/app-store';
import { useScreenSize } from '../../../../common/hooks/use-is-mobile';
import { Stack } from '@mui/material';

interface ICommonBettingSectionProps {
    sportLeagues?: ReactNode;
    content: ReactNode;
    actions?: ReactNode;
    header?: ReactNode;
    betSlip?: ReactNode;
}

export const CommonBettingSection: FC<ICommonBettingSectionProps> = observer(
    ({ sportLeagues, content, actions, header, betSlip }) => {
        const [{ headerHeight, footerHeight }] = useDependencies(AppStore);
        const betSlipContainerRef = useRef<HTMLDivElement>();
        const [betSlipContainerHeight, setBetSlipContainerHeight] = useState(0);

        const { isDesktop } = useScreenSize();

        useEffect(() => {
            const handler = () => {
                if (!betSlipContainerRef.current) {
                    return;
                }

                const footerEl = document.querySelector('footer');

                const footerTop = footerEl?.getBoundingClientRect().top ?? 0;

                const visibleFooterHeight = Math.round(Math.max(window.innerHeight - footerTop, 0));

                const betSlipContainerRect = betSlipContainerRef.current.getBoundingClientRect();

                const result =
                    window.innerHeight -
                    visibleFooterHeight -
                    24 -
                    Math.max(betSlipContainerRect.top, headerHeight + 24);

                setBetSlipContainerHeight(result);
            };

            handler();

            window.addEventListener('scroll', handler);

            return () => {
                window.removeEventListener('scroll', handler);
            };
        }, [headerHeight]);

        return (
            <Box
                sx={{
                    display: 'flex',
                    overflow: 'visible',
                    gap: 3,
                }}
            >
                <Box
                    sx={{
                        flex: '1 1 auto',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    {header}
                    {!isDesktop && actions}
                    {sportLeagues}
                    <Box
                        sx={{
                            minHeight: isDesktop
                                ? window.innerHeight - footerHeight - 24
                                : undefined,
                        }}
                    >
                        {content}
                    </Box>
                </Box>
                {isDesktop ? (
                    <Box sx={{ flex: '0 0 375px' }}>
                        <Stack spacing={1} sx={{ height: '100%' }}>
                            {!!actions && <Box>{actions}</Box>}
                            <Box
                                ref={betSlipContainerRef}
                                sx={{
                                    position: 'sticky',
                                    top: headerHeight + 24,
                                    borderRadius: 1,
                                    overflow: 'hidden',
                                }}
                                style={{
                                    height: betSlipContainerHeight,
                                }}
                            >
                                {betSlip}
                            </Box>
                        </Stack>
                    </Box>
                ) : (
                    betSlip
                )}
            </Box>
        );
    },
);
