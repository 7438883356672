import { apiClient } from '../../common/api/api-client';
import {
    MatchModel,
    SportLeagueCombinedModel,
    SportLeagueTargetParam,
    GameCreateModel,
} from '../../common/api/api';
import { LoadStatus } from '../../common/enums/load-status';
import { FieldState, FormState } from 'formstate';
import { makeAutoObservable, runInAction } from 'mobx';
import moment from 'moment';
import { injectable } from 'ioc';
import { getDateWithAppliedHour, getTomorrow } from '../../common/utils/dates';
import { formStateValidators } from '../../common/utils/form-state-validators';

const MINIMUM_MAX_PLAYER_COUNT = 2;
const MAXIMUM_MAX_PLAYER_COUNT = 50;
const ALL_GAMES_OPTION_VALUE = -1;
export const INVALID_DATE_RANGE_ERROR = 'Invalid Period';

const getNextWeekDayFromTomorrow = () => moment(getTomorrow()).add(1, 'week').toDate();

const getDatesWithAppliedHours = (
    startDate: Date,
    startTime: Date,
    endDate: Date,
    endTime: Date,
) => {
    const finalStartDate = new Date(startDate);
    const finalEndDate = new Date(endDate);

    finalStartDate.setHours(startTime.getHours());
    finalEndDate.setHours(endTime.getHours());

    [finalStartDate, finalEndDate].forEach(date => {
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
    });

    return {
        startDate: finalStartDate,
        endDate: finalEndDate,
    };
};

@injectable()
export class CreateGameStore {
    leagues: SportLeagueCombinedModel[] = [];
    initialBalanceOptions: number[] = [];
    createGameLoadStatus = LoadStatus.None;
    formState = new FormState({
        leagueIds: new FieldState<number[]>([]).validators(
            value => value.length === 0 && 'Select a League',
        ),
        matchId: new FieldState<number>(ALL_GAMES_OPTION_VALUE),
        initialBalance: new FieldState<number>(0),
        maxPlayers: new FieldState<number>(0).validators(
            value =>
                (value < MINIMUM_MAX_PLAYER_COUNT || value > MAXIMUM_MAX_PLAYER_COUNT) &&
                `The maximum players count should be between ${MINIMUM_MAX_PLAYER_COUNT} and ${MAXIMUM_MAX_PLAYER_COUNT}`,
        ),
        startDate: new FieldState<Date | null>(getTomorrow()).validators(value => {
            if (this.formState.$.matchId.value) {
                return false;
            }

            return formStateValidators.dateValidator(value);
        }),
        startTime: new FieldState<Date | null>(getTomorrow()).validators(value => {
            if (this.formState.$.matchId.value) {
                return false;
            }

            return formStateValidators.dateValidator(value);
        }),
        endDate: new FieldState<Date | null>(getNextWeekDayFromTomorrow()).validators(value => {
            if (this.formState.$.matchId.value) {
                return false;
            }

            return formStateValidators.dateValidator(value);
        }),
        endTime: new FieldState<Date | null>(getNextWeekDayFromTomorrow()).validators(value => {
            if (this.formState.$.matchId.value) {
                return false;
            }

            return formStateValidators.dateValidator(value);
        }),
        isPrivate: new FieldState(false),
    })
        .compose()
        .validators($ => {
            if ($.matchId.value) {
                return false;
            }

            const currentStartDate = $.startDate.$!;
            const currentStartTime = $.startTime.$!;
            const currentEndDate = $.endDate.$!;
            const currentEndTime = $.endTime.$!;

            const startDate = getDateWithAppliedHour(currentStartDate, currentStartTime);

            const endDate = getDateWithAppliedHour(currentEndDate, currentEndTime);

            if (startDate.getTime() >= endDate.getTime()) {
                return INVALID_DATE_RANGE_ERROR;
            }

            return false;
        });
    availableMatches: MatchModel[] = [];

    constructor() {
        this.formState.disableAutoValidation();

        makeAutoObservable(this);
    }

    initCreateGame = async () => {
        try {
            const [leagues, initialBalanceOptions] = await Promise.all([
                apiClient.sportLeagues(undefined, SportLeagueTargetParam.GameCreate),
                apiClient.initialBalanceOptions(),
            ]);

            runInAction(() => {
                this.leagues = leagues;
                this.initialBalanceOptions = initialBalanceOptions;
            });

            this.formState.$.initialBalance.onChange(initialBalanceOptions[0]);
        } catch {
            // skip
        }
    };

    submitCreateGame = async () => {
        await this.formState.enableAutoValidationAndValidate();

        if (this.formState.hasError) {
            throw new Error('Validation error');
        }

        runInAction(() => {
            this.createGameLoadStatus = LoadStatus.Loading;
        });

        const formStateValues = this.formState.$;

        const isMatchSelected = formStateValues.matchId.$ !== ALL_GAMES_OPTION_VALUE;

        let startDate = new Date(formStateValues.startDate.$!);
        let endDate = new Date(formStateValues.endDate.$!);

        if (!isMatchSelected) {
            const startTime = formStateValues.startTime.$!;
            const endTime = formStateValues.endTime.$!;

            startDate = getDateWithAppliedHour(startDate, startTime);
            endDate = getDateWithAppliedHour(endDate, endTime);
        }

        const payload = new GameCreateModel({
            matchId: isMatchSelected ? formStateValues.matchId.$ : undefined,
            leagueIds: formStateValues.leagueIds.$,
            startDate,
            endDate,
            maxPlayerCount: formStateValues.maxPlayers.$,
            initialBalance: formStateValues.initialBalance.$,
            isPrivate: formStateValues.isPrivate.$,
            participantIds: [],
            minBetAmount: 0,
            leagues: undefined,
        });

        try {
            const game = await apiClient.createGame(payload);

            runInAction(() => {
                this.createGameLoadStatus = LoadStatus.Ok;
            });

            return game;
        } catch {
            runInAction(() => {
                this.createGameLoadStatus = LoadStatus.Error;
            });

            throw new Error('something went wrong');
        }
    };

    selectLeague = (selectedLeagueId: number) => {
        const { leagueIds } = this.formState.$;
        const isAdding = !leagueIds.value.includes(selectedLeagueId);
        const result = isAdding
            ? [...leagueIds.value, selectedLeagueId]
            : leagueIds.value.filter(leagueId => leagueId !== selectedLeagueId);
        leagueIds.onChange(result);

        if (!result.length) {
            leagueIds.enableAutoValidationAndValidate();
        }

        if (leagueIds.value.length === 1) {
            this.fetchAvailableMatches();
        } else {
            this.availableMatches = [];
            this.selectMatch(ALL_GAMES_OPTION_VALUE);
        }
    };

    selectMatch = (matchId: MatchModel['id']) => {
        if (matchId === ALL_GAMES_OPTION_VALUE) {
            if (this.formState.$.matchId.value === ALL_GAMES_OPTION_VALUE) {
                return;
            }

            this.formState.$.matchId.onChange(ALL_GAMES_OPTION_VALUE);
            this.formState.$.startDate.reset();
            this.formState.$.startTime.reset();
            this.formState.$.endDate.reset();
            this.formState.$.endTime.reset();
        }
        const match = this.availableMatches.find(({ id }) => id === matchId);

        if (!match) {
            return;
        }

        this.formState.$.matchId.onChange(matchId);
        this.formState.$.startDate.onChange(null);
        this.formState.$.startTime.onChange(null);
        this.formState.$.endDate.onChange(null);
        this.formState.$.endTime.onChange(null);
    };

    fetchAvailableMatches = async () => {
        const { leagueIds } = this.formState.$;

        if (leagueIds.value.length !== 1) {
            return;
        }

        const leagueId = leagueIds.value[0];

        try {
            const { items: availableMatches } = await apiClient.matches2(
                leagueId,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
            );

            runInAction(() => {
                this.availableMatches = availableMatches ?? [];
            });
        } catch {
            // skip
        }
    };
}
