import { injectable } from 'ioc';
import { FriendshipStatus, PlayerFriendModel } from '../../common/api/api';
import { LoadStatus } from '../../common/enums/load-status';
import { makeObservable, observable, runInAction } from 'mobx';
import { apiClient } from '../../common/api/api-client';
import { noop } from '../../common/utils/noop';

@injectable()
export abstract class PlayerFriendsBaseStore {
    abstract friendShipStatus: FriendshipStatus;

    players: PlayerFriendModel[] = [];
    areAllPlayersFetched = false;
    loadStatus = LoadStatus.None;
    searchTerm = '';
    reducedPlayersCount = 0;

    constructor() {
        makeObservable(this, {
            players: observable,
            areAllPlayersFetched: observable,
            loadStatus: observable,
            searchTerm: observable,
        });
    }

    incrementReducedPlayersCount = () => {
        this.reducedPlayersCount += 1;
    };

    fetchPlayers = async () => {
        if (this.areAllPlayersFetched || this.loadStatus === LoadStatus.Loading) {
            return;
        }

        runInAction(() => {
            this.loadStatus = LoadStatus.Loading;
        });

        try {
            const { totalAmount, items = [] } = await apiClient.friendsGET(
                this.friendShipStatus,
                this.searchTerm,
                this.players.length - this.reducedPlayersCount,
                20,
            );

            runInAction(() => {
                this.players.push(...items);

                if (totalAmount <= this.players.length) {
                    this.areAllPlayersFetched = true;
                }

                this.loadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.loadStatus = LoadStatus.Error;
            });
        }
    };

    search = (searchTerm: string) => {
        this.searchTerm = searchTerm;
        this.players = [];
        this.areAllPlayersFetched = false;
        this.reducedPlayersCount = 0;

        this.fetchPlayers().catch(noop);
    };
}
