export const SuccessCheckMark = () => {
    return (
        <svg
            width="61"
            height="60"
            viewBox="0 0 61 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M60.5 30C60.5 46.5685 47.0685 60 30.5 60C13.9315 60 0.5 46.5685 0.5 30C0.5 13.4315 13.9315 0 30.5 0C47.0685 0 60.5 13.4315 60.5 30ZM5.498 30C5.498 43.8082 16.6918 55.002 30.5 55.002C44.3082 55.002 55.502 43.8082 55.502 30C55.502 16.1918 44.3082 4.998 30.5 4.998C16.6918 4.998 5.498 16.1918 5.498 30Z"
                fill="url(#paint0_linear_1995_118778)"
            />
            <g filter="url(#filter0_dd_1995_118778)">
                <path
                    d="M42.2187 22.2495L26.8594 37.6088C26.7702 37.6981 26.6643 37.7689 26.5478 37.8172C26.4313 37.8655 26.3063 37.8904 26.1802 37.8904C26.054 37.8904 25.9291 37.8655 25.8126 37.8172C25.696 37.7689 25.5902 37.6981 25.501 37.6088L18.7813 30.8891C18.6012 30.709 18.5 30.4647 18.5 30.21C18.5 29.9552 18.6012 29.7109 18.7813 29.5308C18.9614 29.3507 19.2058 29.2495 19.4605 29.2495C19.7152 29.2495 19.9595 29.3507 20.1397 29.5308L26.1802 35.5725L40.8603 20.8912C41.0405 20.7111 41.2848 20.6099 41.5395 20.6099C41.7942 20.6099 42.0386 20.7111 42.2187 20.8912C42.3988 21.0713 42.5 21.3156 42.5 21.5704C42.5 21.8251 42.3988 22.0694 42.2187 22.2495Z"
                    fill="#07202D"
                />
                <path
                    d="M26.1802 34.8654L40.5068 20.5376L40.5068 20.5376C40.7807 20.2637 41.1522 20.1099 41.5395 20.1099C41.9269 20.1099 42.2983 20.2637 42.5722 20.5376L42.2204 20.8894L42.5722 20.5376C42.8461 20.8115 43 21.183 43 21.5704C43 21.9577 42.8461 22.3292 42.5722 22.6031L27.2131 37.9622L27.2129 37.9624L26.1802 34.8654ZM26.1802 34.8654L20.4932 29.1773L20.4932 29.1772C20.2193 28.9033 19.8478 28.7495 19.4605 28.7495C19.0731 28.7495 18.7017 28.9033 18.4278 29.1772C18.1539 29.4511 18 29.8226 18 30.21C18 30.5973 18.1539 30.9688 18.4278 31.2427L18.7813 30.8891L18.4278 31.2427L25.1473 37.9622L26.1802 34.8654Z"
                    stroke="#58F081"
                />
            </g>
            <defs>
                <filter
                    id="filter0_dd_1995_118778"
                    x="14.5"
                    y="17.6099"
                    width="32"
                    height="25.2808"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.298039 0 0 0 0 1 0 0 0 0 0.533333 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1995_118778"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.299593 0 0 0 0 1 0 0 0 0 0.533062 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_1995_118778"
                        result="effect2_dropShadow_1995_118778"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_1995_118778"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_1995_118778"
                    x1="30.5"
                    y1="0"
                    x2="30.5"
                    y2="60"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5BE734" />
                    <stop offset="1" stopColor="#2B5FD9" />
                </linearGradient>
            </defs>
        </svg>
    );
};
