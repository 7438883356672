import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FC } from 'react';
import { ILobbyGamesProps } from '../lobby-games';
import { observer } from 'mobx-react';
import { LobbyStore } from '../../../stores/lobby-store';
import { useDependencies } from 'ioc';
import { useTheme } from '@mui/material';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import { CustomIconButton } from '../../../../common/components/custom-icon-button/custom-icon-button';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import { GameListItemMobile } from '../../../../common/components/games/mobile/game-list-item-mobile';
import { GameModel } from '../../../../common/api/api';

interface ILobbyGamesMobileProps extends ILobbyGamesProps {
    onJoinGame: (game: GameModel['id']) => void;
}

export const LobbyGamesMobile: FC<ILobbyGamesMobileProps> = observer(
    ({ setLastElement, onJoinGame }) => {
        const [{ showParticipants, games }] = useDependencies(LobbyStore);

        const theme = useTheme();

        return (
            <Box display="flex" flexDirection="column" gap={2}>
                {games.map((game, index) => {
                    return (
                        <GameListItemMobile
                            game={game}
                            key={game.id}
                            ref={index === games.length - 1 ? setLastElement : undefined}
                            actions={
                                <Box display="flex" gap={2}>
                                    <CustomIconButton
                                        onClick={() => showParticipants(game)}
                                        color={theme.palette.grey[800]}
                                    >
                                        <Groups2OutlinedIcon />
                                    </CustomIconButton>
                                    <Button
                                        onClick={() => {
                                            onJoinGame(game.id);
                                        }}
                                        endIcon={<DirectionsWalkIcon />}
                                        fullWidth
                                        sx={{
                                            border: '1px solid',
                                            boxShadow: 1,
                                        }}
                                    >
                                        <Typography>Join</Typography>
                                    </Button>
                                </Box>
                            }
                        />
                    );
                })}
            </Box>
        );
    },
);
