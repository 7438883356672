import { Box } from '@mui/system';
import Trophy from '../layouts/assets/trophy-blur.png';
import Basketball from '../layouts/assets/basketball.png';
import Football from '../layouts/assets/football.png';
import FootballBlur from '../layouts/assets/football-blur.png';

export const FramerIllustration = () => {
    return (
        <Box
            sx={{
                display: { xs: 'none', sm: 'block', md: 'block' },
                position: 'absolute',
                width: '100%',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    width: 150,
                    height: { sm: 200 },
                    left: { sm: 90 },
                    top: 0,
                    transform: 'translate(-85px, -20px) scale(1.2)',
                    opacity: 1,
                }}
            >
                <img
                    src={Trophy}
                    alt="Trophy"
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                />
            </Box>

            <Box
                sx={{
                    position: 'absolute',
                    width: { xs: 80, sm: 120 },
                    height: { xs: 100, sm: 135 },
                    right: { xs: 10, sm: 100 },
                    bottom: { xs: 100, sm: 194 },
                    transform: 'translate(22.5px, 2px) scale(1.8)',
                    opacity: 0.7,
                    filter: 'blur(2.5px)',
                }}
            >
                <img
                    src={Football}
                    alt="Football"
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                />
            </Box>

            <Box
                sx={{
                    position: 'absolute',
                    width: { xs: 100, sm: 140 },
                    height: { xs: 120, sm: 171 },
                    left: { xs: 30, sm: 127 },
                    bottom: { xs: 50, sm: 150 },
                    transform: 'translate(-66px, 90px) scale(1.04)',
                    opacity: 1,
                }}
            >
                <img
                    src={FootballBlur}
                    alt="Image 6"
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                />
            </Box>

            <Box
                sx={{
                    position: 'absolute',
                    width: { sm: 100 },
                    height: { sm: 100 },
                    right: { sm: 50 },
                    top: { sm: 34 },
                    transform: 'translate(-22px) scale(1.04)',
                    opacity: 0.3,
                    filter: 'blur(4px)',
                }}
            >
                <img
                    src={Basketball}
                    alt="Basketball"
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                />
            </Box>
        </Box>
    );
};
