import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { alpha, IconButton, useTheme } from '@mui/material';
import { BookOpen } from '@phosphor-icons/react';
import { useScreenSize } from '../../hooks/use-is-mobile';

interface ISectionImageContainerProps {
    src: string;
    headline: string;
    description?: string;
    onInfoClick?: () => void;
}

export const SectionImage: FC<ISectionImageContainerProps> = ({ src, headline, onInfoClick }) => {
    const theme = useTheme();
    const { isMobile } = useScreenSize();
    return (
        <Box
            sx={{
                borderRadius: { xs: 0, sm: '32px' },
                height: { xs: '80px', sm: '160px' },
                background: `linear-gradient(white, white) padding-box, linear-gradient(to bottom, ${theme.palette.primary.main}, ${alpha(theme.palette.secondary.main, 0.2)}) border-box`,
                border: '1px solid transparent',
                overflow: 'hidden',
                position: 'relative',
            }}
        >
            <IconButton
                size={isMobile ? 'small' : 'medium'}
                onClick={onInfoClick}
                color="secondary"
                sx={{ position: 'absolute', top: 16, right: 16, border: 2 }}
            >
                <BookOpen weight="bold" />
            </IconButton>
            <Box
                sx={{
                    background: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .7)), url(${src})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '50% 65%',
                    height: '100%',
                    padding: 4,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="h2" fontWeight={700}>
                        {headline}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};
