import { FC, ReactElement, ReactNode } from 'react';
import { observer } from 'mobx-react';
import Typography from '@mui/material/Typography';
import { Stack, useTheme, StackProps } from '@mui/material';
import Box from '@mui/material/Box';
import { useDependencies } from 'ioc';
import { PlayoffStore } from '../../../stores/playoff-store';
import { formatDateRange } from '../../../../common/utils/format-date-range';
import { ColorfulDivider } from '../../../../redesigned-modules/common/components/colorful-divider/colorful-divider';

interface PlayoffSectionProps {
    title: string;
    children: (roundNumber: number) => ReactNode;
    justifyGamesContent?: StackProps['justifyContent'];
    absoluteImage?: ReactElement;
}

const minColumnWidth = 240;
const maxColumnWidth = 340;

export const PlayoffSection: FC<PlayoffSectionProps> = observer(
    ({ title, children, justifyGamesContent, absoluteImage }) => {
        const [{ gameLeaguePlayoffData }] = useDependencies(PlayoffStore);

        return (
            <Box
                sx={{
                    width: '100%',
                    minWidth: 'max-content',
                    position: 'relative',
                }}
            >
                {absoluteImage}
                <Typography
                    variant="h4"
                    sx={{
                        position: 'sticky',
                        left: 0,
                        mb: 1,
                        width: 'fit-content',
                    }}
                >
                    {title}
                </Typography>
                <Stack spacing={2} direction="row" justifyContent="center">
                    {gameLeaguePlayoffData
                        ? gameLeaguePlayoffData.rounds.map(
                              ({ roundNumber, startDate, endDate }) => {
                                  return (
                                      <Box
                                          key={roundNumber}
                                          sx={{
                                              height: 40,
                                              flex: '1 0 0',
                                              minWidth: minColumnWidth,
                                              maxWidth: maxColumnWidth,
                                          }}
                                      >
                                          <Typography color="white" fontSize={14}>
                                              Round {roundNumber}
                                          </Typography>
                                          <Typography fontSize={14}>
                                              {formatDateRange(startDate, endDate)}
                                          </Typography>
                                      </Box>
                                  );
                              },
                          )
                        : null}
                </Stack>
                <ColorfulDivider height={4} my={2} />
                <Stack spacing={2} direction="row" justifyContent="center">
                    {gameLeaguePlayoffData
                        ? gameLeaguePlayoffData.rounds.map(({ roundNumber }) => (
                              <Box
                                  key={roundNumber}
                                  sx={{
                                      flex: '1 0 0',
                                      minWidth: minColumnWidth,
                                      maxWidth: maxColumnWidth,
                                  }}
                              >
                                  <Stack
                                      spacing={2}
                                      sx={{ height: '100%' }}
                                      justifyContent={justifyGamesContent}
                                  >
                                      {children(roundNumber)}
                                  </Stack>
                              </Box>
                          ))
                        : null}
                </Stack>
            </Box>
        );
    },
);
