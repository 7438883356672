import { FC, Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Card from '@mui/material/Card';
import { FriendshipStatus, PlayerFriendModel } from '../../common/api/api';
import Box from '@mui/material/Box';
import { Avatar } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { noop } from '../../common/utils/noop';
import { useIsMobile } from '../../common/hooks/use-is-mobile';

interface PlayerProps {
    player: PlayerFriendModel;
    onAdd?: (id: number) => Promise<void>;
    onRemove?: (id: number) => Promise<void>;
    onAccept?: (id: number) => Promise<void>;
    onDeny?: (id: number) => Promise<void>;
}

export const Player: FC<PlayerProps> = observer(({ player, onAdd, onDeny, onAccept, onRemove }) => {
    const isMobile = useIsMobile();
    const [feedbackText, setFeedbackText] = useState('');

    const actionMinWidth = 76;

    useEffect(() => {
        if (player.friendshipStatus === FriendshipStatus.RequestSent) {
            setFeedbackText('Friend Request Sent');
        }
    }, [player.friendshipStatus]);

    let actions = <Fragment />;
    let actionHeadline = <Fragment />;

    if (player.friendshipStatus === FriendshipStatus.RequestReceived) {
        if (isMobile) {
            actionHeadline = (
                <Typography variant="body2">
                    <strong>@{player.username}</strong> wants to connect with you.
                </Typography>
            );
        }

        actions = (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="right"
                flexWrap={isMobile ? 'nowrap' : 'wrap'}
                gap={1.5}
            >
                <Button
                    sx={{ minWidth: actionMinWidth }}
                    fullWidth={
                        isMobile && player.friendshipStatus === FriendshipStatus.RequestReceived
                    }
                    color="error"
                    variant="outlined"
                    onClick={() => {
                        onDeny?.(player.id)
                            .then(() => setFeedbackText('Friend Request Denied'))
                            .catch(noop);
                    }}
                >
                    Deny
                </Button>
                <Button
                    sx={{ minWidth: actionMinWidth }}
                    fullWidth={
                        isMobile && player.friendshipStatus === FriendshipStatus.RequestReceived
                    }
                    variant="contained"
                    onClick={() => {
                        onAccept?.(player.id)
                            .then(() => {
                                setFeedbackText('Friend Request Accepted');
                            })
                            .catch(noop);
                    }}
                >
                    Accept
                </Button>
            </Box>
        );
    }

    if (player.friendshipStatus === FriendshipStatus.Friends) {
        actions = (
            <Button
                sx={{ minWidth: actionMinWidth }}
                color="error"
                variant="outlined"
                onClick={() => {
                    onRemove?.(player.id)
                        .then(() => setFeedbackText('Removed'))
                        .catch(noop);
                }}
            >
                Remove
            </Button>
        );
    }

    if (
        player.friendshipStatus === FriendshipStatus.NotFriends ||
        player.friendshipStatus === FriendshipStatus.None
    ) {
        actions = (
            <Button
                sx={{ minWidth: actionMinWidth }}
                variant="contained"
                onClick={() => {
                    onAdd?.(player.id).catch(noop);
                }}
            >
                Add Friends
            </Button>
        );
    }

    if (feedbackText) {
        actions = (
            <Typography variant="body1" color="textSecondary" align="right">
                {feedbackText}
            </Typography>
        );
    }

    return (
        <Card>
            <Box
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                py={2}
                px={3}
                gap={1.5}
            >
                <Box display="flex" alignItems="center" gap={1.5}>
                    <Avatar src={player.avatarUrl} />
                    {(!isMobile ||
                        player.friendshipStatus !== FriendshipStatus.RequestReceived) && (
                        <Typography variant="body1">{player.username}</Typography>
                    )}
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1.5}
                    flexGrow={
                        player.friendshipStatus === FriendshipStatus.RequestReceived ? 1 : undefined
                    }
                >
                    {actionHeadline}
                    {actions}
                </Box>
            </Box>
        </Card>
    );
});
