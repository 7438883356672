import { observer } from 'mobx-react';
import { provide, useDependencies } from 'ioc';
import { AuthLayout } from '../layouts/auth-layout';
import { LoginStore } from '../../stores/login.store';

import { StyledFormFrame } from '../brand-components/brand-form-frame';

import { LoginForm } from './login-form';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getRedirectUriForDiscord } from '../../utils/third-party-utils';

export const Login = provide([LoginStore])(
    observer(() => {
        const [{ loginWithDiscordCode }] = useDependencies(LoginStore);
        const [searchParams] = useSearchParams();
        const location = useLocation();
        const navigate = useNavigate();

        useEffect(() => {
            const code = searchParams.get('code');
            const provider = location.pathname.includes('discord-callback');

            const login = async () => {
                return loginWithDiscordCode(code!, getRedirectUriForDiscord());
            };

            if (code && provider) {
                login();
            }
        }, [location.pathname, loginWithDiscordCode, navigate, searchParams]);

        return (
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
                <AuthLayout>
                    <StyledFormFrame sx={{ height: { sx: '100%' } }}>
                        <LoginForm />
                    </StyledFormFrame>
                </AuthLayout>
            </GoogleOAuthProvider>
        );
    }),
);
