import { FC, Fragment } from 'react';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { ColoredBorder } from '../../../../common/components/colored-border/colored-border';
import { Avatar, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { customNeutral } from '../theme-provider/colors';
import PeopleIcon from '@mui/icons-material/People';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Logout } from '@mui/icons-material';
import { usePopover } from '../../../../common/hooks/use-popover';
import { useDependencies } from 'ioc';
import { AuthStore } from '../../../../common/stores/auth-store';
import { useNavigate } from 'react-router-dom';
import { useScreenSize } from '../../../../common/hooks/use-is-mobile';

interface ProfileProps {}

export const Profile: FC<ProfileProps> = observer(() => {
    const [{ me, unAuthorize }] = useDependencies(AuthStore);

    const { anchorRef, handleToggle, open, handleClose } = usePopover();

    const navigate = useNavigate();

    const { isMobile } = useScreenSize();

    const logOut = () => {
        unAuthorize();
    };

    if (!me) {
        return null;
    }

    return (
        <Fragment>
            <Box ref={anchorRef}>
                <IconButton onClick={handleToggle}>
                    <ColoredBorder toTop borderRadius={100} borderSize="2px">
                        <Avatar
                            sx={{
                                width: 44,
                                height: 44,
                                background: customNeutral[900],
                            }}
                            src={me.avatarUrl}
                        />
                    </ColoredBorder>
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorRef.current}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => navigate('/profile')}>
                    <ListItemIcon>
                        <AccountCircleIcon />
                    </ListItemIcon>
                    Profile
                </MenuItem>
                {isMobile && (
                    <MenuItem onClick={() => navigate('/friends')}>
                        <ListItemIcon>
                            <PeopleIcon />
                        </ListItemIcon>
                        Friends
                    </MenuItem>
                )}
                <MenuItem onClick={logOut}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </Fragment>
    );
});
