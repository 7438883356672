import { useMediaQuery, useTheme } from '@mui/material';

export const useIsMobile = () => {
    const theme = useTheme();

    return useMediaQuery(theme.breakpoints.down('sm'));
};

export const useScreenSize = () => {
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    return { isMobile, isTablet, isDesktop };
};
