import { provide, useDependencies } from 'ioc';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import { ResetPasswordStore } from '../../stores/reset-password.store';
import { BrandTypography } from '../brand-components/brand-typography';
import Typography from '@mui/material/Typography';
import { ArrowCircleRight } from '@phosphor-icons/react';
import { BrandButton } from '../brand-components/brand-button';
import { PasswordField } from '../brand-components/password-field';
import { PasswordCriteria } from '../brand-components/password-criteria';
import { LoadStatus } from '../../../common/enums/load-status';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

export const ResetPasswordForm = provide([ResetPasswordStore])(
    observer(() => {
        const [searchParams] = useSearchParams();
        const navigate = useNavigate();
        const [{ formState, resetPasswordLoadStatus, resetPassword }] =
            useDependencies(ResetPasswordStore);
        const { newPassword, confirmPassword } = formState.$;

        useEffect(() => {
            const token = searchParams.get('token');
            if (!token) {
                navigate('/login');
            }
        }, [navigate, searchParams]);

        const handleSubmit = async (event: any) => {
            event?.preventDefault();

            const token = searchParams.get('token');

            const result = await resetPassword(token!);

            if (result) {
                window.toastr.success('Password changed successfully');
                navigate('/');
            }
        };

        return (
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flex: 1,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <BrandTypography variant="h4" textAlign="center" mb={2}>
                        Reset password
                    </BrandTypography>
                    <Typography
                        textAlign="center"
                        sx={{ color: theme => theme.palette.primary[250] }}
                    >
                        Enter you email address and we’ll send you a link to reset your password
                    </Typography>
                </Box>
                <Box width="100%" gap={2}>
                    <PasswordField
                        fullWidth
                        variant="standard"
                        placeholder="New Password"
                        value={newPassword.value}
                        error={newPassword.hasError}
                        sx={{ mb: 2 }}
                        onChange={event => {
                            newPassword.onChange(event.target.value);
                        }}
                        onBlur={newPassword.enableAutoValidationAndValidate}
                    />
                    <PasswordCriteria password={newPassword.value} />
                    <PasswordField
                        fullWidth
                        variant="standard"
                        placeholder="Confirm Password"
                        value={confirmPassword.value}
                        error={!!confirmPassword.error}
                        helperText={confirmPassword.error}
                        onChange={event => {
                            confirmPassword.onChange(event.target.value);
                        }}
                        onBlur={confirmPassword.enableAutoValidationAndValidate}
                    />
                </Box>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <BrandButton
                        type="submit"
                        disabled={
                            formState.hasError || resetPasswordLoadStatus === LoadStatus.Loading
                        }
                        endIcon={<ArrowCircleRight size={32} weight="fill" color="#000" />}
                    >
                        Reset password
                    </BrandButton>
                </Box>
            </Box>
        );
    }),
);
