import { injectable } from 'ioc';
import { LoadStatus } from '../../common/enums/load-status';
import { GameLeaguePublicModel, GameStatusParam } from '../../common/api/api';
import { makeObservable, observable, runInAction, when } from 'mobx';
import { apiClient } from '../../common/api/api-client';
import { ASYNC_LOAD_LIMIT } from '../../common/utils/ux';
import axios from 'axios';

@injectable()
export abstract class LeaguesListBaseStore {
    leaguesLoadStatus = LoadStatus.None;
    areAllLeaguesFetched = false;
    leagues: GameLeaguePublicModel[] = [];
    searchTerm = '';

    abortController?: AbortController;

    abstract gameStatusParam: GameStatusParam;

    constructor() {
        makeObservable(this, {
            leaguesLoadStatus: observable,
            areAllLeaguesFetched: observable,
            leagues: observable,
            searchTerm: observable,
        });
    }

    fetchLeagues = async () => {
        if (this.leaguesLoadStatus === LoadStatus.Loading || this.areAllLeaguesFetched) {
            return;
        }

        this.abortController = new AbortController();

        try {
            runInAction(() => {
                this.leaguesLoadStatus = LoadStatus.Loading;
            });

            const { items: leagues, totalAmount } = await apiClient.getGameLeagues(
                this.searchTerm,
                this.gameStatusParam,
                this.leagues.length,
                ASYNC_LOAD_LIMIT,
                this.abortController.signal,
            );

            if (leagues) {
                runInAction(() => {
                    this.leagues.push(...leagues);
                });
            }

            if (totalAmount <= this.leagues.length) {
                runInAction(() => {
                    this.areAllLeaguesFetched = true;
                });
            }

            runInAction(() => {
                this.leaguesLoadStatus = LoadStatus.Ok;
            });
        } catch (error) {
            runInAction(() => {
                this.leaguesLoadStatus = axios.isCancel(error) ? LoadStatus.Ok : LoadStatus.Error;
            });
        }
    };

    searchLeagues = async (searchTerm: string) => {
        await this.resetLeagues();

        runInAction(() => {
            this.searchTerm = searchTerm;
        });

        this.fetchLeagues();
    };

    resetLeagues = async () => {
        this.abortController?.abort();
        await when(() => this.leaguesLoadStatus !== LoadStatus.Loading);

        runInAction(() => {
            this.leagues = [];
            this.areAllLeaguesFetched = false;
        });
    };
}
