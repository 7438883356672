import { provide, useDependencies } from 'ioc';
import { observer } from 'mobx-react';
import Typography from '@mui/material/Typography';

import { AuthLayout } from '../layouts/auth-layout';
import { ForgotPasswordForm } from './forgot-password-form';
import { StyledFormFrame } from '../brand-components/brand-form-frame';
import { ForgotPasswordStore } from '../../stores/forgot-password.store';
import { SuccessfullySentBanner } from '../brand-components/successfully-sent-banner';

export const ForgotPassword = provide([ForgotPasswordStore])(
    observer(() => {
        const [{ isEmailSent }] = useDependencies(ForgotPasswordStore);

        return (
            <AuthLayout>
                <StyledFormFrame sx={{ height: { sx: '100%' } }}>
                    {isEmailSent ? (
                        <SuccessfullySentBanner title="We've Tossed an Email Your Way">
                            <Typography
                                textAlign="center"
                                sx={{ color: theme => theme.palette.primary[250], mx: { xs: 0 } }}
                            >
                                Now, the ball is in your court. Follow the link in your email to set
                                a new password.
                            </Typography>
                        </SuccessfullySentBanner>
                    ) : (
                        <ForgotPasswordForm />
                    )}
                </StyledFormFrame>
            </AuthLayout>
        );
    }),
);
