import { ConferencePlayerInfoModel } from '../../../../common/api/api';
import { FC, ReactNode } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { ColoredBorder } from '../../../../common/components/colored-border/colored-border';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

interface PlayerInfoProps {
    player: Partial<ConferencePlayerInfoModel>;
    mainInfo?: ReactNode;
}

export const PlayerInfo: FC<PlayerInfoProps> = ({ player, mainInfo }) => {
    return (
        <Box display="flex" gap={1} alignItems="center">
            <ColoredBorder toTop borderSize="2px" borderRadius={100}>
                <Avatar sx={{ width: 40, height: 40 }} src={player.avatarUrl} />
            </ColoredBorder>
            <Stack spacing={0.5}>
                <Typography fontSize={16} lineHeight={1} sx={{ wordBreak: 'break-all' }}>
                    {player.username}
                </Typography>
                {!!mainInfo && mainInfo}
            </Stack>
        </Box>
    );
};
