import { FC, PropsWithChildren, ReactElement } from 'react';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';

interface IAppContentContainerProps {
    header?: ReactElement;
}

export const AppContentContainer: FC<PropsWithChildren<IAppContentContainerProps>> = ({
    children,
    header,
}) => {
    return (
        <Box sx={{ pt: { xs: header ? 0 : 2, sm: 2 } }}>
            {!!header && (
                <Container maxWidth="xl" sx={{ px: { xs: 0, md: 10 }, mb: 4 }}>
                    {header}
                </Container>
            )}
            <Container maxWidth="xl" sx={{ px: { xs: 2, sx: 3, md: 10 } }}>
                {children}
            </Container>
        </Box>
    );
};

export const AppContentPictureContainer: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Container maxWidth="xl" sx={{ px: { xs: 0, md: 10 } }}>
            {children}
        </Container>
    );
};
