import { FC } from 'react';
import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import { HistoryStore } from '../../stores/history-store';
import { TableHead } from '@mui/material';
import { useIsMobile } from '../../../common/hooks/use-is-mobile';
import { HistoryGamesMobile } from './mobile/history-games-mobile';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { formatDateWithName } from '../../../../utils';
import { Balance } from 'shared';
import Box from '@mui/material/Box';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { StatusChip } from '../../../lobby/components/lobby-games/status-chip';
import { LastElementSetter, TableColumnConfig } from '../../../common/utils/types';
import { GameModel } from '../../../common/api/api';
import { CustomIconButton } from '../../../common/components/custom-icon-button/custom-icon-button';
import { TypographyTableCell } from '../../../common/components/table-cells/typography-table-cell';
import { SportLeagueIconList } from '../../../common/components/sport-league-icon-list/sport-league-icon-list';
import { CustomTable } from '../../../common/components/custom-table/custom-table';

export interface IHistoryGamesProps {
    setLastElement: LastElementSetter;
}

const historyColumns: TableColumnConfig<GameModel>[] = [
    {
        title: 'Game ID',
        getComponent: game => <TypographyTableCell>#{game.id}</TypographyTableCell>,
    },
    {
        title: 'Start Date',
        getComponent: game => (
            <TypographyTableCell>{formatDateWithName(game.startDate)}</TypographyTableCell>
        ),
    },
    {
        title: 'End Date',
        getComponent: game => (
            <TypographyTableCell>{formatDateWithName(game.endDate)}</TypographyTableCell>
        ),
    },
    {
        title: 'Status',
        getComponent: game => <StatusChip status={game.status} />,
    },
    {
        title: 'Participants',
        getComponent: game => (
            <TypographyTableCell>
                {game.currentPlayerCount}/{game.maxPlayerCount}
            </TypographyTableCell>
        ),
    },
    {
        title: 'Initial Balance',
        getComponent: game => (
            <Balance typographyProps={{ fontWeight: 700 }} amount={game.initialBalance} />
        ),
    },
    {
        title: 'Final Balance',
        getComponent: game => (
            <Balance typographyProps={{ fontWeight: 700 }} amount={game.currentBalance} />
        ),
    },
    {
        title: 'Leagues',
        getComponent: game => <SportLeagueIconList leagues={game.leagues} />,
    },
    {
        title: 'Ranking',
        getComponent: game => <TypographyTableCell>#{game.ranking}</TypographyTableCell>,
    },
];

export const HistoryGames: FC<IHistoryGamesProps> = observer(({ setLastElement }) => {
    const [{ showLeaderboard, games }] = useDependencies(HistoryStore);

    const isMobile = useIsMobile();

    if (isMobile) {
        return <HistoryGamesMobile setLastElement={setLastElement} />;
    }

    return (
        <CustomTable>
            {/* <TableHead>*/}
            {/*    <TableRow>*/}
            {/*        {historyColumns.map((column, columnIndex) => (*/}
            {/*            <TableCell key={columnIndex}>{column.title}</TableCell>*/}
            {/*        ))}*/}
            {/*        <TableCell />*/}
            {/*    </TableRow>*/}
            {/* </TableHead>*/}
            <TableBody>
                {games.map((game, gameIndex) => (
                    <TableRow
                        key={game.id}
                        ref={gameIndex === games.length - 1 ? setLastElement : undefined}
                    >
                        {historyColumns.map((column, columnIndex) => (
                            <TableCell key={columnIndex}>{column.getComponent(game)}</TableCell>
                        ))}
                        <TableCell align="right">
                            <Box display="flex" gap={2} justifyContent="flex-end">
                                <CustomIconButton
                                    onClick={() => showLeaderboard(game.id)}
                                    color="#FF7D00"
                                >
                                    <LeaderboardIcon />
                                </CustomIconButton>
                            </Box>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </CustomTable>
    );
});
