import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { provide, useDependencies } from 'ioc';
import { SlateStore } from '../stores/slate-store';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { BetSlipStore } from '../../bet-slip/stores/bet-slip-store';
import { LoadStatus } from '../../common/enums/load-status';
import { CommonBettingSection } from '../../redesigned-modules/common/components/common-betting-section/common-betting-section';
import { SlateMatchContent } from './slate-match-content';
import { SportLeagues } from '../../redesigned-modules/common/components/sport-leagues/sport-leagues';
import { AppContentContainer } from '../../redesigned-modules/common/components/app-content-container/app-content-container';
import Typography from '@mui/material/Typography';
import { BetSlip } from '../../bet-slip/components/bet-slip';
import { LiveOddsStore } from '../../common/stores/live-odds-store';
import { WithBettingHub } from '../../root/components/with-betting-hub';

export const Slate = provide([SlateStore, BetSlipStore, LiveOddsStore])(
    observer(() => {
        const { leagueId: leagueIdParam, matchId: matchIdParam } = useParams();

        const [
            { sportLeagueId, sportLeaguesLoadStatus, init, selectSportLeague, selectMatch, sports },
        ] = useDependencies(SlateStore);

        useEffect(() => {
            // TODO: add number validation
            if (leagueIdParam) {
                selectSportLeague(+leagueIdParam);
            }
        }, [leagueIdParam]);

        useEffect(() => {
            // TODO: add number validation
            selectMatch(matchIdParam ? +matchIdParam : undefined);
        }, [matchIdParam]);

        useEffect(() => {
            init();
        }, []);

        const navigate = useNavigate();

        const handleLeagueChange = (selectedId: number, replace?: boolean) => {
            if (selectedId !== sportLeagueId) {
                navigate(`../${selectedId}`, { replace });
            }
        };

        useEffect(() => {
            if (sportLeaguesLoadStatus === LoadStatus.Ok && leagueIdParam === undefined) {
                const firstValidSport = sports.find(sport => !!sport.leagues?.length);

                if (firstValidSport) {
                    const firstLeague = firstValidSport.leagues![0];
                    handleLeagueChange(firstLeague.id, true);
                }
            }
        }, [sportLeaguesLoadStatus]);

        const handleBetSlipMatchClick = (matchId: number) => {
            navigate(`../${sportLeagueId}/${matchId}`);
        };

        return (
            <AppContentContainer>
                <CommonBettingSection
                    header={
                        <Typography fontWeight={900} fontSize={34} color="secondary">
                            Slate
                        </Typography>
                    }
                    sportLeagues={
                        <SportLeagues
                            sportLeagues={sports}
                            activeSportLeagueId={sportLeagueId}
                            onChange={handleLeagueChange}
                        />
                    }
                    content={
                        <SlateMatchContent
                            activeMatchId={matchIdParam ? +matchIdParam : undefined}
                        />
                    }
                    betSlip={<BetSlip isReview onMatchClick={handleBetSlipMatchClick} />}
                />
            </AppContentContainer>
        );
    }),
);

export const SlatePage = () => {
    return (
        <WithBettingHub>
            <Routes>
                <Route path=":leagueId?/:matchId?" element={<Slate />} />
                <Route path="*" element={<Navigate to="" replace />} />
            </Routes>
        </WithBettingHub>
    );
};
