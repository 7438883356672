import { FC } from 'react';
import { observer } from 'mobx-react';
import Card from '@mui/material/Card';
import { CardActionArea, Stack, useTheme } from '@mui/material';
import { LeagueTitleModel } from '../../../../common/api/api';
import Typography from '@mui/material/Typography';
import { customNeutral } from '../../../root/components/theme-provider/colors';

interface ISportLeagueProps {
    sportLeague: LeagueTitleModel;
    onClick: () => void;
    isActive?: boolean;
}

export const SportLeague: FC<ISportLeagueProps> = observer(
    ({ sportLeague, onClick, isActive }) => {
        const { name, logoUrl } = sportLeague;

        const theme = useTheme();

        return (
            <Card
                sx={{
                    background: isActive
                        ? theme.palette.secondary.main
                        : customNeutral[900],
                    boxShadow: '0px 3px 10px 0px #83838326',
                }}
                onClick={onClick}
            >
                <CardActionArea sx={{ px: 2, py: 1 }}>
                    <Stack alignItems={'center'} direction={'row'} spacing={1}>
                        {!!logoUrl && (
                            <img
                                height={24}
                                src={sportLeague.logoUrl}
                                alt={`${logoUrl} Logo`}
                            />
                        )}
                        <Typography
                            fontWeight={900}
                            color={isActive ? 'black' : 'white'}
                        >
                            {name}
                        </Typography>
                    </Stack>
                </CardActionArea>
            </Card>
        );
    }
);
