import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { provide } from 'ioc';
import { LeaderboardStore } from '../stores/leaderboard-store';
import { useDependencies } from 'ioc';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { IconButton, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { useIsMobile } from '../../common/hooks/use-is-mobile';
import { LeaderboardContent } from './leaderboard-content';
import { LeaderboardView } from '../enums/leaderboard-view';
import { BetHistoryContent } from './bet-history-content';

interface LeaderboardProps {
    gameId: number;
    onClose: () => void;
}

export const Leaderboard: FC<LeaderboardProps> = provide([LeaderboardStore])(
    observer(({ gameId, onClose }) => {
        const [{ init, leaderboardView }] = useDependencies(LeaderboardStore);
        const isMobile = useIsMobile();

        useEffect(() => {
            init(gameId);
        }, []);

        return (
            <Dialog fullScreen={isMobile} open onClose={onClose} fullWidth maxWidth="md">
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    px={2}
                    sx={{ height: 68 }}
                >
                    {/* <DialogTitle sx={{ m: 0, p: 2 }}>*/}
                    <Typography fontWeight={900} fontSize={24} color="primary">
                        {leaderboardView === LeaderboardView.Leaderboard
                            ? 'Leaderboard'
                            : 'Bet History'}
                    </Typography>
                    {/* </DialogTitle>*/}
                    <IconButton onClick={onClose} sx={{ p: 0, position: 'absolute', right: 22 }}>
                        <CloseIcon sx={{ width: 32, height: 32 }} />
                    </IconButton>
                </Box>
                {leaderboardView === LeaderboardView.Leaderboard && <LeaderboardContent />}
                {leaderboardView === LeaderboardView.BetHistory && <BetHistoryContent />}
            </Dialog>
        );
    }),
);
