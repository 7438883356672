import React from 'react';
import { provide, useDependencies } from 'ioc';
import { observer } from 'mobx-react';
import { AuthLayout } from '../layouts/auth-layout';
import { StyledFormFrame } from '../brand-components/brand-form-frame';
import { ResetPasswordStore } from '../../stores/reset-password.store';
import { SuccessfullySentBanner } from '../brand-components/successfully-sent-banner';
import { ResetPasswordForm } from './reset-password-form';

import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { UnauthenticatedRoutes } from '../../../redesigned-modules/root/components/app/app-unauthenticated';

export const ResetPassword = provide([ResetPasswordStore])(
    observer(() => {
        const [{ isPasswordReset }] = useDependencies(ResetPasswordStore);

        return (
            <AuthLayout>
                <StyledFormFrame sx={{ height: { sx: '100%' } }}>
                    {isPasswordReset ? (
                        <SuccessfullySentBanner title="Your password has been reset.">
                            <Link
                                to={UnauthenticatedRoutes.login}
                                component={RouterLink}
                                textAlign="center"
                                underline="hover"
                                sx={{ color: theme => theme.palette.primary[250] }}
                            >
                                Log in now
                            </Link>
                        </SuccessfullySentBanner>
                    ) : (
                        <ResetPasswordForm />
                    )}
                </StyledFormFrame>
            </AuthLayout>
        );
    }),
);
