import { Fragment, useState } from 'react';
import { observer } from 'mobx-react';
import { provide } from 'ioc';
import { FriendsContentStore } from '../stores/friends-content-store';
import { useDependencies } from 'ioc';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Tab, Tabs } from '@mui/material';
import { MyFriends } from './my-friends';
import { FriendsContentTab } from '../enum/friends-content-tab';
import { FriendRequests } from './friend-requests';
import { AddFriends } from './add-friends';

export const FriendsContent = provide([FriendsContentStore])(
    observer(() => {
        const [tab, setTab] = useState(FriendsContentTab.MyFriends);

        const [{ startAddingFriends, isAddingFriends }] = useDependencies(FriendsContentStore);

        const playerLists = (
            <Fragment>
                {tab === FriendsContentTab.MyFriends && <MyFriends />}
                {tab === FriendsContentTab.FriendRequests && <FriendRequests />}
            </Fragment>
        );

        return (
            <Fragment>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="h5" fontWeight={600}>
                        Friends
                    </Typography>
                    <Button variant="contained" onClick={startAddingFriends}>
                        Add Friends
                    </Button>
                </Box>
                <Tabs sx={{ mb: 2 }} value={tab} onChange={(_event, newTab) => setTab(newTab)}>
                    <Tab
                        value={FriendsContentTab.MyFriends}
                        label="My Friends"
                        sx={{ flex: '1 1 0', maxWidth: 'unset' }}
                    />
                    <Tab
                        value={FriendsContentTab.FriendRequests}
                        label="Friend Requests"
                        sx={{ flex: '1 1 0', maxWidth: 'unset' }}
                    />
                </Tabs>
                {playerLists}
                {isAddingFriends && <AddFriends />}
            </Fragment>
        );
    }),
);
