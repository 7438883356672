import { useLocation } from 'react-router-dom';

export const useReturnURL = () => {
    const location = useLocation();
    const returnURL = location.pathname + location.search + location.hash;
    const redirectionURLSearchParams = new URLSearchParams();
    redirectionURLSearchParams.set('returnURL', encodeURIComponent(returnURL));

    return redirectionURLSearchParams.toString();
};
