import { observer } from 'mobx-react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useReturnURL } from '../../../../../hooks/use-return-url';

// New Routes
import { Login } from '../../../../auth/components/login/login';
import { Signup } from '../../../../auth/components/signup/signup';
import { ForgotPassword } from '../../../../auth/components/forgot-password/forgot-password';
import { ResetPassword } from '../../../../auth/components/reset-password/reset-password';

// Old Routes
// import Forgot from '../../../../../pages/Auth/Forgot';
// import Reset from '../../../../../pages/Auth/Reset';
// import { Signup } from '../../../../auth/components/_signup/signup';
import Contact from '../../../../../pages/Contact';

export const UnauthenticatedRoutes = {
    login: 'login',
    signup: 'signup',
    forgotPassword: 'forgot-password',
    passwordReset: 'password-reset',
    contacts: 'contacts',
} as const;

export const AppUnauthenticated = observer(() => {
    const redirectionURL = useReturnURL();

    return (
        <Routes>
            {/* auth*/}
            <Route path={UnauthenticatedRoutes.login} element={<Login />} />
            <Route path="discord-callback" element={<Login />} />
            <Route path="google-callback" element={<Login />} />

            <Route path={UnauthenticatedRoutes.signup} element={<Signup />} />
            <Route path={UnauthenticatedRoutes.forgotPassword} element={<ForgotPassword />} />
            <Route path={UnauthenticatedRoutes.passwordReset} element={<ResetPassword />} />

            {/* common*/}
            <Route path={UnauthenticatedRoutes.contacts} element={<Contact />} />

            {/* fallback*/}
            <Route
                path="*"
                element={
                    <Navigate
                        to={{
                            pathname: 'login',
                            search: redirectionURL,
                        }}
                        replace
                    />
                }
            />
        </Routes>
    );
});
