import { TableColumnConfig } from '../../common/utils/types';
import { GameLeaguePublicModel } from '../../common/api/api';
import { TypographyTableCell } from '../../common/components/table-cells/typography-table-cell';
import { Chip, Stack } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { SportLeagueIconList } from '../../common/components/sport-league-icon-list/sport-league-icon-list';
import { Fragment } from 'react';
import { Participants } from '../../common/components/participants/participants';
import { formatDateWithName } from '../../../utils';

export const leaguesListColumns: TableColumnConfig<GameLeaguePublicModel>[] = [
    {
        title: 'League Name',
        getComponent: league => <TypographyTableCell>{league.name}</TypographyTableCell>,
    },
    {
        title: 'Start Date',
        getComponent: league => (
            <TypographyTableCell>{formatDateWithName(league.startDate)}</TypographyTableCell>
        ),
    },
    {
        title: 'End Date',
        getComponent: league => (
            <TypographyTableCell>{formatDateWithName(league.endDate)}</TypographyTableCell>
        ),
    },
    {
        title: 'Participants',
        getComponent: league => (
            <Participants>
                <TypographyTableCell>
                    {league.currentPlayerCount}/{league.maxPlayerCount}
                </TypographyTableCell>
            </Participants>
        ),
    },
    // {
    //     title: 'Created By',
    //     getComponent: league => (
    //         <TypographyTableCell>
    //             <Chip
    //                 sx={{ border: 'none' }}
    //                 variant="outlined"
    //                 avatar={<Avatar src={league.createdByPlayer.avatarUrl} />}
    //                 label={league.createdByPlayer.username}
    //             />
    //         </TypographyTableCell>
    //     ),
    // },
    {
        title: 'Leagues',
        getComponent: league => <SportLeagueIconList leagues={league.leagues} />,
    },
];
