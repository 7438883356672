import { provide } from 'ioc';
import { observer } from 'mobx-react';
import { AuthLayout } from '../layouts/auth-layout';
import { StyledFormFrame } from '../brand-components/brand-form-frame';
import { SignupStore } from '../../stores/signup.store';
import { SignupForm } from './signup-form';

export const Signup = provide([SignupStore])(
    observer(() => {
        return (
            <AuthLayout>
                <StyledFormFrame sx={{ height: { sx: '100%' }, overflow: 'auto' }}>
                    <SignupForm />
                </StyledFormFrame>
            </AuthLayout>
        );
    }),
);
