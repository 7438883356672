import { Button, ButtonProps, IconButton } from '@mui/material';
import { styled } from '@mui/system';

// TODO:ARN change values to use from theme

export const BrandButton = styled(Button)<ButtonProps>(({ theme }) => ({
    'backgroundColor': theme.palette.secondary.main,
    'borderRadius': '100px',
    'padding': '6px 20px',
    'fontSize': '16px',
    'fontWeight': 'bold',
    'color': theme.palette.primary['950'],
    '&:hover': {
        backgroundColor: theme.palette.secondary[200],
    },
    '&:disabled': {
        backgroundColor: '#4E817F', // Disabled state color
    },
}));

export const BrandLinkButton = styled(IconButton)<any>(({ theme }) => ({
    'backgroundColor': theme.palette.secondary.main,
    'color': theme.palette.primary['950'],
    '&:hover': {
        backgroundColor: theme.palette.secondary[200],
    },
    '&:disabled': {
        backgroundColor: '#4E817F', // Disabled state color
    },
}));
