import { observer } from 'mobx-react';
import { provide } from 'ioc';
import { LeaguesStore } from '../stores/leagues-store';
import Box from '@mui/material/Box';
import LeaguesSectionImage from '../../../assets/redesign/images/leagues-section-image.jpeg';
import Typography from '@mui/material/Typography';
import { Tab, Tabs } from '@mui/material';
import {
    Navigate,
    Link as RouterLink,
    Route,
    Routes,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import Button from '@mui/material/Button';
import { asParentPath } from '../../common/utils/as-parent-path';
import { MyLeagues } from './my-leagues/my-leagues';
import { LiveLeagues } from './live-leagues/live-leagues';
import { useDependencies } from 'ioc';
import { CreateLeague } from './create-league/create-league';
import { GameLeaguePublicModel } from '../../common/api/api';
import { Fragment, useEffect, useState } from 'react';
import Link from '@mui/material/Link';
import { NotificationsStore } from '../../common/stores/notifications-store';
import { LeaguesRoutes } from '../utils/leagues-routes';
import { LeagueContentPage } from './content/league-content-page';
import { InvitedLeague } from './invited-league/invited-league';
import { Participants } from '../../participants/components/participants';
import { SectionImage } from '../../common/components/section-image-container/section-image';
import { AppContentContainer } from '../../redesigned-modules/common/components/app-content-container/app-content-container';
import { LeaguesHistory } from './leagues-history/leagues-history';

const LeaguesPageMain = provide([LeaguesStore])(
    observer(() => {
        const [
            {
                isCreatingLeague,
                statusTab,
                hideCreateLeague,
                showCreateLeague,
                initInvitedLeague,
                invitedLeague,
                hideParticipants,
                getParticipants,
                leagueForParticipants,
            },
            { notify },
        ] = useDependencies(LeaguesStore, NotificationsStore);
        const navigate = useNavigate();

        const [myLeaguesKey, setMyLeaguesKey] = useState(() => Math.random());

        const [searchParams] = useSearchParams();

        useEffect(() => {
            // TODO: have globally defined keys
            const invitationId = searchParams.get('invitationId');
            const token = searchParams.get('token');

            if (invitationId) {
                initInvitedLeague(+invitationId, token ? decodeURIComponent(token) : undefined);
            }
        }, []);

        const createLeagueCallback = (gameLeague?: GameLeaguePublicModel) => {
            if (statusTab === LeaguesRoutes['My Leagues']) {
                setMyLeaguesKey(Math.random());
            }

            if (gameLeague) {
                notify({
                    severity: 'success',
                    children: (
                        <Typography>
                            Successfully created!
                            {statusTab === LeaguesRoutes['Live Leagues'] && (
                                <Fragment>
                                    &nbsp;See&nbsp;
                                    <Link
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            // closeNotification();
                                            navigate(`../${LeaguesRoutes['My Leagues']}`);
                                        }}
                                    >
                                        My Leagues
                                    </Link>
                                    .
                                </Fragment>
                            )}
                        </Typography>
                    ),
                });
            }
        };

        return (
            <AppContentContainer
                header={<SectionImage src={LeaguesSectionImage} headline="Leagues" />}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: { xs: 'column-reverse', sm: 'row' },
                        alignItems: 'start',
                        gap: 3,
                        mb: 3,
                    }}
                >
                    <Tabs value={statusTab}>
                        {Object.entries(LeaguesRoutes).map(([label, route]) => (
                            <Tab
                                key={label}
                                label={label}
                                component={RouterLink}
                                to={`../${route}`}
                                value={route}
                            />
                        ))}
                    </Tabs>
                    <Button
                        onClick={showCreateLeague}
                        variant="contained"
                        sx={{ width: { xs: '100%', sm: 'auto' } }}
                    >
                        Create League
                    </Button>
                </Box>
                <Routes>
                    <Route
                        path={LeaguesRoutes['My Leagues']}
                        element={<MyLeagues key={myLeaguesKey} />}
                    />
                    <Route path={LeaguesRoutes['Live Leagues']} element={<LiveLeagues />} />
                    <Route path={LeaguesRoutes.History} element={<LeaguesHistory />} />
                    <Route path="*" element={<Navigate to={LeaguesRoutes['My Leagues']} />} />
                </Routes>
                {isCreatingLeague && (
                    <CreateLeague
                        onClose={hideCreateLeague}
                        successCallback={createLeagueCallback}
                    />
                )}
                {!!invitedLeague && <InvitedLeague league={invitedLeague} />}
                {!!leagueForParticipants && (
                    <Participants onClose={hideParticipants} getParticipants={getParticipants} />
                )}
            </AppContentContainer>
        );
    }),
);

export const LeaguesPage = () => {
    return (
        <Routes>
            <Route path="*" element={<LeaguesPageMain />} />
            <Route path={asParentPath('content/:leagueId')} element={<LeagueContentPage />} />
        </Routes>
    );
};
