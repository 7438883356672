import Dialog from '@mui/material/Dialog';
import { FC, useEffect } from 'react';
import { useIsMobile } from '../../common/hooks/use-is-mobile';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { LoadStatus } from '../../common/enums/load-status';
import { Balance } from '../../../components/smallComponents';
import { CreateGameStore, INVALID_DATE_RANGE_ERROR } from '../stores/create-game-store';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { noop } from '../../common/utils/noop';
import moment, { Moment } from 'moment';
import { FieldState } from 'formstate';
import { provide } from 'ioc';
import { useDependencies } from 'ioc';
import { GameFullModel } from '../../common/api/api';

interface CreateGameProps {
    onClose: () => void;
    successCallback?: (game?: GameFullModel) => void;
}

export const CreateGame: FC<CreateGameProps> = provide([CreateGameStore])(
    observer(({ onClose, successCallback }) => {
        const [
            {
                leagues,
                submitCreateGame,
                formState,
                createGameLoadStatus,
                initialBalanceOptions,
                availableMatches,
                selectLeague,
                initCreateGame,
                selectMatch,
            },
        ] = useDependencies(CreateGameStore);

        const theme = useTheme();
        const isMobile = useIsMobile();

        const isInvalidPeriod = formState.error === INVALID_DATE_RANGE_ERROR;

        const {
            leagueIds,
            initialBalance,
            startDate,
            startTime,
            endDate,
            endTime,
            isPrivate,
            matchId,
            maxPlayers,
        } = formState.$;

        useEffect(() => {
            initCreateGame();
        }, []);

        const handleSubmit = () => {
            submitCreateGame()
                .then(game => {
                    onClose();
                    successCallback?.(game);
                })
                .catch(noop);
        };

        const handleDateChange = (field: FieldState<Date | null>) => (value: Moment | null) => {
            field.onChange(value?.toDate() ?? null);
        };

        return (
            <Dialog maxWidth="md" fullScreen={isMobile} open onClose={onClose} fullWidth>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    px={2}
                    sx={{ height: 68 }}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }}>
                        <Typography
                            fontWeight={900}
                            fontSize={24}
                            color={theme.palette.primary.main}
                        >
                            Game Creation
                        </Typography>
                    </DialogTitle>
                    <IconButton onClick={onClose} sx={{ p: 0, position: 'absolute', right: 22 }}>
                        <CloseIcon sx={{ width: 32, height: 32 }} />
                    </IconButton>
                </Box>
                <DialogContent sx={{ px: { sm: '100px' }, pb: 4 }}>
                    <Box
                        component="form"
                        onSubmit={event => {
                            event.preventDefault();
                            handleSubmit();
                        }}
                    >
                        <Box mb={2}>
                            <Typography
                                sx={{ mb: 2 }}
                                variant="subtitle2"
                                fontWeight={900}
                                color={leagueIds.error ? theme.palette.error.main : undefined}
                            >
                                Choose Sports
                            </Typography>
                            <Grid container spacing={3}>
                                {leagues.map(league => {
                                    const isActive = leagueIds.value.includes(league.leagueId!);

                                    return (
                                        <Grid item xs={6} sm={3} key={league.leagueId}>
                                            <Card
                                                sx={{
                                                    border: '4px solid',
                                                    p: '10px 20px',
                                                    borderColor: isActive
                                                        ? theme.palette.primary.main
                                                        : 'transparent',
                                                    borderRadius: 100,
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => selectLeague(league.leagueId!)}
                                            >
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    gap={0.5}
                                                    justifyContent="center"
                                                >
                                                    <img src={league.logoUrl} height={24} />
                                                    <Typography
                                                        variant="subtitle2"
                                                        fontWeight={900}
                                                        color={theme.palette.primary.main}
                                                    >
                                                        {league.name}
                                                    </Typography>
                                                </Box>
                                            </Card>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Typography sx={{ mb: 2 }} variant="subtitle2" fontWeight={900}>
                                Specify Tournament Details
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="create-game--match">Match</InputLabel>
                                        <Select
                                            disabled={leagueIds.value.length !== 1}
                                            label="Match"
                                            labelId="create-game--match"
                                            value={matchId.value}
                                            onChange={event => selectMatch(+event.target.value)}
                                        >
                                            <MenuItem value={-1}>All Matches</MenuItem>
                                            {availableMatches.map(match => {
                                                return (
                                                    <MenuItem value={match.id} key={match.id}>
                                                        {match.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="create-game--initial-balance">
                                            Initial Balance
                                        </InputLabel>
                                        <Select
                                            labelId="create-game--initial-balance"
                                            value={initialBalance.value}
                                            label="Initial Balance"
                                            onChange={event => {
                                                initialBalance.onChange(+event.target.value);
                                            }}
                                        >
                                            {initialBalanceOptions.map(
                                                (value: number, index: number) => (
                                                    <MenuItem value={value} key={index}>
                                                        <Balance amount={value} />
                                                    </MenuItem>
                                                ),
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        type="number"
                                        label="Max Players"
                                        fullWidth
                                        value={maxPlayers.value || ''}
                                        onChange={event => maxPlayers.onChange(+event.target.value)}
                                        onBlur={maxPlayers.enableAutoValidationAndValidate}
                                        error={maxPlayers.hasError}
                                        helperText={maxPlayers.error}
                                        InputProps={{ inputProps: { min: 0 } }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mb={2}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Typography sx={{ mb: 2 }} variant="subtitle1">
                                    Start Date & Time
                                </Typography>
                                <Box mb={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <DatePicker
                                                label="Date"
                                                onChange={handleDateChange(startDate)}
                                                value={
                                                    startDate.value ? moment(startDate.value) : null
                                                }
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        error:
                                                            startDate.hasError || isInvalidPeriod,
                                                        onBlur: startDate.enableAutoValidationAndValidate,
                                                    },
                                                }}
                                                disabled={matchId.value !== -1}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TimePicker
                                                format="hh A"
                                                label="Time"
                                                views={['hours']}
                                                onChange={handleDateChange(startTime)}
                                                value={
                                                    startTime.value ? moment(startTime.value) : null
                                                }
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        error:
                                                            startTime.hasError || isInvalidPeriod,
                                                        onBlur: startTime.enableAutoValidationAndValidate,
                                                    },
                                                }}
                                                disabled={matchId.value !== -1}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Typography sx={{ mb: 2 }} variant="subtitle1">
                                    End Date & Time
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <DatePicker
                                            label="Date"
                                            onChange={handleDateChange(endDate)}
                                            value={endDate.value ? moment(endDate.value) : null}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    error: endDate.hasError || isInvalidPeriod,
                                                    onBlur: endDate.enableAutoValidationAndValidate,
                                                },
                                            }}
                                            disabled={matchId.value !== -1}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TimePicker
                                            format="hh A"
                                            label="Time"
                                            views={['hours']}
                                            onChange={handleDateChange(endTime)}
                                            value={endTime.value ? moment(endTime.value) : null}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    error: endTime.hasError || isInvalidPeriod,
                                                    onBlur: endTime.enableAutoValidationAndValidate,
                                                },
                                            }}
                                            disabled={matchId.value !== -1}
                                        />
                                    </Grid>
                                </Grid>
                            </LocalizationProvider>
                        </Box>
                        <Box mb={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isPrivate.value}
                                        onChange={(_, checked) => isPrivate.onChange(checked)}
                                    />
                                }
                                label="Invitation Only"
                            />
                        </Box>
                        <Button
                            disabled={
                                formState.hasError || createGameLoadStatus === LoadStatus.Loading
                            }
                            size="large"
                            fullWidth
                            type="submit"
                            variant="contained"
                        >
                            Create Game
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }),
);
