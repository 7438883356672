import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { provide } from 'ioc';
import { StandingsMatchupStore } from '../../stores/standings-matchup-store';
import { useDependencies } from 'ioc';
import Box from '@mui/material/Box';
import { HeaderContainer } from '../../../common/components/header-container/header-container';
import { BackButton } from '../../../common/components/back-button/back-button';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { LoadStatus } from '../../../common/enums/load-status';
import { GameMatchup } from '../content/game-matchup/game-matchup';

export const StandingsMatchupPage = provide([StandingsMatchupStore])(
    observer(() => {
        const [{ init, playersWithBets, loadStatus }] = useDependencies(StandingsMatchupStore);

        const { gameId } = useParams();

        const navigate = useNavigate();
        const location = useLocation();
        const backlink = location.pathname.split('/').slice(0, -1).join('/');

        useEffect(() => {
            if (gameId) {
                init(+gameId);
            }
        }, []);

        return (
            // TODO: make reusable container
            <Container
                maxWidth="xl"
                sx={{
                    py: {
                        xs: 2,
                        sm: 3,
                    },
                    px: {
                        xs: 0,
                        sm: 15,
                    },
                }}
            >
                <HeaderContainer mb={2}>
                    <Box mb={2}>
                        <BackButton label="Standings" onClick={() => navigate(backlink)} />
                    </Box>
                    {!!playersWithBets.length && (
                        <Typography variant="h4" fontWeight={900} sx={{ wordBreak: 'break-all' }}>
                            {playersWithBets[0].player.username}
                            {playersWithBets.length === 2 &&
                                ` vs
                            ${playersWithBets[1].player.username}`}
                        </Typography>
                    )}
                </HeaderContainer>
                {loadStatus === LoadStatus.Loading ? (
                    <Box height={200} display="flex" alignItems="center" justifyContent="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <GameMatchup playersWithBets={playersWithBets} />
                )}
            </Container>
        );
    }),
);
