import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { FC } from 'react';

interface IColorfulDividerProps {
    height?: number;
    my?: number;
    mt?: number;
    mb?: number;
}

export const ColorfulDivider: FC<IColorfulDividerProps> = ({
    height = 2,
    my,
    mt,
    mb,
}) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                my,
                mt,
                mb,
                height,
                background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            }}
        />
    );
};
