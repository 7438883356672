import { FC, Fragment } from 'react';
import { Box, Button, Divider, Paper, Stack, Typography, useTheme } from '@mui/material';
import { BetSlipOdd } from './bet-slip-odd';
import { useDependencies } from 'ioc';
import { BetSlipMatchInfo, BetSlipStore } from '../stores/bet-slip-store';
import { useScreenSize } from '../../common/hooks/use-is-mobile';
import { observer } from 'mobx-react';
import { formatAmericanOdd } from '../../common/utils/format-american-odd';
import { ColorfulDivider } from '../../redesigned-modules/common/components/colorful-divider/colorful-divider';
import { Info } from '@phosphor-icons/react';
import { BetSlipHeader } from './bet-slip-header';
import { Missions } from './missions/missions';
import { customNeutral } from '../../redesigned-modules/root/components/theme-provider/colors';
import { TotalStakeInput } from './total-stake-input';
import { Balance } from '../../common/components/balance/balance';
import { SwipeableBetSlipContainer } from './swipeable-bet-slip-container';

export interface IBetSlipInternalProps {
    isReview?: boolean;
    onMatchClick: (matchId: BetSlipMatchInfo['id']) => void;
    isOpen?: boolean;
    swipe?: () => void;
}

export interface IBetSlipProps extends Pick<IBetSlipInternalProps, 'onMatchClick' | 'isReview'> {}

export const BetSlipMain: FC<IBetSlipInternalProps> = observer(props => {
    const { isReview, onMatchClick, swipe } = props;

    const [
        {
            activeOdds,
            placeBet,
            calculatedOdds,
            clearBetSlip,
            setTotalStake,
            amountToWin,
            totalStake,
            isPlaceBetButtonDisabled,
            minBetAmount,
        },
    ] = useDependencies(BetSlipStore);

    const isPlaceBetButtonVisible = !isReview && !!activeOdds.size;

    const handleMatchClick = (matchId: number) => {
        swipe?.();
        onMatchClick(matchId);
    };

    const theme = useTheme();

    const handlePlaceBet = () => {
        placeBet().then(swipe);
    };

    const bets = (
        <Stack>
            {activeOdds.size > 0 ? (
                Array.from(activeOdds.values()).map(betSlipOdd => {
                    return (
                        <Fragment key={betSlipOdd.odd.id}>
                            <Box px={2} py={1.5}>
                                <BetSlipOdd
                                    betSlipOdd={betSlipOdd}
                                    onMatchClick={handleMatchClick}
                                />
                            </Box>
                            <Divider />
                        </Fragment>
                    );
                })
            ) : (
                <Box
                    sx={{
                        height: 200,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 500,
                            color: 'textSecondary',
                        }}
                    >
                        Add your bet selections here.
                    </Typography>
                </Box>
            )}
        </Stack>
    );

    return (
        <Paper
            sx={{
                height: '100%',
                width: '100%',
            }}
        >
            <Stack sx={{ height: '100%' }}>
                <Box flexShrink={0}>
                    <BetSlipHeader {...props} />
                </Box>
                <Box flexShrink={0}>
                    <ColorfulDivider />
                </Box>
                {(!!activeOdds.size || (!isReview && !!minBetAmount)) && (
                    <Stack
                        flexShrink={0}
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                            p: 1,
                            background: theme.palette.background.default,
                        }}
                    >
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            {!isReview && !!minBetAmount && (
                                <Fragment>
                                    <Info size={20} color={theme.palette.secondary.main} />
                                    <Typography color="secondary" fontSize={12}>
                                        Min wager is
                                    </Typography>
                                    <Balance
                                        amount={minBetAmount}
                                        color="secondary"
                                        fontSize={12}
                                    />
                                </Fragment>
                            )}
                        </Stack>
                        {!!activeOdds.size && (
                            <Typography
                                fontSize={12}
                                sx={{
                                    'textDecoration': 'underline',
                                    'cursor': 'pointer',
                                    '&:hover': {
                                        textDecoration: 'none',
                                    },
                                }}
                                onClick={clearBetSlip}
                            >
                                Clear All
                            </Typography>
                        )}
                    </Stack>
                )}
                <Box sx={{ flex: '1 1 auto', overflow: 'hidden' }}>
                    <Box sx={{ overflow: 'auto', height: '100%' }}>{bets}</Box>
                </Box>
                <Box>
                    <Stack
                        spacing={2}
                        sx={{
                            p: 2,
                        }}
                    >
                        {!!calculatedOdds && (
                            <Stack spacing={1}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography fontWeight={700} color="white">
                                        Total Odds
                                    </Typography>
                                    <Typography fontWeight={600} color="secondary">
                                        {formatAmericanOdd(calculatedOdds.totalAmericanOdd)}
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography fontWeight={700} color="white">
                                        Total Stake
                                    </Typography>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography color="textSecondary">
                                            {activeOdds.size}x
                                        </Typography>
                                        <TotalStakeInput onChange={setTotalStake} />
                                    </Stack>
                                </Stack>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography fontWeight={700} color="white">
                                        Total Payout
                                    </Typography>
                                    <Balance amount={amountToWin} fontWeight={600} />
                                </Stack>
                            </Stack>
                        )}
                        {isPlaceBetButtonVisible && (
                            <Button
                                variant="contained"
                                disabled={isPlaceBetButtonDisabled}
                                fullWidth
                                sx={{
                                    background: `${theme.palette.primary.main} !important`,
                                    py: 1.5,
                                    color: 'white',
                                    borderRadius: 100,
                                    opacity: isPlaceBetButtonDisabled ? 0.5 : undefined,
                                }}
                                onClick={handlePlaceBet}
                            >
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <Typography fontWeight={600}>Place Bet</Typography>
                                    <Balance amount={totalStake} fontWeight={600} />
                                </Stack>
                            </Button>
                        )}
                    </Stack>
                </Box>
                {!isReview && <Missions />}
            </Stack>
        </Paper>
    );
});

export const BetSlip: FC<IBetSlipProps> = props => {
    const { isDesktop } = useScreenSize();

    return isDesktop ? (
        <BetSlipMain {...props} />
    ) : (
        <SwipeableBetSlipContainer>
            {(isOpen, swipe) => <BetSlipMain {...props} isOpen={isOpen} swipe={swipe} />}
        </SwipeableBetSlipContainer>
    );
};
