import { Box } from '@mui/material';
import { SuccessCheckMark } from '../assets/success-check-mark';
import { FC, PropsWithChildren } from 'react';
import { BrandTypography } from './brand-typography';

export const SuccessfullySentBanner: FC<PropsWithChildren<{ title?: string }>> = ({
    title,
    children,
}) => (
    <Box display="flex" justifyContent="center" alignItems="center" gap={2} flexDirection="column">
        <SuccessCheckMark />
        <BrandTypography
            variant="h4"
            mb={2}
            mx={4}
            sx={{
                textAlign: 'center',
                display: 'block',
                fontSize: { xs: '24px' },
                mx: { xs: 0 },
                mb: 2,
            }}
        >
            {title}
        </BrandTypography>
        <Box
            mx={4}
            sx={{ mx: { xs: 0 }, color: theme => theme.palette.primary[250] }}
            textAlign="center"
        >
            {children}
        </Box>
    </Box>
);
