import React from 'react';
import ILLUSTRARIONSVG from './empty.svg';
import './index.scss';
import Typography from '@mui/material/Typography';

export const EmptyList = () => {
    return (
        <div className="empty_list_container">
            <img alt="empty" src={ILLUSTRARIONSVG} className="illustration" />
            <Typography className="message">Nothing yet</Typography>
        </div>
    );
};
