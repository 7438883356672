import { observer } from 'mobx-react';
import { provide } from 'ioc';
import { PlayoffStore } from '../../../stores/playoff-store';
import { useEffect } from 'react';
import { useDependencies } from 'ioc';
import { PlayoffBracket } from './playoff-bracket';
import Box from '@mui/material/Box';
import { WinnersConsolationLadder } from './winners-consolation-ladder';
import { ConsolationLadder } from './consolation-ladder';

export const Playoff = provide([PlayoffStore])(
    observer(() => {
        const [{ init, gameLeaguePlayoffData }] = useDependencies(PlayoffStore);

        useEffect(() => {
            init();
        }, []);

        if (!gameLeaguePlayoffData) {
            return null;
        }

        return (
            <Box
                sx={{
                    overflowY: 'auto',
                }}
            >
                <Box sx={{ mb: 6 }}>
                    <PlayoffBracket />
                </Box>
                <Box sx={{ mb: 6 }}>
                    <WinnersConsolationLadder />
                </Box>
                <ConsolationLadder />
            </Box>
        );
    }),
);
