import { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import { provide, useDependencies } from 'ioc';
import { useScreenSize } from '../../../common/hooks/use-is-mobile';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Box } from '@mui/system';
import { AsyncList } from '../../../common/components/async-table-list/async-list';
import { leaguesListColumns } from '../../utils/leagues-list-columns';
import { LiveLeaguesStore } from '../../stores/live-leagues-store';
import { LiveLeaguesMobile } from './mobile/live-leagues-mobile';
import Button from '@mui/material/Button';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import { GameModel } from '../../../common/api/api';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { noop } from '../../../common/utils/noop';
import { NotificationsStore } from '../../../common/stores/notifications-store';
import { useNavigate } from 'react-router-dom';
import { LeaguesRoutes } from '../../utils/leagues-routes';
import { CustomTable } from '../../../common/components/custom-table/custom-table';
import { LeaguesStore } from '../../stores/leagues-store';
import { DebouncedSearch } from 'shared';
import { HandWaving } from '@phosphor-icons/react';

export const LiveLeagues = provide([LiveLeaguesStore])(
    observer(() => {
        const [
            { setStatusTab },
            { fetchLeagues, leaguesLoadStatus, joinLeague, searchLeagues, leagues },
            { notify },
        ] = useDependencies(LeaguesStore, LiveLeaguesStore, NotificationsStore);

        useEffect(() => {
            setStatusTab(LeaguesRoutes['Live Leagues']);
            fetchLeagues();
        }, []);

        const navigate = useNavigate();

        const { isDesktop } = useScreenSize();

        const handleJoinLeague = (leagueId: GameModel['id']) => {
            joinLeague(leagueId)
                .then(league => {
                    notify({
                        severity: 'info',
                        children: (
                            <Fragment>
                                <Typography mr={2} variant="body1">
                                    Joined league <strong>{league.name}</strong>!
                                </Typography>
                                <Typography variant="body2">
                                    You can find the league in&nbsp;
                                    <Link
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            // closeNotification();
                                            navigate(`../${LeaguesRoutes['My Leagues']}`);
                                        }}
                                    >
                                        My Leagues
                                    </Link>
                                    &nbsp;page.
                                </Typography>
                            </Fragment>
                        ),
                    });
                })
                .catch(noop);
        };

        return (
            <Box display="flex" flexDirection="column" alignItems={{ sm: 'start' }} gap={3}>
                <DebouncedSearch onSearch={searchLeagues} label="Search League" />
                <AsyncList
                    length={leagues.length}
                    loadStatus={leaguesLoadStatus}
                    onFetch={fetchLeagues}
                >
                    {setLastElement =>
                        !isDesktop ? (
                            <LiveLeaguesMobile
                                onJoinLeague={handleJoinLeague}
                                setLastElement={setLastElement}
                            />
                        ) : (
                            <CustomTable>
                                <TableBody>
                                    {leagues.map((league, leagueIndex) => (
                                        <TableRow
                                            key={league.id}
                                            ref={
                                                leagueIndex === leagues.length - 1
                                                    ? setLastElement
                                                    : undefined
                                            }
                                        >
                                            {leaguesListColumns.map((column, columnIndex) => (
                                                <TableCell key={columnIndex}>
                                                    {column.getComponent(league)}
                                                </TableCell>
                                            ))}
                                            <TableCell align="right">
                                                <Button
                                                    variant="contained"
                                                    endIcon={<HandWaving />}
                                                    onClick={() => handleJoinLeague(league.id)}
                                                >
                                                    <Typography>Join</Typography>
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </CustomTable>
                        )
                    }
                </AsyncList>
            </Box>
        );
    }),
);
