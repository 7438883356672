import { inject, injectable } from 'ioc';
import { makeAutoObservable, runInAction } from 'mobx';
import { apiClient } from '../../common/api/api-client';
import { NotificationModel } from '../../common/api/api';
import { LoadStatus } from '../../common/enums/load-status';
import { CommsHubStore } from '../../root/stores/comms-hub-store';

@injectable()
export class UserNotificationsStore {
    userNotifications: NotificationModel[] = [];
    badgeCount = 0;
    loadStatus = LoadStatus.None;

    @inject(CommsHubStore) private readonly commsHubStore!: CommsHubStore;

    constructor() {
        makeAutoObservable(this);
    }

    init = async () => {
        this.commsHubStore.connection.on('ReceiveNotificationCountAsync', badgeCount => {
            runInAction(() => {
                this.badgeCount = badgeCount;
            });
        });
    };

    fetchAll = async () => {
        runInAction(() => {
            this.loadStatus = LoadStatus.Loading;
        });

        try {
            const { items } = await apiClient.notificationsGET(0, 10);

            runInAction(() => {
                this.loadStatus = LoadStatus.Ok;
                this.userNotifications = items || [];
            });
        } catch (error) {
            runInAction(() => {
                this.loadStatus = LoadStatus.Error;
            });
        }
    };

    takeAction = async (notification: NotificationModel, decision: boolean) => {
        await apiClient.notificationsPATCH(notification.id, decision);

        // TODO: improve type
        runInAction(() => {
            (notification as any).hasAccept = false;
            (notification as any).message =
                `You ${decision ? 'accepted' : 'rejected'} @${notification.title} 's invite.`;
        });
    };
}
