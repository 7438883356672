const main = 'https://balancebet.com/';

export const ExternalDocsLinks = {
    termsAndConditions: `${main}terms-and-conditions`,
    privacyPolicy: `${main}privacy-policy`,
    contactUs: `${main}contact-us`,
    feedback: `${main}feedback`,
} as const;

export const ExternalSocialLinks = {
    facebook: 'https://www.facebook.com/balancebet',
    twitter: 'https://twitter.com/balancebet',
    instagram: 'https://www.instagram.com/balancebet',
} as const;
