import { FC, Fragment, PropsWithChildren, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import { CommsHubStore } from '../stores/comms-hub-store';

export const WithCommsHub: FC<PropsWithChildren> = observer(({ children }) => {
    const [{ init, dispose }] = useDependencies(CommsHubStore);

    useEffect(() => {
        init();

        return () => {
            dispose();
        };
    }, []);

    return <Fragment>{children}</Fragment>;
});
