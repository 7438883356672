import { FC } from 'react';
import { observer } from 'mobx-react';
import { GameLeaguePublicModel } from '../../../../common/api/api';
import { useDependencies } from 'ioc';
import { MyLeaguesStore } from '../../../stores/my-leagues-store';
import Box from '@mui/material/Box';
import { GameLeagueListItemMobile } from '../../../../common/components/game-leagues/mobile/game-league-list-item-mobile';
import { LastElementSetter } from '../../../../common/utils/types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { darken, Stack } from '@mui/material';
import { DoorOpen, UserPlus } from '@phosphor-icons/react';
import {
    customNegative,
    customSecondary,
} from '../../../../redesigned-modules/root/components/theme-provider/colors';
import { CustomIconButton } from '../../../../common/components/custom-icon-button/custom-icon-button';

interface MyLeaguesMobileProps {
    setLastElement: LastElementSetter;
    onLeave: (league: GameLeaguePublicModel) => void;
    onClick: (league: GameLeaguePublicModel) => void;
    onShare: (league: GameLeaguePublicModel) => void;
}

export const MyLeaguesMobile: FC<MyLeaguesMobileProps> = observer(
    ({ onClick, setLastElement, onShare, onLeave }) => {
        const [{ leagues }] = useDependencies(MyLeaguesStore);

        return (
            <Stack spacing={2}>
                {leagues.map((league, index) => (
                    <GameLeagueListItemMobile
                        key={league.id}
                        league={league}
                        ref={index === leagues.length - 1 ? setLastElement : undefined}
                        onClick={
                            league.hasStarted
                                ? () => {
                                      onClick(league);
                                  }
                                : undefined
                        }
                        actions={
                            league.hasStarted ? undefined : (
                                <Box display="flex" gap={2}>
                                    <Button
                                        onClick={event => {
                                            event.stopPropagation();
                                            onShare(league);
                                        }}
                                        endIcon={<UserPlus />}
                                        fullWidth
                                        variant="contained"
                                        sx={{
                                            'background': customSecondary[300],
                                            '&:hover': {
                                                background: darken(customSecondary[300], 0.1),
                                            },
                                            'color': 'black',
                                        }}
                                    >
                                        <Typography>Invite</Typography>
                                    </Button>
                                    <CustomIconButton
                                        color={customNegative['500']}
                                        onClick={event => {
                                            event.stopPropagation();
                                            onLeave(league);
                                        }}
                                    >
                                        <DoorOpen weight="fill" />
                                    </CustomIconButton>
                                </Box>
                            )
                        }
                    />
                ))}
            </Stack>
        );
    },
);
