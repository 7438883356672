export const SocialDiscord = () => {
    return (
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="50" height="50" rx="25" fill="#081217" />
            <path
                d="M32.2701 18.33C30.9401 17.71 29.5001 17.26 28.0001 17C27.9737 17.0004 27.9486 17.0112 27.9301 17.03C27.7501 17.36 27.5401 17.79 27.4001 18.12C25.8091 17.8802 24.1911 17.8802 22.6001 18.12C22.4601 17.78 22.2501 17.36 22.0601 17.03C22.0501 17.01 22.0201 17 21.9901 17C20.4901 17.26 19.0601 17.71 17.7201 18.33C17.7101 18.33 17.7001 18.34 17.6901 18.35C14.9701 22.42 14.2201 26.38 14.5901 30.3C14.5901 30.32 14.6001 30.34 14.6201 30.35C16.4201 31.67 18.1501 32.47 19.8601 33C19.8901 33.01 19.9201 33 19.9301 32.98C20.3301 32.43 20.6901 31.85 21.0001 31.24C21.0201 31.2 21.0001 31.16 20.9601 31.15C20.3901 30.93 19.8501 30.67 19.3201 30.37C19.2801 30.35 19.2801 30.29 19.3101 30.26C19.4201 30.18 19.5301 30.09 19.6401 30.01C19.6601 29.99 19.6901 29.99 19.7101 30C23.1501 31.57 26.8601 31.57 30.2601 30C30.2801 29.99 30.3101 29.99 30.3301 30.01C30.4401 30.1 30.5501 30.18 30.6601 30.27C30.7001 30.3 30.7001 30.36 30.6501 30.38C30.1301 30.69 29.5801 30.94 29.0101 31.16C28.9701 31.17 28.9601 31.22 28.9701 31.25C29.2901 31.86 29.6501 32.44 30.0401 32.99C30.0701 33 30.1001 33.01 30.1301 33C31.8501 32.47 33.5801 31.67 35.3801 30.35C35.4001 30.34 35.4101 30.32 35.4101 30.3C35.8501 25.77 34.6801 21.84 32.3101 18.35C32.3001 18.34 32.2901 18.33 32.2701 18.33ZM21.5201 27.91C20.4901 27.91 19.6301 26.96 19.6301 25.79C19.6301 24.62 20.4701 23.67 21.5201 23.67C22.5801 23.67 23.4201 24.63 23.4101 25.79C23.4101 26.96 22.5701 27.91 21.5201 27.91ZM28.4901 27.91C27.4601 27.91 26.6001 26.96 26.6001 25.79C26.6001 24.62 27.4401 23.67 28.4901 23.67C29.5501 23.67 30.3901 24.63 30.3801 25.79C30.3801 26.96 29.5501 27.91 28.4901 27.91Z"
                fill="#5865F2"
            />
        </svg>
    );
};
