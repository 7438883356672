import { FC, Fragment } from 'react';
import { observer } from 'mobx-react';
import { MatchWithOddsModelPagingModel } from '../../api/api';
import { LoadStatus } from '../../enums/load-status';
import { Box, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { EmptyList } from '../../../../components/EmptyList';
import { MatchOverview } from './match-overview';
import { MatchOverviewRowProps } from './match-overview-row';

export interface MatchOverviewsProps {
    matchOverviews: MatchWithOddsModelPagingModel;
    loadStatus: LoadStatus;
    onMatchClick: MatchOverviewRowProps['onMatchClick'];
}

export const MatchOverviews: FC<MatchOverviewsProps> = observer(props => {
    const { matchOverviews, loadStatus, onMatchClick } = props;

    if (loadStatus === LoadStatus.Loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress sx={{ mt: 10 }} />
            </Box>
        );
    }

    const matchOverviewItems = matchOverviews.items ?? [];

    return (
        <Fragment>
            <Stack spacing={2}>
                {matchOverviewItems.map(matchOverview => {
                    return (
                        <MatchOverview
                            key={matchOverview.match.id}
                            onMatchClick={onMatchClick}
                            matchOverview={matchOverview}
                        />
                    );
                })}
            </Stack>
            {loadStatus === LoadStatus.Ok && matchOverviewItems.length === 0 ? (
                <Box my={5}>
                    <EmptyList />
                </Box>
            ) : null}
        </Fragment>
    );
});
