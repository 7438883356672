export const XIcon = () => (
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g filter="url(#filter0_bi_351_24682)">
            <mask id="path-1-inside-1_351_24682" fill="white">
                <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" />
            </mask>
            <path
                d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
                fill="#58F081"
            />
            <path
                d="M0 0H40H0ZM40 21C40 32.0457 31.0457 41 20 41C8.9543 41 0 32.0457 0 21V20C0 30.4934 8.9543 39 20 39C31.0457 39 40 30.4934 40 20V21ZM0 40V0V40ZM40 0V40V0Z"
                fill="white"
                fillOpacity="0.1"
                mask="url(#path-1-inside-1_351_24682)"
            />
            <g clipPath="url(#clip0_351_24682)">
                <path
                    d="M25.75 10.9375H28.8175L22.1175 18.615L30 29.0625H23.8287L18.995 22.725L13.4637 29.0625H10.395L17.5612 20.85L10 10.9375H16.3288L20.6975 16.7287L25.75 10.9375ZM24.675 27.2225H26.375L15.4037 12.6812H13.5812L24.675 27.2225Z"
                    fill="#3A3AD2"
                />
            </g>
        </g>
        <defs>
            <filter
                id="filter0_bi_351_24682"
                x="-40"
                y="-40"
                width="120"
                height="120"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
                <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_351_24682"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_351_24682"
                    result="shape"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="32"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect2_innerShadow_351_24682"
                />
                <feOffset dy="8" />
                <feGaussianBlur stdDeviation="32" />
                <feComposite
                    in2="hardAlpha"
                    operator="arithmetic"
                    k2="-1"
                    k3="1"
                />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"
                />
                <feBlend
                    mode="plus-lighter"
                    in2="shape"
                    result="effect2_innerShadow_351_24682"
                />
            </filter>
            <clipPath id="clip0_351_24682">
                <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(10 10)"
                />
            </clipPath>
        </defs>
    </svg>
);
