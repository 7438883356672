import { injectable } from 'ioc';
import { makeAutoObservable, runInAction } from 'mobx';
import { apiClient } from '../../common/api/api-client';
import { GameLeaguePublicModel } from '../../common/api/api';
import { ParticipantsGetter } from '../../participants/utils/types';
import { LeaguesRoutes } from '../utils/leagues-routes';

@injectable()
export class LeaguesStore {
    statusTab = LeaguesRoutes['My Leagues'];
    isCreatingLeague = false;
    invitedLeague?: GameLeaguePublicModel;
    leagueForParticipants?: GameLeaguePublicModel;

    constructor() {
        makeAutoObservable(this);
    }

    initInvitedLeague = async (leagueId: number, token?: string) => {
        try {
            const league = await apiClient.getGameLeague(leagueId, token);

            runInAction(() => {
                this.invitedLeague = league;
            });
        } catch {
            //
        }
    };

    setStatusTab = (tab: LeaguesRoutes) => {
        this.statusTab = tab;
    };

    joinInvitedLeague = async (leagueId: number, token?: string) => {
        await apiClient.participationPOST(leagueId, token);
    };

    cancelGameInvitation = () => {
        this.invitedLeague = undefined;
    };

    showCreateLeague = () => {
        this.isCreatingLeague = true;
    };

    hideCreateLeague = () => {
        this.isCreatingLeague = false;
    };

    showParticipants = (league: GameLeaguePublicModel) => {
        this.leagueForParticipants = league;
    };

    hideParticipants = () => {
        this.leagueForParticipants = undefined;
    };

    getParticipants: ParticipantsGetter = async () => {
        if (!this.leagueForParticipants) {
            return [];
        }

        try {
            const { items: participants } = await apiClient.getGameLeagueParticipants(
                this.leagueForParticipants.id,
                undefined,
                undefined,
            );

            return participants ?? [];
        } catch {
            return [];
        }
    };
}
