import { observer } from 'mobx-react';
import { Box } from '@mui/system';
import { useDependencies } from 'ioc';
import { ProfileStore } from '../stores/profile-store';
import { provide } from 'ioc';
import { useIsMobile } from '../../common/hooks/use-is-mobile';
import { Avatar, InputAdornment, MenuItem, Select } from '@mui/material';
import Button from '@mui/material/Button';
import { ColoredBorder } from '../../common/components/colored-border/colored-border';
import { useEffect } from 'react';
import { AuthStore } from '../../common/stores/auth-store';
import { getInputProps } from '../../auth/utils/get-input-props';
import TextField from '@mui/material/TextField';
import { LoadStatus } from '../../common/enums/load-status';
import { PlayerPrivacySetting } from '../../common/api/api';
import { FriendsContent } from '../../friends/components/friends-content';
import Typography from '@mui/material/Typography';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ChangePassword } from './change-password';

const Profile = provide([ProfileStore])(
    observer(() => {
        const [{ me }, { formState, init, profileDataChangeLoadStatus, save }] = useDependencies(
            AuthStore,
            ProfileStore,
        );

        useEffect(() => {
            init();
        }, []);

        const isMobile = useIsMobile();
        const navigate = useNavigate();

        const { username, fullName, phoneNumber, privacySetting } = formState.$;

        return (
            <Box height="100%" px={{ xs: 2, sm: 15 }} py={{ xs: 2, sm: 4 }}>
                <Box height="100%" display="flex" justifyContent="space-between" gap={15}>
                    <Box flex="3 0 0">
                        <Box width="100%" display="flex" flexDirection="column" alignItems="center">
                            <ColoredBorder borderSize="2px" sx={{ borderRadius: 1000, mb: 3 }}>
                                <Avatar
                                    src={me?.avatarUrl}
                                    sx={{
                                        height: { xs: 80, sm: 240 },
                                        width: { xs: 80, sm: 240 },
                                    }}
                                />
                            </ColoredBorder>
                            <Button
                                sx={{ alignSelf: 'end', mb: 3 }}
                                onClick={() => {
                                    navigate('change-password');
                                }}
                            >
                                Change Password
                            </Button>
                            <Box
                                component="form"
                                display="flex"
                                flexDirection="column"
                                width="100%"
                                onSubmit={event => {
                                    event.preventDefault();
                                    save();
                                }}
                            >
                                <TextField
                                    label="Username"
                                    {...getInputProps(username)}
                                    sx={{ mb: 2 }}
                                    InputProps={undefined}
                                    disabled
                                />
                                <TextField
                                    label="Full Name"
                                    {...getInputProps(fullName)}
                                    sx={{ mb: 2 }}
                                />
                                <Typography mb={1} variant="body1" color="textSecondary">
                                    I want my full name to be visible to:
                                </Typography>
                                <Select
                                    sx={{ mb: 2 }}
                                    value={privacySetting.value}
                                    onChange={event => {
                                        privacySetting.onChange(
                                            event.target.value as PlayerPrivacySetting,
                                        );
                                    }}
                                >
                                    {Object.values(PlayerPrivacySetting).map(value => {
                                        return (
                                            <MenuItem key={value} value={value}>
                                                {value}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <TextField
                                    label="Phone Number"
                                    {...getInputProps(phoneNumber)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">+</InputAdornment>
                                        ),
                                    }}
                                    sx={{ mb: 3 }}
                                />
                                <Button
                                    size="large"
                                    variant="contained"
                                    type="submit"
                                    disabled={
                                        formState.hasError ||
                                        profileDataChangeLoadStatus === LoadStatus.Loading
                                    }
                                >
                                    Save Changes
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    {!isMobile && (
                        <Box flex="9 0 0">
                            <FriendsContent />
                        </Box>
                    )}
                </Box>
            </Box>
        );
    }),
);

export const ProfilePage = () => {
    return (
        <Routes>
            <Route path="" element={<Profile />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="*" element={<Navigate to="" />} />
        </Routes>
    );
};
