import { observer } from 'mobx-react';
import { provide, useDependencies } from 'ioc';
import { LeagueContentStore } from '../../stores/league-content-store';
import {
    Navigate,
    Link as RouterLink,
    Route,
    Routes,
    useNavigate,
    useParams,
} from 'react-router-dom';
import Box from '@mui/material/Box';
import { getLeagueContentRoutes } from '../../utils/league-content-routes';
import { Tab, Tabs } from '@mui/material';
import { BackButton } from '../../../common/components/back-button/back-button';
import { Fragment, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { StandingsMatchupPage } from '../standings-matchup-page/standings-matchup-page';
import { useScreenSize } from '../../../common/hooks/use-is-mobile';
import { asParentPath } from '../../../common/utils/as-parent-path';
import { LoadStatus } from '../../../common/enums/load-status';
import { formatDateRange } from '../../../common/utils/format-date-range';
import { AppContentContainer } from '../../../redesigned-modules/common/components/app-content-container/app-content-container';
import { customNeutral } from '../../../redesigned-modules/root/components/theme-provider/colors';
import { GameLeagueStatusEnum } from '../../../common/api/api';

const LeagueContentPageMain = provide([LeagueContentStore])(
    observer(() => {
        const [{ init, league, leagueLoadStatus }] = useDependencies(LeagueContentStore);

        const navigate = useNavigate();
        const { tab, leagueId } = useParams();

        useEffect(() => {
            if (leagueId) {
                init(+leagueId);
            }
        }, []);

        const { isMobile } = useScreenSize();

        if (!league) {
            return null;
        }

        const routes = getLeagueContentRoutes(league.status === GameLeagueStatusEnum.Completed);

        if (!routes.find(({ pathname }) => pathname === tab)) {
            return <Navigate to={`../${routes[0].pathname}`} replace />;
        }

        const tabs = (
            <Tabs value={tab} textColor="secondary" indicatorColor="secondary">
                {routes.map(({ name, pathname, icon }) => {
                    return (
                        <Tab
                            icon={icon}
                            iconPosition="start"
                            sx={{ minWidth: 'unset', minHeight: 'unset' }}
                            key={name}
                            label={isMobile ? undefined : name}
                            component={RouterLink}
                            to={`../${pathname}`}
                            value={pathname}
                        />
                    );
                })}
            </Tabs>
        );

        return (
            <AppContentContainer>
                <Box mb={2}>
                    <Box mb={2}>
                        <BackButton label="Leagues" onClick={() => navigate('../..')} />
                    </Box>
                    <Typography variant="h4" color="white" fontWeight={900} sx={{ mb: 2 }}>
                        {league?.name}
                    </Typography>
                    <Typography
                        fontWeight={600}
                        sx={{ mb: { xs: 1, sm: 2, md: 3 } }}
                        color={customNeutral[100]}
                    >
                        {league?.startDate &&
                            league?.endDate &&
                            formatDateRange(league.startDate, league.endDate)}
                    </Typography>
                    {tabs}
                </Box>
                {leagueLoadStatus === LoadStatus.Ok &&
                    routes.map(({ pathname, Component }) => {
                        if (tab === pathname) {
                            return <Component key={pathname} />;
                        }

                        return <Fragment key={pathname} />;
                    })}
            </AppContentContainer>
        );
    }),
);

export const LeagueContentPage = () => {
    return (
        <Routes>
            <Route path="standings/:gameId" element={<StandingsMatchupPage />} />
            <Route path={asParentPath(':tab?')} element={<LeagueContentPageMain />} />
        </Routes>
    );
};
