// ClashDisplay fonts
import ClashDisplayExtralight from '../../../../../fonts/clash-display/ClashDisplay-Extralight.ttf';
import ClashDisplayLight from '../../../../../fonts/clash-display/ClashDisplay-Light.ttf';
import ClashDisplayRegular from '../../../../../fonts/clash-display/ClashDisplay-Regular.ttf';
import ClashDisplayMedium from '../../../../../fonts/clash-display/ClashDisplay-Medium.ttf';
import ClashDisplaySemibold from '../../../../../fonts/clash-display/ClashDisplay-Semibold.ttf';
import ClashDisplayBold from '../../../../../fonts/clash-display/ClashDisplay-Bold.ttf';
import ClashDisplayVariable from '../../../../../fonts/clash-display/ClashDisplay-Variable.ttf';
// Gilroy fonts
import GilroyLight from '../../../../../fonts/glory/Gilroy-Light.ttf';
import GilroyRegular from '../../../../../fonts/glory/Gilroy-Regular.ttf';
import GilroyMedium from '../../../../../fonts/glory/Gilroy-Medium.ttf';
import GilroyBold from '../../../../../fonts/glory/Gilroy-Bold.ttf';
import GilroyExtraBold from '../../../../../fonts/glory/Gilroy-ExtraBold.ttf';

export const fontFaceDeclarations = `
@font-face {
    font-family: 'ClashDisplay-Extralight';
    src: url(${ClashDisplayExtralight}) format('truetype');
    font-weight: 200;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: 'ClashDisplay-Light';
    src: url(${ClashDisplayLight}) format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: 'ClashDisplay-Regular';
    src: url(${ClashDisplayRegular}) format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: 'ClashDisplay-Medium';
    src: url(${ClashDisplayMedium}) format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: 'ClashDisplay-Semibold';
    src: url(${ClashDisplaySemibold}) format('truetype');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: 'ClashDisplay-Bold';
    src: url(${ClashDisplayBold}) format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: 'ClashDisplay-Variable';
    src: url(${ClashDisplayVariable}) format('truetype');
    font-weight: 200 700;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: url(${GilroyLight}) format('truetype');
}
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url(${GilroyRegular}) format('truetype');
}
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: url(${GilroyMedium}) format('truetype');
}
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url(${GilroyBold}) format('truetype');
}
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-display: swap;
    font-weight: 900;
    src: url(${GilroyExtraBold}) format('truetype');
}
`;
