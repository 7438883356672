import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { provide, useDependencies } from 'ioc';
import { StandingsStore } from '../../../stores/standings-store';
import Box from '@mui/material/Box';
import { StandingsTable } from './standings-table';
import { Stack } from '@mui/material';
import { Scoreboard } from './scoreboard';

export const Standings = provide([StandingsStore])(
    observer(() => {
        const [{ init, standings }] = useDependencies(StandingsStore);

        useEffect(() => {
            init();
        }, [init]);

        if (!standings) {
            return null;
        }

        return (
            <Box display="flex" flexDirection="column" gap={4}>
                <Stack
                    spacing={3}
                    direction={{ xs: 'column', md: 'row' }}
                    alignItems={{ xs: 'stretch', md: 'start' }}
                >
                    {standings.conferences?.map(conference => (
                        <StandingsTable key={conference.id} conference={conference} />
                    ))}
                </Stack>
                <Scoreboard />
            </Box>
        );
    }),
);
