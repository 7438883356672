import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import { FC, useEffect, useState } from 'react';
import { CardActionArea, Paper, useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Balance } from '../../../../../../components/smallComponents';
import { Bet } from '../../../../../common/components/bet/bet';
import { GameMatchupProps } from '../game-matchup';

export const GameMatchupMobile: FC<GameMatchupProps> = observer(({ playersWithBets }) => {
    const [activePlayerId, setActivePlayerId] = useState<number | null>(null);
    const theme = useTheme();

    useEffect(() => {
        if (playersWithBets.length) {
            setActivePlayerId(playersWithBets[0].player.id);
        }
    }, [playersWithBets]);

    const betsToShow =
        activePlayerId !== null
            ? (playersWithBets.find(({ player }) => player.id === activePlayerId)?.bets ?? [])
            : [];

    return (
        <Paper sx={{ borderRadius: 0 }}>
            <Box display="flex" gap={4} justifyContent="space-between" p={3}>
                {playersWithBets.map(({ player }) => (
                    <Box key={player.id} flex="1 1 0">
                        <Card
                            sx={{
                                height: '100%',
                                border: '4px solid',
                                borderColor:
                                    activePlayerId === player.id
                                        ? theme.palette.primary.main
                                        : 'transparent',
                            }}
                        >
                            <CardActionArea onClick={() => setActivePlayerId(player.id)}>
                                <Box
                                    p={1.5}
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    gap={2}
                                    justifyContent="space-between"
                                >
                                    <Avatar src={player.avatarUrl} />
                                    <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                                        {player.username}
                                    </Typography>
                                    <Balance
                                        amount={player.balance}
                                        textProps={{
                                            fontWeight: 700,
                                        }}
                                    />
                                </Box>
                            </CardActionArea>
                        </Card>
                    </Box>
                ))}
                {playersWithBets.length === 1 && (
                    <Box flex="1 1 0">
                        <Card sx={{ height: '100%' }}>
                            <Box
                                height="100%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Typography variant="h3">BYE</Typography>
                            </Box>
                        </Card>
                    </Box>
                )}
            </Box>
            <Box display="flex" flexDirection="column" gap={1}>
                {betsToShow.map((bet, index) => {
                    return (
                        <Bet
                            key={`${activePlayerId}-${index}`}
                            bet={bet}
                            sx={{
                                borderRadius: 0,
                                border: '1px solid #dedede',
                            }}
                        />
                    );
                })}
            </Box>
        </Paper>
    );
});
