import { FC, PropsWithChildren } from 'react';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';

export const Score: FC<PropsWithChildren> = ({ children }) => {
    const theme = useTheme();

    return (
        <Typography
            fontSize={18}
            fontWeight={700}
            sx={{
                background: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                backgroundClip: 'text',
                color: 'transparent',
                WebkitTextStroke: `0.5px ${theme.palette.secondary.main}`,
                textShadow: `0px 1px 4px ${theme.palette.secondary.main}`,
            }}
        >
            {children}
        </Typography>
    );
};
