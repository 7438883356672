export const getOrdinalSuffix = (num: number): string => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = num % 100;

    if (v >= 11 && v <= 13) {
        return `${num}th`;
    }

    return `${num}${suffixes[num % 10 < 4 ? num % 10 : 0]}`;
};
