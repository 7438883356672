import { FC } from 'react';
import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import Box from '@mui/material/Box';
import { GameLeagueListItemMobile } from '../../../../common/components/game-leagues/mobile/game-league-list-item-mobile';
import { LastElementSetter } from '../../../../common/utils/types';
import { LeaguesHistoryStore } from '../../../stores/leagues-history-store';
import { GameLeaguePublicModel } from '../../../../common/api/api';

interface LiveLeaguesMobileProps {
    setLastElement: LastElementSetter;
    onClick: (league: GameLeaguePublicModel) => void;
}

export const LeaguesHistoryMobile: FC<LiveLeaguesMobileProps> = observer(
    ({ setLastElement, onClick }) => {
        const [{ leagues }] = useDependencies(LeaguesHistoryStore);

        return (
            <Box display="flex" flexDirection="column" gap={2}>
                {leagues.map((league, index) => (
                    <GameLeagueListItemMobile
                        onClick={() => onClick(league)}
                        key={league.id}
                        league={league}
                        ref={index === leagues.length - 1 ? setLastElement : undefined}
                    />
                ))}
            </Box>
        );
    },
);
