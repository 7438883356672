import { observer } from 'mobx-react';
import { provide } from 'ioc';
import { UserNotificationsStore } from '../stores/user-notifications-store';
import { Fragment, useState, MouseEvent, useEffect, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import { useIsMobile } from '../../common/hooks/use-is-mobile';
import { Popover } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { UserNotificationsContent } from './user-notifications-content';
import { useDependencies } from 'ioc';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import NotificationSound from '../../../assets/sounds/notification-sound.wav';
import { noop } from '../../common/utils/noop';
import { Bell as BellIcon } from '@phosphor-icons/react';

export const UserNotifications = provide([UserNotificationsStore])(
    observer(() => {
        const [{ init, badgeCount, fetchAll }] = useDependencies(UserNotificationsStore);

        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const isOpen = !!anchorEl;

        const isMobile = useIsMobile();

        const audioPlayer = useRef<HTMLAudioElement>(null);

        useEffect(() => {
            init();
        }, []);

        const prevBadgeCount = useRef(badgeCount);

        useEffect(() => {
            if (badgeCount > prevBadgeCount.current) {
                audioPlayer.current?.play().catch(noop);
            }

            prevBadgeCount.current = badgeCount;
        }, [badgeCount, audioPlayer.current]);

        const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
            fetchAll();
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <Fragment>
                <IconButton onClick={handleClick}>
                    <Badge badgeContent={badgeCount} color="primary">
                        <BellIcon size={32} weight="bold" />
                    </Badge>
                </IconButton>
                {isMobile && (
                    <Dialog open={isOpen} fullScreen>
                        <UserNotificationsContent onClose={handleClose} />
                    </Dialog>
                )}
                {!isMobile && (
                    <Popover
                        open={isOpen}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <UserNotificationsContent />
                    </Popover>
                )}
                <audio ref={audioPlayer} src={NotificationSound} />
            </Fragment>
        );
    }),
);
