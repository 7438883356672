export const PlayoffBracketImage = () => {
    return (
        <svg
            width="271"
            height="260"
            viewBox="0 0 271 260"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.7" filter="url(#filter0_f_387_36439)">
                <path
                    d="M201.933 244.396C231.319 244.396 255.142 220.574 255.142 191.187C255.142 161.801 231.319 137.978 201.933 137.978C172.546 137.978 148.724 161.801 148.724 191.187C148.724 220.574 172.546 244.396 201.933 244.396Z"
                    fill="url(#paint0_linear_387_36439)"
                />
                <path
                    d="M201.934 242.274C230.148 242.274 253.02 219.401 253.02 191.187C253.02 162.973 230.148 140.101 201.934 140.101C173.719 140.101 150.847 162.973 150.847 191.187C150.847 219.401 173.719 242.274 201.934 242.274Z"
                    fill="url(#paint1_linear_387_36439)"
                />
                <mask
                    id="mask0_387_36439"
                    // style="mask-type:luminance"
                    maskUnits="userSpaceOnUse"
                    x="150"
                    y="140"
                    width="104"
                    height="103"
                >
                    <path
                        d="M201.934 242.274C230.149 242.274 253.021 219.401 253.021 191.187C253.021 162.973 230.149 140.101 201.934 140.101C173.72 140.101 150.848 162.973 150.848 191.187C150.848 219.401 173.72 242.274 201.934 242.274Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask0_387_36439)">
                    <path
                        d="M206.214 149.774C209.661 150.957 213.169 151.815 216.736 152.334C219.738 152.771 222.658 151.133 223.897 148.363L229.557 135.688L202.414 129.123L201.655 142.969C201.488 146.001 203.345 148.788 206.214 149.778V149.774Z"
                        fill="url(#paint2_linear_387_36439)"
                    />
                    <path
                        d="M175.675 158.877C178.951 157.278 182.039 155.404 184.924 153.247C187.356 151.433 188.265 148.209 187.18 145.374L182.219 132.408L158.382 146.961L167.635 157.286C169.659 159.546 172.944 160.206 175.671 158.877H175.675Z"
                        fill="url(#paint3_linear_387_36439)"
                    />
                    <path
                        d="M160.522 186.912C161.705 183.464 162.563 179.957 163.081 176.389C163.519 173.388 161.881 170.468 159.111 169.228L146.436 163.568L139.871 190.711L153.717 191.47C156.748 191.637 159.535 189.781 160.526 186.912H160.522Z"
                        fill="url(#paint4_linear_387_36439)"
                    />
                    <path
                        d="M169.629 217.446C168.03 214.17 166.156 211.083 163.999 208.197C162.185 205.766 158.961 204.857 156.126 205.941L143.16 210.903L157.713 234.739L168.038 225.486C170.298 223.462 170.958 220.177 169.629 217.45V217.446Z"
                        fill="url(#paint5_linear_387_36439)"
                    />
                    <path
                        d="M197.659 232.6C194.212 231.416 190.704 230.559 187.137 230.04C184.135 229.602 181.215 231.24 179.976 234.01L174.316 246.686L201.458 253.25L202.217 239.405C202.385 236.373 200.528 233.586 197.659 232.595V232.6Z"
                        fill="url(#paint6_linear_387_36439)"
                    />
                    <path
                        d="M228.194 223.492C224.918 225.092 221.831 226.965 218.945 229.122C216.514 230.936 215.605 234.161 216.689 236.995L221.651 249.962L245.487 235.408L236.234 225.083C234.21 222.823 230.925 222.163 228.198 223.492H228.194Z"
                        fill="url(#paint7_linear_387_36439)"
                    />
                    <path
                        d="M243.347 195.462C242.164 198.91 241.306 202.417 240.788 205.985C240.35 208.986 241.988 211.906 244.758 213.146L257.433 218.806L263.998 191.663L250.152 190.904C247.121 190.737 244.334 192.593 243.343 195.462H243.347Z"
                        fill="url(#paint8_linear_387_36439)"
                    />
                    <path
                        d="M234.244 164.928C235.843 168.204 237.717 171.291 239.874 174.177C241.688 176.608 244.912 177.517 247.747 176.432L260.713 171.471L246.16 147.634L235.835 156.888C233.575 158.912 232.915 162.196 234.244 164.923V164.928Z"
                        fill="url(#paint9_linear_387_36439)"
                    />
                </g>
                <path
                    opacity="0.2"
                    d="M201.933 227.107C221.771 227.107 237.853 211.025 237.853 191.187C237.853 171.349 221.771 155.267 201.933 155.267C182.095 155.267 166.013 171.349 166.013 191.187C166.013 211.025 182.095 227.107 201.933 227.107Z"
                    fill="#1C1C64"
                />
                <path
                    d="M201.641 227.069C220.787 227.069 236.309 211.547 236.309 192.401C236.309 173.254 220.787 157.733 201.641 157.733C182.494 157.733 166.973 173.254 166.973 192.401C166.973 211.547 182.494 227.069 201.641 227.069Z"
                    fill="url(#paint10_linear_387_36439)"
                />
                <path
                    d="M217.596 202.597C218.673 198.151 216.563 194.695 210.581 192.662L210.714 192.105C216.409 192.894 218.75 190.518 219.693 186.625C220.902 181.621 218.24 178.032 211.568 176.42L192.105 171.711L184.348 204.034L204.364 208.875C212.151 210.757 216.306 208.172 217.652 202.61L217.596 202.597ZM198.275 180.266L209.398 182.954C212.168 183.623 213.013 184.995 212.489 187.204C212.489 187.195 212.494 187.187 212.498 187.178L212.485 187.234C212.485 187.234 212.485 187.217 212.489 187.204C211.945 189.408 211.036 189.819 207.918 189.069L196.796 186.38L198.275 180.266ZM205.92 202.186L193.687 199.227L195.299 192.555L207.545 195.458C210.324 196.131 211.169 197.512 210.633 199.737C210.097 201.963 208.699 202.859 205.92 202.186Z"
                    fill="url(#paint11_linear_387_36439)"
                />
                <path
                    d="M158.209 210.23C199.053 210.23 232.163 177.12 232.163 136.276C232.163 95.4322 199.053 62.3218 158.209 62.3218C117.365 62.3218 84.2549 95.4322 84.2549 136.276C84.2549 177.12 117.365 210.23 158.209 210.23Z"
                    fill="url(#paint12_linear_387_36439)"
                />
                <path
                    d="M158.209 207.284C197.425 207.284 229.217 175.493 229.217 136.276C229.217 97.0593 197.425 65.2678 158.209 65.2678C118.992 65.2678 87.2002 97.0593 87.2002 136.276C87.2002 175.493 118.992 207.284 158.209 207.284Z"
                    fill="url(#paint13_linear_387_36439)"
                />
                <mask
                    id="mask1_387_36439"
                    // style="mask-type:luminance"
                    maskUnits="userSpaceOnUse"
                    x="87"
                    y="65"
                    width="143"
                    height="143"
                >
                    <path
                        d="M158.208 207.284C197.424 207.284 229.216 175.493 229.216 136.276C229.216 97.0593 197.424 65.2678 158.208 65.2678C118.991 65.2678 87.1992 97.0593 87.1992 136.276C87.1992 175.493 118.991 207.284 158.208 207.284Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask1_387_36439)">
                    <path
                        d="M137.486 82.2438C142.503 81.5492 147.391 80.4043 152.142 78.8049C156.138 77.4585 158.728 73.5908 158.522 69.38L157.575 50.1057L119.798 59.0246L127.559 76.6652C129.257 80.5287 133.309 82.8184 137.49 82.2395L137.486 82.2438Z"
                        fill="url(#paint14_linear_387_36439)"
                    />
                    <path
                        d="M105.351 112.727C108.409 108.688 111.054 104.425 113.284 99.9316C115.158 96.1539 114.257 91.5873 111.131 88.7572L96.8311 75.7991L76.4248 108.821L94.387 115.806C98.319 117.336 102.804 116.089 105.351 112.727Z"
                        fill="url(#paint15_linear_387_36439)"
                    />
                    <path
                        d="M104.181 157C103.486 151.983 102.342 147.095 100.742 142.344C99.3957 138.347 95.528 135.757 91.3173 135.963L72.043 136.911L80.9619 174.687L98.6025 166.926C102.466 165.228 104.756 161.176 104.177 156.995L104.181 157Z"
                        fill="url(#paint16_linear_387_36439)"
                    />
                    <path
                        d="M134.66 189.134C130.621 186.076 126.358 183.431 121.865 181.201C118.087 179.327 113.52 180.228 110.69 183.354L97.7322 197.654L130.754 218.06L137.739 200.098C139.269 196.166 138.022 191.681 134.66 189.134Z"
                        fill="url(#paint17_linear_387_36439)"
                    />
                    <path
                        d="M178.933 190.304C173.916 190.999 169.027 192.144 164.276 193.743C160.28 195.09 157.69 198.957 157.896 203.168L158.844 222.443L196.62 213.524L188.859 195.883C187.161 192.02 183.109 189.73 178.928 190.309L178.933 190.304Z"
                        fill="url(#paint18_linear_387_36439)"
                    />
                    <path
                        d="M211.067 159.826C208.009 163.865 205.364 168.127 203.134 172.621C201.26 176.399 202.16 180.965 205.286 183.795L219.587 196.753L239.993 163.732L222.031 156.747C218.099 155.216 213.614 156.464 211.067 159.826Z"
                        fill="url(#paint19_linear_387_36439)"
                    />
                    <path
                        d="M212.238 115.548C212.932 120.565 214.077 125.453 215.677 130.204C217.023 134.201 220.891 136.791 225.102 136.585L244.376 135.637L235.457 97.8604L217.816 105.622C213.953 107.32 211.663 111.372 212.242 115.552L212.238 115.548Z"
                        fill="url(#paint20_linear_387_36439)"
                    />
                    <path
                        d="M181.759 83.4147C185.798 86.472 190.06 89.1177 194.554 91.3474C198.332 93.2212 202.898 92.3208 205.728 89.1949L218.686 74.8946L185.665 54.4883L178.68 72.4505C177.149 76.3825 178.397 80.8677 181.759 83.4147Z"
                        fill="url(#paint21_linear_387_36439)"
                    />
                </g>
                <path
                    opacity="0.2"
                    d="M158.21 186.204C185.785 186.204 208.139 163.851 208.139 136.276C208.139 108.701 185.785 86.3469 158.21 86.3469C130.635 86.3469 108.281 108.701 108.281 136.276C108.281 163.851 130.635 186.204 158.21 186.204Z"
                    fill="#2E2EA5"
                />
                <path
                    d="M158.608 186.153C185.221 186.153 206.796 164.579 206.796 137.965C206.796 111.352 185.221 89.7773 158.608 89.7773C131.994 89.7773 110.42 111.352 110.42 137.965C110.42 164.579 131.994 186.153 158.608 186.153Z"
                    fill="url(#paint22_linear_387_36439)"
                />
                <path
                    d="M184.798 140.577C183.336 134.385 178.555 131.431 169.859 132.666L169.674 131.894C177.23 129.291 178.641 124.874 177.358 119.459C175.712 112.495 170.159 109.721 160.871 111.912L133.784 118.305L144.479 163.251L172.337 156.674C183.173 154.114 186.702 148.299 184.875 140.56L184.798 140.577ZM146.811 125.033L162.291 121.38C166.146 120.471 168.058 121.641 168.795 124.707C168.795 124.694 168.791 124.681 168.787 124.669L168.804 124.746C168.804 124.746 168.795 124.72 168.795 124.707C169.507 127.782 168.637 128.866 164.302 129.896L148.822 133.549L146.811 125.037V125.033ZM170.069 147.403L153.042 151.421L150.85 142.133L167.856 138.034C171.724 137.121 173.641 138.304 174.37 141.4C175.099 144.496 173.937 146.486 170.069 147.399V147.403Z"
                    fill="url(#paint23_linear_387_36439)"
                />
                <path
                    d="M99.1251 183.25C145.586 183.25 183.25 145.586 183.25 99.1251C183.25 52.6641 145.586 15 99.1251 15C52.6641 15 15 52.6641 15 99.1251C15 145.586 52.6641 183.25 99.1251 183.25Z"
                    fill="url(#paint24_linear_387_36439)"
                />
                <path
                    d="M99.1252 179.897C143.734 179.897 179.897 143.734 179.897 99.1252C179.897 54.5161 143.734 18.3533 99.1252 18.3533C54.5161 18.3533 18.3533 54.5161 18.3533 99.1252C18.3533 143.734 54.5161 179.897 99.1252 179.897Z"
                    fill="url(#paint25_linear_387_36439)"
                />
                <mask
                    id="mask2_387_36439"
                    // style="mask-type:luminance"
                    maskUnits="userSpaceOnUse"
                    x="18"
                    y="18"
                    width="162"
                    height="162"
                >
                    <path
                        d="M99.1269 179.897C143.736 179.897 179.899 143.734 179.899 99.1252C179.899 54.5161 143.736 18.3533 99.1269 18.3533C54.5178 18.3533 18.355 54.5161 18.355 99.1252C18.355 143.734 54.5178 179.897 99.1269 179.897Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask2_387_36439)">
                    <path
                        d="M75.5548 37.6704C81.2621 36.8772 86.8235 35.5779 92.2221 33.7598C96.7673 32.229 99.7174 27.8296 99.4815 23.04L98.401 1.11572L55.4272 11.261L64.2561 31.3286C66.19 35.7194 70.7995 38.3265 75.5506 37.6704H75.5548Z"
                        fill="url(#paint26_linear_387_36439)"
                    />
                    <path
                        d="M39 72.3384C42.4775 67.7417 45.4877 62.8921 48.0218 57.7851C50.1529 53.4886 49.1281 48.2916 45.5734 45.0714L29.3093 30.3337L6.09863 67.8961L26.5307 75.8417C31.003 77.5826 36.1057 76.1675 39 72.3384Z"
                        fill="url(#paint27_linear_387_36439)"
                    />
                    <path
                        d="M37.6704 122.7C36.8772 116.993 35.5779 111.432 33.7598 106.033C32.229 101.488 27.8296 98.5378 23.04 98.7737L1.11572 99.8542L11.261 142.828L31.3286 133.999C35.7194 132.065 38.3265 127.456 37.6704 122.705V122.7Z"
                        fill="url(#paint28_linear_387_36439)"
                    />
                    <path
                        d="M72.3382 159.255C67.7415 155.777 62.8918 152.767 57.7849 150.233C53.4884 148.102 48.2914 149.127 45.0712 152.682L30.3335 168.946L67.8959 192.156L75.8414 171.724C77.5823 167.252 76.1673 162.149 72.3382 159.255Z"
                        fill="url(#paint29_linear_387_36439)"
                    />
                    <path
                        d="M122.701 160.584C116.994 161.377 111.432 162.677 106.034 164.495C101.488 166.026 98.5383 170.425 98.7742 175.215L99.8547 197.139L142.828 186.994L134 166.926C132.066 162.535 127.456 159.928 122.705 160.584H122.701Z"
                        fill="url(#paint30_linear_387_36439)"
                    />
                    <path
                        d="M159.255 125.916C155.777 130.513 152.767 135.363 150.233 140.47C148.102 144.766 149.127 149.963 152.682 153.183L168.946 167.921L192.156 130.359L171.724 122.413C167.252 120.672 162.149 122.087 159.255 125.916Z"
                        fill="url(#paint31_linear_387_36439)"
                    />
                    <path
                        d="M160.585 75.5546C161.378 81.2618 162.677 86.8233 164.495 92.2218C166.026 96.767 170.425 99.7171 175.215 99.4813L197.139 98.4007L186.994 55.427L166.926 64.2559C162.536 66.1897 159.929 70.7993 160.585 75.5503V75.5546Z"
                        fill="url(#paint32_linear_387_36439)"
                    />
                    <path
                        d="M125.916 38.9995C130.513 42.477 135.363 45.4872 140.47 48.0213C144.766 50.1525 149.963 49.1276 153.183 45.5729L167.921 29.3088L130.359 6.09814L122.413 26.5302C120.672 31.0025 122.087 36.1052 125.916 38.9995Z"
                        fill="url(#paint33_linear_387_36439)"
                    />
                </g>
                <path
                    opacity="0.2"
                    d="M99.1253 155.919C130.492 155.919 155.919 130.491 155.919 99.1251C155.919 67.7587 130.492 42.3313 99.1253 42.3313C67.759 42.3313 42.3315 67.7587 42.3315 99.1251C42.3315 130.491 67.759 155.919 99.1253 155.919Z"
                    fill="#3A3AD2"
                />
                <path
                    d="M99.5801 155.863C129.852 155.863 154.393 131.323 154.393 101.05C154.393 70.7781 129.852 46.2375 99.5801 46.2375C69.3078 46.2375 44.7673 70.7781 44.7673 101.05C44.7673 131.323 69.3078 155.863 99.5801 155.863Z"
                    fill="url(#paint34_linear_387_36439)"
                />
                <path
                    d="M129.372 104.022C127.708 96.9812 122.267 93.6151 112.379 95.0216L112.173 94.1425C120.77 91.1839 122.37 86.1584 120.916 79.9966C119.047 72.0725 112.726 68.9209 102.161 71.4122L71.3518 78.6845L83.5167 129.81L115.209 122.327C127.532 119.416 131.546 112.799 129.471 103.996L129.38 104.018L129.372 104.022ZM86.1623 86.3428L103.769 82.1878C108.151 81.1544 110.325 82.4836 111.165 85.9697C111.165 85.9526 111.161 85.9397 111.157 85.9268L111.178 86.0169C111.178 86.0169 111.17 85.9869 111.165 85.974C111.976 89.4687 110.985 90.7036 106.054 91.8742L88.4478 96.0292L86.1623 86.3471V86.3428ZM112.615 111.787L93.246 116.358L90.7504 105.793L110.098 101.132C114.497 100.094 116.675 101.436 117.507 104.957C118.339 108.477 117.014 110.741 112.615 111.783V111.787Z"
                    fill="url(#paint35_linear_387_36439)"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_387_36439"
                    x="0"
                    y="0"
                    width="270.142"
                    height="259.396"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur
                        stdDeviation="7.5"
                        result="effect1_foregroundBlur_387_36439"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_387_36439"
                    x1="123.323"
                    y1="258.311"
                    x2="265.877"
                    y2="136.587"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#308246" />
                    <stop offset="0.03" stopColor="#36834B" />
                    <stop offset="0.13" stopColor="#4D875D" />
                    <stop offset="0.25" stopColor="#628A6D" />
                    <stop offset="0.37" stopColor="#738C7A" />
                    <stop offset="0.49" stopColor="#808E84" />
                    <stop offset="0.63" stopColor="#8A908C" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_387_36439"
                    x1="213.789"
                    y1="141.751"
                    x2="190.069"
                    y2="240.617"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#308246" />
                    <stop offset="1" stopColor="#1C1C64" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_387_36439"
                    x1="168.208"
                    y1="257.346"
                    x2="218.49"
                    y2="125.841"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#308246" />
                    <stop offset="0.03" stopColor="#36834B" />
                    <stop offset="0.13" stopColor="#4D875D" />
                    <stop offset="0.25" stopColor="#628A6D" />
                    <stop offset="0.37" stopColor="#738C7A" />
                    <stop offset="0.49" stopColor="#808E84" />
                    <stop offset="0.63" stopColor="#8A908C" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_387_36439"
                    x1="135.924"
                    y1="245"
                    x2="186.205"
                    y2="113.499"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#308246" />
                    <stop offset="0.03" stopColor="#36834B" />
                    <stop offset="0.13" stopColor="#4D875D" />
                    <stop offset="0.25" stopColor="#628A6D" />
                    <stop offset="0.37" stopColor="#738C7A" />
                    <stop offset="0.49" stopColor="#808E84" />
                    <stop offset="0.63" stopColor="#8A908C" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_387_36439"
                    x1="127.965"
                    y1="241.959"
                    x2="178.246"
                    y2="110.454"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#308246" />
                    <stop offset="0.03" stopColor="#36834B" />
                    <stop offset="0.13" stopColor="#4D875D" />
                    <stop offset="0.25" stopColor="#628A6D" />
                    <stop offset="0.37" stopColor="#738C7A" />
                    <stop offset="0.49" stopColor="#808E84" />
                    <stop offset="0.63" stopColor="#8A908C" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_387_36439"
                    x1="144.493"
                    y1="248.278"
                    x2="194.773"
                    y2="116.777"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#308246" />
                    <stop offset="0.03" stopColor="#36834B" />
                    <stop offset="0.13" stopColor="#4D875D" />
                    <stop offset="0.25" stopColor="#628A6D" />
                    <stop offset="0.37" stopColor="#738C7A" />
                    <stop offset="0.49" stopColor="#808E84" />
                    <stop offset="0.63" stopColor="#8A908C" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_387_36439"
                    x1="182.911"
                    y1="262.967"
                    x2="233.193"
                    y2="131.462"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#308246" />
                    <stop offset="0.03" stopColor="#36834B" />
                    <stop offset="0.13" stopColor="#4D875D" />
                    <stop offset="0.25" stopColor="#628A6D" />
                    <stop offset="0.37" stopColor="#738C7A" />
                    <stop offset="0.49" stopColor="#808E84" />
                    <stop offset="0.63" stopColor="#8A908C" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_387_36439"
                    x1="215.192"
                    y1="275.312"
                    x2="265.473"
                    y2="143.807"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#308246" />
                    <stop offset="0.03" stopColor="#36834B" />
                    <stop offset="0.13" stopColor="#4D875D" />
                    <stop offset="0.25" stopColor="#628A6D" />
                    <stop offset="0.37" stopColor="#738C7A" />
                    <stop offset="0.49" stopColor="#808E84" />
                    <stop offset="0.63" stopColor="#8A908C" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear_387_36439"
                    x1="223.151"
                    y1="278.354"
                    x2="273.436"
                    y2="146.854"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#308246" />
                    <stop offset="0.03" stopColor="#36834B" />
                    <stop offset="0.13" stopColor="#4D875D" />
                    <stop offset="0.25" stopColor="#628A6D" />
                    <stop offset="0.37" stopColor="#738C7A" />
                    <stop offset="0.49" stopColor="#808E84" />
                    <stop offset="0.63" stopColor="#8A908C" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear_387_36439"
                    x1="206.623"
                    y1="272.035"
                    x2="256.904"
                    y2="140.53"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#308246" />
                    <stop offset="0.03" stopColor="#36834B" />
                    <stop offset="0.13" stopColor="#4D875D" />
                    <stop offset="0.25" stopColor="#628A6D" />
                    <stop offset="0.37" stopColor="#738C7A" />
                    <stop offset="0.49" stopColor="#808E84" />
                    <stop offset="0.63" stopColor="#8A908C" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint10_linear_387_36439"
                    x1="187.878"
                    y1="249.25"
                    x2="214.903"
                    y2="137.543"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#308246" />
                    <stop offset="0.03" stopColor="#36834B" />
                    <stop offset="0.13" stopColor="#4D875D" />
                    <stop offset="0.25" stopColor="#628A6D" />
                    <stop offset="0.37" stopColor="#738C7A" />
                    <stop offset="0.49" stopColor="#808E84" />
                    <stop offset="0.63" stopColor="#8A908C" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint11_linear_387_36439"
                    x1="228.23"
                    y1="144.484"
                    x2="183.837"
                    y2="226.45"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#308246" />
                    <stop offset="1" stopColor="#1C1C64" />
                </linearGradient>
                <linearGradient
                    id="paint12_linear_387_36439"
                    x1="102.931"
                    y1="268.916"
                    x2="203.173"
                    y2="28.4229"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#48C369" />
                    <stop offset="0.03" stopColor="#51C470" />
                    <stop offset="0.13" stopColor="#72C889" />
                    <stop offset="0.25" stopColor="#90CBA0" />
                    <stop offset="0.37" stopColor="#A8CDB2" />
                    <stop offset="0.49" stopColor="#BBCFC0" />
                    <stop offset="0.63" stopColor="#C8D1CB" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint13_linear_387_36439"
                    x1="141.835"
                    y1="67.5636"
                    x2="174.56"
                    y2="205.04"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#48C369" />
                    <stop offset="1" stopColor="#2E2EA5" />
                </linearGradient>
                <linearGradient
                    id="paint14_linear_387_36439"
                    x1="157.969"
                    y1="239.519"
                    x2="137.665"
                    y2="44.8892"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#48C369" />
                    <stop offset="0.03" stopColor="#51C470" />
                    <stop offset="0.13" stopColor="#72C889" />
                    <stop offset="0.25" stopColor="#90CBA0" />
                    <stop offset="0.37" stopColor="#A8CDB2" />
                    <stop offset="0.49" stopColor="#BBCFC0" />
                    <stop offset="0.63" stopColor="#C8D1CB" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint15_linear_387_36439"
                    x1="110.187"
                    y1="244.501"
                    x2="89.8831"
                    y2="49.8707"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#48C369" />
                    <stop offset="0.03" stopColor="#51C470" />
                    <stop offset="0.13" stopColor="#72C889" />
                    <stop offset="0.25" stopColor="#90CBA0" />
                    <stop offset="0.37" stopColor="#A8CDB2" />
                    <stop offset="0.49" stopColor="#BBCFC0" />
                    <stop offset="0.63" stopColor="#C8D1CB" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint16_linear_387_36439"
                    x1="98.4062"
                    y1="245.73"
                    x2="78.1021"
                    y2="51.1005"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#48C369" />
                    <stop offset="0.03" stopColor="#51C470" />
                    <stop offset="0.13" stopColor="#72C889" />
                    <stop offset="0.25" stopColor="#90CBA0" />
                    <stop offset="0.37" stopColor="#A8CDB2" />
                    <stop offset="0.49" stopColor="#BBCFC0" />
                    <stop offset="0.63" stopColor="#C8D1CB" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint17_linear_387_36439"
                    x1="122.872"
                    y1="243.177"
                    x2="102.568"
                    y2="48.5467"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#48C369" />
                    <stop offset="0.03" stopColor="#51C470" />
                    <stop offset="0.13" stopColor="#72C889" />
                    <stop offset="0.25" stopColor="#90CBA0" />
                    <stop offset="0.37" stopColor="#A8CDB2" />
                    <stop offset="0.49" stopColor="#BBCFC0" />
                    <stop offset="0.63" stopColor="#C8D1CB" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint18_linear_387_36439"
                    x1="179.73"
                    y1="237.247"
                    x2="159.426"
                    y2="42.6171"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#48C369" />
                    <stop offset="0.03" stopColor="#51C470" />
                    <stop offset="0.13" stopColor="#72C889" />
                    <stop offset="0.25" stopColor="#90CBA0" />
                    <stop offset="0.37" stopColor="#A8CDB2" />
                    <stop offset="0.49" stopColor="#BBCFC0" />
                    <stop offset="0.63" stopColor="#C8D1CB" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint19_linear_387_36439"
                    x1="227.51"
                    y1="232.261"
                    x2="207.206"
                    y2="37.6313"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#48C369" />
                    <stop offset="0.03" stopColor="#51C470" />
                    <stop offset="0.13" stopColor="#72C889" />
                    <stop offset="0.25" stopColor="#90CBA0" />
                    <stop offset="0.37" stopColor="#A8CDB2" />
                    <stop offset="0.49" stopColor="#BBCFC0" />
                    <stop offset="0.63" stopColor="#C8D1CB" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint20_linear_387_36439"
                    x1="239.293"
                    y1="231.035"
                    x2="218.989"
                    y2="36.4053"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#48C369" />
                    <stop offset="0.03" stopColor="#51C470" />
                    <stop offset="0.13" stopColor="#72C889" />
                    <stop offset="0.25" stopColor="#90CBA0" />
                    <stop offset="0.37" stopColor="#A8CDB2" />
                    <stop offset="0.49" stopColor="#BBCFC0" />
                    <stop offset="0.63" stopColor="#C8D1CB" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint21_linear_387_36439"
                    x1="214.826"
                    y1="233.585"
                    x2="194.526"
                    y2="38.9545"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#48C369" />
                    <stop offset="0.03" stopColor="#51C470" />
                    <stop offset="0.13" stopColor="#72C889" />
                    <stop offset="0.25" stopColor="#90CBA0" />
                    <stop offset="0.37" stopColor="#A8CDB2" />
                    <stop offset="0.49" stopColor="#BBCFC0" />
                    <stop offset="0.63" stopColor="#C8D1CB" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint22_linear_387_36439"
                    x1="177.274"
                    y1="217.115"
                    x2="140.578"
                    y2="61.6391"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#48C369" />
                    <stop offset="0.03" stopColor="#51C470" />
                    <stop offset="0.13" stopColor="#72C889" />
                    <stop offset="0.25" stopColor="#90CBA0" />
                    <stop offset="0.37" stopColor="#A8CDB2" />
                    <stop offset="0.49" stopColor="#BBCFC0" />
                    <stop offset="0.63" stopColor="#C8D1CB" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint23_linear_387_36439"
                    x1="147.004"
                    y1="70.053"
                    x2="170.465"
                    y2="183.154"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#48C369" />
                    <stop offset="1" stopColor="#2E2EA5" />
                </linearGradient>
                <linearGradient
                    id="paint24_linear_387_36439"
                    x1="36.2521"
                    y1="249.998"
                    x2="150.278"
                    y2="-23.5622"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="0.03" stopColor="#63F189" />
                    <stop offset="0.13" stopColor="#8CF5A8" />
                    <stop offset="0.25" stopColor="#B0F8C3" />
                    <stop offset="0.37" stopColor="#CDFAD9" />
                    <stop offset="0.49" stopColor="#E3FCEA" />
                    <stop offset="0.63" stopColor="#F3FEF6" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint25_linear_387_36439"
                    x1="80.5038"
                    y1="20.9576"
                    x2="117.729"
                    y2="177.334"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="1" stopColor="#3A3AD2" />
                </linearGradient>
                <linearGradient
                    id="paint26_linear_387_36439"
                    x1="98.8558"
                    y1="216.555"
                    x2="75.7639"
                    y2="-4.83843"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="0.03" stopColor="#63F189" />
                    <stop offset="0.13" stopColor="#8CF5A8" />
                    <stop offset="0.25" stopColor="#B0F8C3" />
                    <stop offset="0.37" stopColor="#CDFAD9" />
                    <stop offset="0.49" stopColor="#E3FCEA" />
                    <stop offset="0.63" stopColor="#F3FEF6" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint27_linear_387_36439"
                    x1="44.5049"
                    y1="222.224"
                    x2="21.414"
                    y2="0.834343"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="0.03" stopColor="#63F189" />
                    <stop offset="0.13" stopColor="#8CF5A8" />
                    <stop offset="0.25" stopColor="#B0F8C3" />
                    <stop offset="0.37" stopColor="#CDFAD9" />
                    <stop offset="0.49" stopColor="#E3FCEA" />
                    <stop offset="0.63" stopColor="#F3FEF6" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint28_linear_387_36439"
                    x1="31.1066"
                    y1="223.623"
                    x2="8.01057"
                    y2="2.23115"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="0.03" stopColor="#63F189" />
                    <stop offset="0.13" stopColor="#8CF5A8" />
                    <stop offset="0.25" stopColor="#B0F8C3" />
                    <stop offset="0.37" stopColor="#CDFAD9" />
                    <stop offset="0.49" stopColor="#E3FCEA" />
                    <stop offset="0.63" stopColor="#F3FEF6" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint29_linear_387_36439"
                    x1="58.9312"
                    y1="220.722"
                    x2="35.8393"
                    y2="-0.671615"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="0.03" stopColor="#63F189" />
                    <stop offset="0.13" stopColor="#8CF5A8" />
                    <stop offset="0.25" stopColor="#B0F8C3" />
                    <stop offset="0.37" stopColor="#CDFAD9" />
                    <stop offset="0.49" stopColor="#E3FCEA" />
                    <stop offset="0.63" stopColor="#F3FEF6" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint30_linear_387_36439"
                    x1="123.61"
                    y1="213.972"
                    x2="100.514"
                    y2="-7.41992"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="0.03" stopColor="#63F189" />
                    <stop offset="0.13" stopColor="#8CF5A8" />
                    <stop offset="0.25" stopColor="#B0F8C3" />
                    <stop offset="0.37" stopColor="#CDFAD9" />
                    <stop offset="0.49" stopColor="#E3FCEA" />
                    <stop offset="0.63" stopColor="#F3FEF6" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint31_linear_387_36439"
                    x1="177.956"
                    y1="208.305"
                    x2="154.865"
                    y2="-13.0882"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="0.03" stopColor="#63F189" />
                    <stop offset="0.13" stopColor="#8CF5A8" />
                    <stop offset="0.25" stopColor="#B0F8C3" />
                    <stop offset="0.37" stopColor="#CDFAD9" />
                    <stop offset="0.49" stopColor="#E3FCEA" />
                    <stop offset="0.63" stopColor="#F3FEF6" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint32_linear_387_36439"
                    x1="191.359"
                    y1="206.904"
                    x2="168.268"
                    y2="-14.4849"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="0.03" stopColor="#63F189" />
                    <stop offset="0.13" stopColor="#8CF5A8" />
                    <stop offset="0.25" stopColor="#B0F8C3" />
                    <stop offset="0.37" stopColor="#CDFAD9" />
                    <stop offset="0.49" stopColor="#E3FCEA" />
                    <stop offset="0.63" stopColor="#F3FEF6" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint33_linear_387_36439"
                    x1="163.531"
                    y1="209.811"
                    x2="140.439"
                    y2="-11.5826"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="0.03" stopColor="#63F189" />
                    <stop offset="0.13" stopColor="#8CF5A8" />
                    <stop offset="0.25" stopColor="#B0F8C3" />
                    <stop offset="0.37" stopColor="#CDFAD9" />
                    <stop offset="0.49" stopColor="#E3FCEA" />
                    <stop offset="0.63" stopColor="#F3FEF6" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint34_linear_387_36439"
                    x1="120.814"
                    y1="191.075"
                    x2="79.0734"
                    y2="14.2238"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="0.03" stopColor="#63F189" />
                    <stop offset="0.13" stopColor="#8CF5A8" />
                    <stop offset="0.25" stopColor="#B0F8C3" />
                    <stop offset="0.37" stopColor="#CDFAD9" />
                    <stop offset="0.49" stopColor="#E3FCEA" />
                    <stop offset="0.63" stopColor="#F3FEF6" />
                    <stop offset="0.79" stopColor="#FCFFFD" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint35_linear_387_36439"
                    x1="86.3839"
                    y1="23.7902"
                    x2="113.069"
                    y2="152.44"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#58F081" />
                    <stop offset="1" stopColor="#3A3AD2" />
                </linearGradient>
            </defs>
        </svg>
    );
};
