import { useDependencies } from 'ioc';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { LoadStatus } from '../../../common/enums/load-status';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect } from 'react';
import { SignupStore } from '../../stores/signup.store';
import { UserAvatar, UserAvatarType } from '../_signup/user-avatar';
import { getInputProps } from '../../utils/get-input-props';
import { Checkbox, FormControlLabel, InputAdornment } from '@mui/material';
import Link from '@mui/material/Link';
import { ExternalDocsLinks } from '../../utils/external-links';
import Button from '@mui/material/Button';
import { UnauthenticatedRoutes } from '../../../redesigned-modules/root/components/app/app-unauthenticated';
import { noop } from '../../../common/utils/noop';
import { BrandTextField } from '../brand-components/brand-text-field';

export const SignupForm = observer(() => {
    const [{ signup, init, signupLoadStatus, formState, availableAvatarFiles }] =
        useDependencies(SignupStore);

    const {
        username,
        password,
        confirmPassword,
        avatarFile,
        email,
        fullName,
        phoneNumber,
        agreeTerms,
    } = formState.$;

    useEffect(() => {
        init();
    }, []);

    const handleSubmit = () => {
        signup().catch(noop);
    };

    return (
        <Box
            component="form"
            display="flex"
            flexDirection="column"
            width="100%"
            onSubmit={event => {
                event.preventDefault();
                handleSubmit();
            }}
        >
            <Typography variant="h4" color="primary" fontWeight={900} textAlign="center" mb={4}>
                Sign Up
            </Typography>
            <Box mb={3} display="flex" flexDirection="column" gap={2} alignItems="center">
                <UserAvatar active src={avatarFile.value?.url} type={UserAvatarType.Selected} />
                <Box
                    display="flex"
                    gap={1.5}
                    flexWrap="wrap"
                    justifyContent="center"
                    alignItems="center"
                >
                    {availableAvatarFiles.map(file => (
                        <UserAvatar
                            active={avatarFile.value?.id === file.id}
                            key={file.id}
                            src={file.url ?? ''}
                            type={UserAvatarType.Available}
                            onClick={() => {
                                avatarFile.onChange(file);
                            }}
                        />
                    ))}
                </Box>
            </Box>
            <BrandTextField variant="standard" label="Full Name" {...getInputProps(fullName)} />
            <BrandTextField
                variant="standard"
                label="Email Address"
                autoComplete="email"
                {...getInputProps(email)}
            />
            <BrandTextField
                variant="standard"
                label="Phone Number"
                InputProps={{
                    startAdornment: <InputAdornment position="start">+</InputAdornment>,
                }}
                {...getInputProps(phoneNumber)}
            />
            <BrandTextField variant="standard" label="Username" {...getInputProps(username)} />
            <BrandTextField
                variant="standard"
                label="Password"
                type="password"
                {...getInputProps(password)}
            />
            <BrandTextField
                variant="standard"
                label="Confirm Password"
                type="password"
                {...getInputProps(confirmPassword)}
                sx={{ mb: 2 }}
            />
            <FormControlLabel
                sx={{ mb: 2 }}
                control={
                    <Checkbox
                        sx={{
                            color: agreeTerms.hasError ? 'red' : undefined,
                        }}
                        checked={agreeTerms.value}
                        onChange={(_event, checked) => {
                            agreeTerms.onChange(checked);
                        }}
                    />
                }
                label={
                    <Typography
                        variant="caption"
                        color={agreeTerms.hasError ? 'red' : 'textSecondary'}
                    >
                        I agree to{' '}
                        <Link
                            target="_blank"
                            component="a"
                            href={ExternalDocsLinks.termsAndConditions}
                            underline="hover"
                        >
                            Terms and Conditions
                        </Link>{' '}
                        and{' '}
                        <Link
                            target="_blank"
                            component="a"
                            href={ExternalDocsLinks.privacyPolicy}
                            underline="hover"
                        >
                            Privacy Policy
                        </Link>
                        .
                    </Typography>
                }
            />
            <Button
                sx={{ mb: 4 }}
                variant="contained"
                type="submit"
                disabled={formState.hasError || signupLoadStatus === LoadStatus.Loading}
            >
                Sign Up
            </Button>
            <Typography variant="body1" mb={3}>
                Already have an account?{' '}
                <Link underline="hover" component={RouterLink} to={UnauthenticatedRoutes.login}>
                    Log In
                </Link>
            </Typography>
        </Box>
    );
});
